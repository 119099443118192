import { DateTime } from 'luxon';

interface IPreferredReadyIntervalValues {
  start: string; // ISO Date string in place time zone, UTC
  end: string; // ISO Date string in place time zone, UTC
  slotTimeInMinutes: number;
}

export class PreferredReadyInterval {
  start?: DateTime;
  end?: DateTime;
  slotTimeInMinutes: number;

  constructor(values: IPreferredReadyIntervalValues) {
    this.start = DateTime.fromISO(values?.start) ?? undefined;
    this.end = DateTime.fromISO(values?.end) ?? undefined;
    this.slotTimeInMinutes = values?.slotTimeInMinutes ?? 10;
  }
}

type AvailableIntervalDateValues = {
  date: string; // ISO Date string in place time zone, UTC
  intervals: IPreferredReadyIntervalValues[];
};

export interface IPreferredReadyValues {
  availableIntervalDates: AvailableIntervalDateValues[];
  time: string | null;
  timeRequired: boolean;
}

export class AvailableIntervalDate {
  date: string;
  intervals: PreferredReadyInterval[];

  constructor(values: AvailableIntervalDateValues) {
    this.date = values.date;
    this.intervals = values?.intervals?.map(
      (interval) => new PreferredReadyInterval(interval),
    );
  }
}

export class PreferredReady {
  availableIntervalDates: AvailableIntervalDate[];
  time: string | null;
  timeRequired: boolean;
  constructor(values: IPreferredReadyValues) {
    this.availableIntervalDates =
      values?.availableIntervalDates
        ?.map((date) => new AvailableIntervalDate(date))
        ?.sort((a, b) => {
          if (!a || !b) return 0;
          const firstDate = DateTime.fromISO(a.date);
          const secondDate = DateTime.fromISO(b.date);

          return firstDate.toMillis() - secondDate.toMillis();
        }) ?? [];
    this.time = values?.time ?? null;
    this.timeRequired = values?.timeRequired ?? false;
  }
}
