import { createContext, useContext, useMemo, useReducer } from 'react';
import { logError } from 'helpers/log-helper/log-helper';
import { PlaceService } from 'services/Place.service';
import { Place } from 'types/place';
import { LogPrefix } from 'types/logging';

interface IPlacesStore {
  readonly places: Place[];
  /**
   * Is currently fetching places
   */
  readonly isLoadingPlaces: boolean;
  /**
   * Has finished fetch request for places,
   * true no matter if the response was an error or success
   */
  readonly hasLoadedPlaces: boolean;
}

interface IPlacesAPI {
  /**
   * Fetch all places for application
   */
  getPlaces: () => Promise<Place[]>;
}

const initialState: IPlacesStore = {
  places: [],
  isLoadingPlaces: false,
  hasLoadedPlaces: false,
};

enum ActionType {
  SET_PLACES,
  START_LOADING_PLACES,
  STOP_LOADING_PLACES,
}

type Action =
  | { type: ActionType.SET_PLACES; payload: Place[] }
  | { type: ActionType.START_LOADING_PLACES }
  | { type: ActionType.STOP_LOADING_PLACES };

const PlacesAPI = createContext<IPlacesAPI>({} as IPlacesAPI);
const PlacesStore = createContext<IPlacesStore>({} as IPlacesStore);

const reducer = (state: IPlacesStore, action: Action): IPlacesStore => {
  switch (action.type) {
    case ActionType.SET_PLACES:
      return {
        places: action.payload,
        isLoadingPlaces: false,
        hasLoadedPlaces: true,
      };
    case ActionType.START_LOADING_PLACES:
      return {
        ...state,
        isLoadingPlaces: true,
        hasLoadedPlaces: false,
      };
    case ActionType.STOP_LOADING_PLACES:
      return {
        ...state,
        isLoadingPlaces: false,
        hasLoadedPlaces: true,
      };
    default:
      return state;
  }
};

export function PlacesProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: IPlacesStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: IPlacesAPI = useMemo(() => {
    const getPlaces = async () => {
      try {
        dispatch({ type: ActionType.START_LOADING_PLACES });
        const places = await PlaceService.getPlaces();
        dispatch({ type: ActionType.SET_PLACES, payload: places });
        return places;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_PLACES });
        logError(LogPrefix.Place, error, 'Could not get Places');
        throw error;
      }
    };
    return { getPlaces };
  }, []);

  return (
    <PlacesAPI.Provider value={api}>
      <PlacesStore.Provider value={state}>{children}</PlacesStore.Provider>
    </PlacesAPI.Provider>
  );
}

export const usePlacesStore = (): IPlacesStore => useContext(PlacesStore);
export const usePlacesAPI = (): IPlacesAPI => useContext(PlacesAPI);
