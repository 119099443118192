import { useState } from 'react';
import { Modal } from '@caspeco/casper-ui-library.components.modal';
import {
  BaseColorContext,
  IBaseColorContext,
} from 'components/base-color-context/BaseColorContext';
import { OrderProvider } from 'store/orderStore';
import { HistoryProvider } from 'store/historyStore';
import { HistoryModalList } from './HistoryModalList';
import { HistoryModalOrder } from './HistoryModalOrder';
import { HistoryModalConfirmDelete } from './HistoryModalConfirmDelete';

interface IHistoryModal {
  isOpen: boolean;
  onClose: () => void;
}

interface IHistoryModalState {
  orderId: string | undefined;
  isProcessed: boolean;
  isConfirmingDelete: boolean;
}

const initialState: IHistoryModalState = {
  isConfirmingDelete: false,
  orderId: undefined,
  isProcessed: false,
};

const baseColorValue: IBaseColorContext = { baseColor: 'surface' };

export function HistoryModal({ isOpen, onClose }: IHistoryModal) {
  const [state, setState] = useState<IHistoryModalState>(initialState);

  const onClickOrder = (orderId: string, isProcessed: boolean) => {
    setState({
      ...state,
      orderId,
      isProcessed,
    });
  };

  const resetState = () => {
    setState(initialState);
  };

  const toggleConfirmDelete = () => {
    setState({ ...state, isConfirmingDelete: !state.isConfirmingDelete });
  };

  const handleOnClose = () => {
    onClose();
    resetState();
  };

  let modalContent;
  const { orderId, isProcessed, isConfirmingDelete } = state;

  if (isConfirmingDelete && orderId) {
    modalContent = (
      <HistoryModalConfirmDelete
        orderId={orderId}
        onDeleteSuccess={resetState}
        toggleConfirmDelete={toggleConfirmDelete}
      />
    );
  } else if (orderId) {
    modalContent = (
      <HistoryModalOrder
        orderId={orderId}
        isProcessed={isProcessed}
        onGoBack={resetState}
        toggleConfirmDelete={toggleConfirmDelete}
      />
    );
  } else {
    // No order selected, show list of order history
    modalContent = <HistoryModalList onClickOrder={onClickOrder} />;
  }

  return (
    <OrderProvider>
      <HistoryProvider>
        <Modal isOpen={isOpen} onClose={handleOnClose} size="md">
          <BaseColorContext.Provider value={baseColorValue}>
            {modalContent}
          </BaseColorContext.Provider>
        </Modal>
      </HistoryProvider>
    </OrderProvider>
  );
}
