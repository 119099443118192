import { useEffect, useState } from 'react';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { NonProtectedRoutes, ProtectedRoutes } from 'types/routes';
import { useOrderStore } from 'store/orderStore';
import { usePlaceStore } from 'store/placeStore';

/**
 * @param routeName current route
 * @returns a string to display in the page header, based on route and accessible place data
 */
export function useGetHeaderTitle(route: ProtectedRoutes | NonProtectedRoutes) {
  const appName = getLocalStorage<string>('appName') ?? '';
  const { place } = usePlaceStore();
  const { order } = useOrderStore();
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (
      route === ProtectedRoutes.PLACES ||
      route === NonProtectedRoutes.APP_REDIRECT_INFO ||
      route === NonProtectedRoutes.COOKIES ||
      route === NonProtectedRoutes.CONFIRM_EMAIL ||
      route === ProtectedRoutes.HISTORY
    ) {
      setTitle(appName);
    } else if (place) {
      setTitle(place.name);
    } else if (order) {
      setTitle(order.placeName);
    }
  }, [place, order, route]);

  return title;
}
