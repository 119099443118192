import { useTranslation } from 'react-i18next';
import { Badge } from '@caspeco/casper-ui-library.components.badge';

interface IAvailableStatus {
  open: boolean;
  preOrderEnabled?: boolean;
}

/**
 * Displays a status text for menu.
 */
function PlaceAvailableStatus({ open, preOrderEnabled }: IAvailableStatus) {
  const { t } = useTranslation();

  if (preOrderEnabled && !open) {
    return <Badge.Info>{t('order_pre_order')}</Badge.Info>;
  }
  if (preOrderEnabled && open) {
    return <Badge.Success>{t('order_now')}</Badge.Success>;
  }
  if (open) {
    return <Badge.Success>{t('order_now')}</Badge.Success>;
  }
  if (!open) {
    return <Badge.Error>{t('time_closed')}</Badge.Error>;
  }
  return <Badge.Error>{t('time_closed')}</Badge.Error>;
}

export default PlaceAvailableStatus;
