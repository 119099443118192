import { IAnalyticsEvent } from 'types/analytics';
import { logError } from 'helpers/log-helper/log-helper';
import { LogPrefix } from 'types/logging';
import { getCookieConsent } from 'helpers/cookie-helper/cookie-helper';
import { ApiService } from './Api.service';

export class AnalyticsAPIService {
  static async sendEvent(event: IAnalyticsEvent): Promise<void> {
    return ApiService.fetch(`telemetry`, {
      method: 'POST',
      body: JSON.stringify(event),
    });
  }

  static async sendManyEvents(events: IAnalyticsEvent[]): Promise<void> {
    return ApiService.fetch(`telemetry/send-many`, {
      method: 'POST',
      body: JSON.stringify(events),
    });
  }
}

export class AnalyticsService {
  static async sendEvent(event: IAnalyticsEvent): Promise<void> {
    const hasConsent = getCookieConsent()?.analytics;
    if (!hasConsent) {
      // Do not send analytics events if the user has not given consent
      return;
    }
    try {
      await AnalyticsAPIService.sendEvent(event);
    } catch (error) {
      logError(LogPrefix.Analytics, error, 'Could not send analytics event');
      throw error;
    }
  }

  static async sendManyEvents(events: IAnalyticsEvent[]): Promise<void> {
    const hasConsent = getCookieConsent()?.analytics;
    if (!hasConsent) {
      // Do not send analytics events if the user has not given consent
      return;
    }
    try {
      await AnalyticsAPIService.sendManyEvents(events);
    } catch (error) {
      logError(LogPrefix.Analytics, error, 'Could not send analytics events');
      throw error;
    }
  }
}
