import { CurrencyString } from './currency';

export interface IPriceIncludingVatValues {
  cents: number;
  currency: string;
}

export enum PriceType {
  'IN_HOUSE',
  'TAKEAWAY',
}

export class PriceIncludingVat {
  cents: number;
  currency: CurrencyString;

  constructor(values?: IPriceIncludingVatValues) {
    this.cents = values?.cents ?? 0;
    this.currency = (values?.currency as CurrencyString) ?? 'SEK';
  }
}
