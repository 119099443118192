import { useState, useEffect } from 'react';
import { IRouteRequestState } from 'types/routeRequestState';
import { useOrderAPI } from 'store/orderStore';
import { useBSTLUserStore } from 'store/bstlUserStore';

/**
 * Handles order request.
 * Initally and until all requests have finished, will return @param isLoading = true.
 * If any request fails, will return @param error with error contents.
 *
 * Used to display a shared initial loading and error state for a route.
 *
 * @returns shared loading state and error state for all requests.
 */
export const useOrderRequest = (orderId: string): IRouteRequestState => {
  const { bstlUser } = useBSTLUserStore();
  const { getPublicOrder, getOrder } = useOrderAPI();
  const [requestState, setRequestState] = useState<IRouteRequestState>({
    isLoading: true,
    error: undefined,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!orderId) throw new Error();
        if (bstlUser) {
          await getOrder(orderId);
        } else {
          await getPublicOrder(orderId);
        }
        setRequestState({
          isLoading: false,
          error: undefined,
        });
      } catch (error) {
        setRequestState({
          isLoading: false,
          error,
        });
      }
    };

    fetch();
  }, [orderId, bstlUser]);

  return requestState;
};
