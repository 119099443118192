import { Box } from '@caspeco/casper-ui-library.components.box';
import {
  ThemeBorderVariable,
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeRadiusVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import {
  Skeleton,
  SkeletonText,
} from '@caspeco/casper-ui-library.components.skeleton';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { CSSVarColumnWidthLarge } from 'components/global-styles/GlobalStyles';

function SkeletonPlaceCard() {
  return (
    <Box
      borderBottom={ThemeBorderVariable.XSmall}
      borderColor={ThemeColorVariable.OnBackgroundBorder}
      py={ThemeSpaceVariable.Medium}
    >
      <Skeleton
        h={{
          base: ThemeFontSizeVariable.XLarge,
          md: ThemeFontSizeVariable.X2Large,
        }}
        mb={ThemeSpaceVariable.Medium}
        w="45%"
      />
      <SkeletonText
        noOfLines={2}
        skeletonHeight={ThemeFontSizeVariable.Medium}
        mb={ThemeSpaceVariable.Small}
      />
    </Box>
  );
}

function SkeletonSearchBar() {
  return (
    <Flex gap={ThemeSpaceVariable.Medium}>
      {/* Search bar */}
      <Skeleton w="100%" h="48px" />
      {/* Sort by location button */}
      <Skeleton
        h="40px"
        w={{ base: '40px', md: '210px' }}
        minW={{ base: '40px', md: '210px' }}
        borderRadius={{ base: '50%', md: ThemeRadiusVariable.XLarge }}
      />
    </Flex>
  );
}

export function SkeletonPlacesList() {
  return (
    <span data-testid="loading-indicator">
      <Flex justify="center" w="100%">
        <Box
          mt={ThemeSpaceVariable.Large}
          w={{ base: '100%', md: CSSVarColumnWidthLarge }}
          px={{
            base: ThemeSpaceVariable.Medium,
            md: ThemeSpaceVariable.None,
          }}
          mb={{
            base: ThemeSpaceVariable.Medium,
            md: ThemeSpaceVariable.XLarge,
          }}
        >
          <SkeletonSearchBar />
          <Flex w="100%" mt={ThemeSpaceVariable.Large} direction="column">
            {[...Array(12)].map((_entry, index) => (
              <SkeletonPlaceCard key={index} />
            ))}
          </Flex>
        </Box>
      </Flex>
    </span>
  );
}
