import { createContext, useContext, useMemo, useReducer } from 'react';
import { logError } from 'helpers/log-helper/log-helper';
import { LogPrefix } from 'types/logging';
import { Offer } from 'types/offer';
import { OffersService } from 'services/Offers.service';

interface IOffersStore {
  /**
   * Currently selected offer
   */
  readonly offer: Offer | undefined;
  /**
   * List of all offers
   */
  readonly offers: Offer[];
  /**
   * Is currently fetching offers or single offer
   */
  readonly isLoadingOffers: boolean;
  /**
   * Has finished fetch request for offers or single offer,
   * true no matter if the response was an error or success
   */
  readonly hasLoadedOffers: boolean;
}

interface IOffersAPI {
  /**
   * Fetch all offers for cart
   */
  getOffers: (cartId: string) => Promise<Offer[]>;
  /**
   * Fetch single offer
   */
  getOffer: (cartId: string, offerId: string) => Promise<Offer>;
}

const initialState: IOffersStore = {
  offer: undefined,
  offers: [],
  isLoadingOffers: false,
  hasLoadedOffers: false,
};

enum ActionType {
  SET_OFFERS,
  SET_OFFER,
  START_LOADING_OFFERS,
  STOP_LOADING_OFFERS,
}

type Action =
  | { type: ActionType.SET_OFFER; payload: Offer }
  | { type: ActionType.SET_OFFERS; payload: Offer[] }
  | { type: ActionType.START_LOADING_OFFERS }
  | { type: ActionType.STOP_LOADING_OFFERS };

const OffersAPI = createContext<IOffersAPI>({} as IOffersAPI);
const OffersStore = createContext<IOffersStore>({} as IOffersStore);

const reducer = (state: IOffersStore, action: Action): IOffersStore => {
  switch (action.type) {
    case ActionType.SET_OFFERS:
      return {
        ...state,
        offers: action.payload,
        isLoadingOffers: false,
        hasLoadedOffers: true,
      };
    case ActionType.SET_OFFER:
      return {
        ...state,
        offer: action.payload,
        isLoadingOffers: false,
        hasLoadedOffers: true,
      };
    case ActionType.START_LOADING_OFFERS:
      return {
        ...state,
        isLoadingOffers: true,
        hasLoadedOffers: false,
      };
    case ActionType.STOP_LOADING_OFFERS:
      return {
        ...state,
        isLoadingOffers: false,
        hasLoadedOffers: true,
      };
    default:
      return state;
  }
};

export function OffersProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: IOffersStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: IOffersAPI = useMemo(() => {
    const getOffers = async (cartId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_OFFERS });
        const offers = await OffersService.getOffers(cartId);
        dispatch({ type: ActionType.SET_OFFERS, payload: offers });
        return offers;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_OFFERS });
        logError(LogPrefix.Cart, error, 'Could not get Offers');
        return [];
      }
    };

    const getOffer = async (cartId: string, offerId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_OFFERS });
        const offer = await OffersService.getOffer(cartId, offerId);
        dispatch({ type: ActionType.SET_OFFER, payload: offer });
        return offer;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_OFFERS });
        logError(LogPrefix.Cart, error, 'Could not get Offer');
        throw error;
      }
    };
    return { getOffers, getOffer };
  }, []);

  return (
    <OffersAPI.Provider value={api}>
      <OffersStore.Provider value={state}>{children}</OffersStore.Provider>
    </OffersAPI.Provider>
  );
}

export const useOffersStore = (): IOffersStore => useContext(OffersStore);
export const useOffersAPI = (): IOffersAPI => useContext(OffersAPI);
