import { Place } from 'types/place';

export const sortPlaceByName = (a: Place, b: Place) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};
