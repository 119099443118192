import { useTranslation } from 'react-i18next';
import { NavigationListButton } from '@caspeco/casper-ui-library.components.navigation-list';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';
import {
  ThemeColorVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { useAppStore } from 'store/appStore';

export function ListItemContactAppName({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();
  const { appData } = useAppStore();
  return (
    <>
      <NavigationListButton
        aria-label={t('misc_contact_x', { 0: appData?.appName })}
        onClick={onClick}
      >
        <Flex align="center" gap={ThemeSpaceVariable.Small}>
          <Icon
            icon={Icons.Email}
            size={ThemeIconSizeVariable.Small}
            color={ThemeColorVariable.OnBackground}
          />
          <Text
            color={ThemeColorVariable.OnBackground}
            whiteSpace="break-spaces"
            wordBreak="break-all"
          >
            {t('misc_contact_x', { 0: appData?.appName })}
          </Text>
        </Flex>
      </NavigationListButton>
    </>
  );
}
