import { config, EEnvironment } from 'config/app.config';
import rg4js from 'raygun4js';
import { ICookieConsent } from 'types/cookies';
import { getAppAlias } from 'helpers/app-helper/app-helper';
import { setLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { deleteSessionStorage } from 'helpers/session-storage-helper/session-storage-helper';

export const setCookieConsent = (
  cookieConsent: ICookieConsent,
  expireDays: number = 365,
) => {
  const appAlias = getAppAlias();
  const d = new Date();
  const value = JSON.stringify(cookieConsent);
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${appAlias}/cookieConsent=${value};${expires};path=/;SameSite=Lax;`;
};

const getCookie = (cookieName: string) => {
  const appAlias = getAppAlias();
  const name = `${appAlias}/${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getCookieConsent = (): undefined | ICookieConsent => {
  const cookieValue = getCookie('cookieConsent');
  if (!cookieValue) return undefined;
  try {
    const parsed = JSON.parse(cookieValue);
    return parsed as ICookieConsent;
  } catch (_error) {
    return undefined;
  }
};

const disableCrashReporting = () => {
  switch (config.environment) {
    /* For both demo and stage environments, ignore disable action call.
     * Always keep crash reporting enabled internally (the exception is dev-local) */
    case EEnvironment.demo:
    case EEnvironment.stage:
    case EEnvironment.dev:
      return;
    default:
      rg4js('enableCrashReporting', false);
      break;
  }
};

export const onEnableAnalyticsCookies = () => {
  // Enable crash reporting statistics to be reported to Raygun (except in local development mode)
  rg4js('enableCrashReporting', config.environment !== EEnvironment.devLocal);
};

/**
 * Removes stored data + stops tracking that cannot be considered vital for the site to function
 * TODO: Handle this more efficiently based on cookie categories
 */
export const onDisableAnalyticsCookies = () => {
  deleteSessionStorage('initiatedFromAppLink');
  deleteSessionStorage('initiatedFromAppLinkId');
  setLocalStorage('preferredPayment', undefined);
  disableCrashReporting();
};
