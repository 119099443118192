import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { ICookieConsent } from 'types/cookies';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Checkbox } from '@caspeco/casper-ui-library.components.checkbox';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@caspeco/casper-ui-library.components.modal';
import { Link } from '@caspeco/casper-ui-library.components.link';

interface ICookieSettings {
  onSave: () => void;
  onGoBack: () => void;
  values: ICookieConsent;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function CookieSettings({
  onGoBack,
  values,
  onChange,
  onSave,
}: ICookieSettings) {
  const { appAlias } = useParams<'appAlias'>();
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader
        p={ThemeSpaceVariable.Medium}
        minH="auto"
        display="flex"
        justifyContent="space-between"
      >
        <Heading
          as="h2"
          fontSize={ThemeFontSizeVariable.XLarge}
          fontFamily={ThemeFontVariable.SubHeader}
          color={ThemeColorVariable.OnSurfaceHeader}
          fontWeight="medium"
        >
          {t('misc_settings')}
        </Heading>
      </ModalHeader>
      <ModalBody px={ThemeSpaceVariable.Medium} pb={ThemeSpaceVariable.Large}>
        <Text>
          {t('cookies_usage_read_more')}
          <Link
            href={`/${appAlias}/cookie-policy`}
            target="_blank"
            color="inherit"
            rel="noreferrer"
          >
            {t('cookies_policy').toLocaleLowerCase()}
          </Link>
          {t('cookies_then_make_your_choices')} {t('cookies_see_list')}
          <Link
            href={`/${appAlias}/cookie-list`}
            target="_blank"
            color="inherit"
            rel="noreferrer"
          >
            {t('misc_here')}
          </Link>
          .
        </Text>
        <Box my={ThemeSpaceVariable.Large}>
          <Stack spacing={ThemeSpaceVariable.Medium}>
            <Checkbox id="necessary" isChecked isDisabled>
              <Text color={ThemeColorVariable.OnSurface}>
                {t('cookies_necessary')}
              </Text>
            </Checkbox>
            <Text>{t('cookies_necessary_description')}</Text>
            <Checkbox
              id="analytics"
              isChecked={values.analytics}
              onChange={onChange}
            >
              <Text color={ThemeColorVariable.OnSurface}>
                {t('cookies_analytics_statistics')}
              </Text>
            </Checkbox>
            <Text>{t('cookies_analytics_statistics_description')}</Text>
          </Stack>
        </Box>
      </ModalBody>
      <ModalFooter
        gap={ThemeSpaceVariable.Medium}
        pt={ThemeSpaceVariable.Medium}
        px={ThemeSpaceVariable.Medium}
        pb="calc(1em + env(safe-area-inset-bottom))"
      >
        <Button
          variant="ghost"
          size={{ base: 'lg', md: 'md' }}
          onClick={onGoBack}
        >
          <Text
            color={ThemeColorVariable.OnSurface}
            fontWeight="inherit"
            fontFamily="inherit"
            fontSize={{
              base: ThemeFontSizeVariable.Large,
              md: ThemeFontSizeVariable.Medium,
            }}
          >
            {t('action_back')}
          </Text>
        </Button>
        <Button
          variant="primary"
          size={{ base: 'lg', md: 'md' }}
          onClick={onSave}
        >
          {t('action_save_and_accept')}
        </Button>
      </ModalFooter>
    </>
  );
}

export default CookieSettings;
