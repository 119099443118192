export type LogAttributeKey =
  | 'cartId'
  | 'applicationName'
  | 'applicationId'
  | 'userId'
  | 'guestId';

export enum LogLevel {
  Warn,
  Debug,
  Info,
  Error,
}

/**
 * Identifier for what entity a log message relates to.
 * Used to prefix log messages and make them easier to scan when
 * displayed in a list of many log messages.
 */
export enum LogPrefix {
  App = '[App]',
  Cart = '[Cart]',
  Auth = '[Auth]',
  User = '[User]',
  Order = '[Order]',
  Menu = '[Menu]',
  Place = '[Place]',
  Article = '[Article]',
  Link = '[Link]',
  Payment = '[Payment]',
  History = '[History]',
  Analytics = '[Analytics]',
}
