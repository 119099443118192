import { useState, useEffect } from 'react';
import { AppService } from 'services/App.service';
import { AppData } from 'types/app';
import { IRouteRequestState } from 'types/routeRequestState';
import { EEnvironment, config } from 'config/app.config';
import { Auth, User as FirebaseUser, connectAuthEmulator } from 'firebase/auth';
import { FirebaseApp } from '@firebase/app';
import { AuthService } from 'services/Auth.service';
import { storeFirebaseUserCredentials } from 'helpers/auth-helper/auth-helper';
import { addLogAttribute } from 'helpers/log-helper/log-helper';
import {
  getAppAlias,
  locallyStoreAppDataValues,
} from 'helpers/app-helper/app-helper';
import packageJson from '../../../package.json';
import { setHTMLHeadAppProperties } from './App.helper';

interface IAppRequestsState extends IRouteRequestState {
  appData: AppData | undefined;
  auth: Auth | undefined;
  firebaseApp: FirebaseApp | undefined;
  firebaseUser: FirebaseUser | null;
}
/**
 * Handles all requests that need to be made initially for loading App.
 * Until all requests have finished, will return @param isLoading = true.
 * If any request fails, will return @param error with error contents.
 *
 * Used to display a shared initial loading and error state.
 *
 * @returns app data, loading state and error state for all requests.
 */
export const useAppRequests = (): IAppRequestsState => {
  const appAlias = getAppAlias();
  const [requestState, setRequestState] = useState<IAppRequestsState>({
    isLoading: true,
    error: undefined,
    appData: undefined,
    auth: undefined,
    firebaseApp: undefined,
    firebaseUser: null,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!appAlias) throw new Error();
        const appData = await AppService.getApp(appAlias);

        setHTMLHeadAppProperties(appData);
        locallyStoreAppDataValues(appData);

        // Define log attributes
        addLogAttribute('applicationName', appData.appName);
        addLogAttribute('applicationId', appData.appId);

        if (config.environment === EEnvironment.mswCI) {
          // Mock successful auth
          setRequestState({
            ...requestState,
            isLoading: false,
            appData,
            auth: {} as Auth,
            firebaseApp: {} as FirebaseApp,
            firebaseUser: { isAnonymous: true } as FirebaseUser,
          });
          return;
        }

        // Initialize Firebase and get auth + user instance
        const { auth, firebaseApp } = await AuthService.initializeFirebase(
          appData.firebaseConfig,
        );

        if (config.environment === EEnvironment.msw) {
          // App is running in test environment, setup Firebase Auth emulator
          connectAuthEmulator(auth, 'http://127.0.0.1:9099');
        }

        const firebaseUser = await AuthService.getCurrentUser(auth);
        if (firebaseUser) await storeFirebaseUserCredentials(firebaseUser);

        setRequestState({
          ...requestState,
          isLoading: false,
          appData,
          auth,
          firebaseApp,
          firebaseUser,
        });
      } catch (error) {
        setRequestState({ ...requestState, isLoading: false, error });
      }
    };

    console.log(
      `Running BSTL-web version ${packageJson.version} in ${config.environment} environment.`,
    );
    fetch();
  }, []);

  return { ...requestState };
};
