import { logDebug } from 'helpers/log-helper/log-helper';
import { DateTime } from 'luxon';
import { LogPrefix } from 'types/logging';
import { Order } from 'types/order';

/** Returns true if order has reached a final state and won't change any more. */
export const reachedFinalOrderStatus = (order: Order | undefined): boolean =>
  order?.deliveryStatus === 'DELIVERED' ||
  order?.status === 'REFUNDED' ||
  order?.status === 'CANCELED';

/**
 * Creates and clicks a download link element that will open
 * the receipt file in the browser.
 *
 * @param receiptBlob receipt file to download
 * @param paidAt timestamp used to create unique download link name
 * @param orderNumber timestamp used to create unique download link name
 */
export const downloadReceipt = (
  receiptBlob: Blob,
  paidAt: string,
  orderNumber: number,
) => {
  // Create a link pointing to the ObjectURL containing the blob.
  const objectURL = window.URL.createObjectURL(receiptBlob);
  const fileName = `${DateTime.fromISO(paidAt).toFormat(
    'yyyy-MM-dd',
  )}_${orderNumber}.pdf`;
  const link = document.createElement('a');
  // Add attributes to the link element
  link.href = objectURL;
  link.download = fileName;

  // Log info
  logDebug(
    LogPrefix.Order,
    `Creating Order Receipt Download Link: ${link.download}`,
  );

  // Manually click the download link
  link.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(objectURL);
  }, 100);
};
