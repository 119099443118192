import i18next from 'i18next';
import { DateTime, Info } from 'luxon';
import { groupBy } from 'helpers/array-helper/array-helper';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { SupportedLanguage } from 'types/language';
import { SupportedLocale } from 'types/locale';
import {
  IOpeningHoursDisplayData,
  RelationalDay,
  IOpeningHours,
  IHours,
} from 'types/openingHours';

interface IHelperOpeningHourObject {
  opens: string;
  closes: string;
  weekday: number;
}

interface IOpeningHoursHelper {
  getOpeningStatus: (at: DateTime) => IOpeningHoursDisplayData;
  isOpen: (at: DateTime) => boolean;
}

const getHourMinuteAsNumbers = (
  hourMinuteString: string,
): { hour: number; minute: number } => ({
  hour: parseInt(hourMinuteString.split(':')[0], 10),
  minute: parseInt(hourMinuteString.split(':')[1], 10),
});

const opensFirst = (
  a: IHelperOpeningHourObject,
  b: IHelperOpeningHourObject,
) => {
  if (a.opens < b.opens) {
    return -1;
  }
  if (a.opens > b.opens) {
    return 1;
  }
  return 0;
};

const spansOverMidnight = (opens: string, closes: string): boolean =>
  closes < opens;

/**
 * Returns weekday number based on weekday name string.
 * For ex. "monday" => 1
 */
const getWeekdayNumber = (weekdayString: string): number | null => {
  switch (weekdayString.toUpperCase()) {
    case 'MONDAY':
      return 1;
    case 'TUESDAY':
      return 2;
    case 'WEDNESDAY':
      return 3;
    case 'THURSDAY':
      return 4;
    case 'FRIDAY':
      return 5;
    case 'SATURDAY':
      return 6;
    case 'SUNDAY':
      return 7;
    default:
      return null;
  }
};

/**
 * Gets the full weekday name based on current selected language.
 * For ex, with Swedish language selection, 1 => "måndag"
 */
export const getWeekdayString = (
  weekdayNumber?: number | null,
): string | null => {
  if (!weekdayNumber) return null;
  const locale = i18next.language ?? SupportedLanguage.EN;
  switch (weekdayNumber) {
    case 1:
      return Info.weekdays('long', { locale })[0];
    case 2:
      return Info.weekdays('long', { locale })[1];
    case 3:
      return Info.weekdays('long', { locale })[2];
    case 4:
      return Info.weekdays('long', { locale })[3];
    case 5:
      return Info.weekdays('long', { locale })[4];
    case 6:
      return Info.weekdays('long', { locale })[5];
    case 7:
      return Info.weekdays('long', { locale })[6];
    default:
      return null;
  }
};

/**
 * Returns valid hour and minute numbers as string in local 'hh:mm'-format.
 */
const getLocalHourMinuteString = (
  hour: number,
  minute: number,
  placeTimezone: string,
): string | null => {
  // Look for valid hour and minute numbers
  if (hour < 24 && minute < 60) {
    const {
      year: currentYear,
      month: currentMonth,
      day: currentDate,
    } = DateTime.local();

    // Create a new local DateTime for hour and minute in timezone of place
    const time = DateTime.local(
      currentYear,
      currentMonth,
      currentDate,
      hour,
      minute,
      { zone: placeTimezone },
    );

    return time.toLocaleString(DateTime.TIME_24_SIMPLE);
  }
  return null;
};

/**
 * @param at at what DateTime we want to know open/closed state.
 * @param placeTimezone timezone name for for place
 * @param openingHoursPerWeekday  opening hours grouped by weekday
 * @returns true or false
 */
const _isOpen = (
  at: DateTime,
  openingHour: IHelperOpeningHourObject,
  placeTimezone: string,
): boolean => {
  const { weekday, hour, minute } = at;
  const localAtTime = getLocalHourMinuteString(hour, minute, placeTimezone);
  const weekdayOfYesterday = at.minus({ days: 1 }).weekday;

  if (!localAtTime || !openingHour.closes || !openingHour.opens) {
    return false;
  }

  if (spansOverMidnight(openingHour.opens, openingHour.closes)) {
    if (localAtTime >= openingHour.opens && weekday === openingHour.weekday) {
      return true;
    }

    if (
      localAtTime < openingHour.closes &&
      openingHour.weekday === weekdayOfYesterday
    ) {
      return true;
    }
  }

  if (weekday !== openingHour.weekday) {
    return false;
  }

  return localAtTime >= openingHour.opens && localAtTime < openingHour.closes;
};

/**
 * @param at at what DateTime we want to know the opening status.
 * @param placeTimezone timezone name for for place
 * @param openingHoursPerWeekday  opening hours grouped by weekday
 * @returns OpeningStatus object.
 */
function _getOpeningStatus(
  at: DateTime,
  placeTimezone: string,
  openingHoursPerWeekday: {
    [key: string]: IHelperOpeningHourObject[];
  } | null,
): IOpeningHoursDisplayData {
  const { weekday: localWeekday } = at;
  const atTime = getLocalHourMinuteString(at.hour, at.minute, placeTimezone);
  const weekdayOfTomorrow = at.plus({ days: 1 }).weekday;

  // No valid hours or local time, return no status
  if (!atTime || !openingHoursPerWeekday) {
    return { atRelation: undefined };
  }

  const currentOpeningHour = openingHoursPerWeekday[localWeekday]?.find((h) =>
    _isOpen(at, h, placeTimezone),
  );

  // Is currently open
  if (currentOpeningHour) {
    return {
      atRelation: RelationalDay.Today,
      at: {
        opens: currentOpeningHour.opens,
        closes: currentOpeningHour.closes,
      },
    };
  }

  const openingHourLaterToday = openingHoursPerWeekday[localWeekday]?.find(
    (h) => atTime < h.opens,
  );

  // Found an opening hour later today
  if (openingHourLaterToday) {
    return {
      atRelation: RelationalDay.Today,
      at: {
        opens: openingHourLaterToday.opens,
        closes: openingHourLaterToday.closes,
      },
    };
  }

  const availableWeekdays = Object.keys(openingHoursPerWeekday);
  const closestWeekDayToToday =
    // Find the first weekday that is after today
    availableWeekdays.find((w) => parseInt(w, 10) > localWeekday) ??
    // Fall back to finding the first available day of week
    availableWeekdays.find((w) => parseInt(w, 10) > 0) ??
    // Last resort, try first weekday
    '1';

  const nextOpeningHour = openingHoursPerWeekday[closestWeekDayToToday]?.[0];

  // The next opening hour found is tomorrow
  if (nextOpeningHour?.weekday === weekdayOfTomorrow) {
    return {
      atRelation: RelationalDay.Tomorrow,
      at: {
        opens: nextOpeningHour.opens,
        closes: nextOpeningHour.closes,
      },
    };
  }

  // Found an upcoming opening hour another weekday that is not tomorrow
  if (nextOpeningHour?.weekday !== weekdayOfTomorrow) {
    return {
      atRelation: RelationalDay.OtherWeekday,
      at: {
        opens: nextOpeningHour.opens,
        closes: nextOpeningHour.closes,
      },
      weekday: nextOpeningHour.weekday,
    };
  }

  return { atRelation: undefined };
}

/**
 * Creates an instance of a opening hours 'helper' that wraps
 * internal logic based on input parameters openingHours and placeTimezone.
 *
 * @param openingHours Opening hours for place or menu.
 * @param placeTimezone Timezone for place.
 * @returns helper methods 'isOpen' and 'getOpeningStatus' for openingHours and placeTimezone.
 */
export const createLocalOpeningHoursHelper = (
  openingHours: IOpeningHours[],
  placeTimezone?: string,
): IOpeningHoursHelper => {
  const _placeTimezone: string =
    placeTimezone ??
    getLocalStorage<string>('placeTimezoneName') ??
    'Europe/Stockholm'; // Last resort, fall back to a default value

  const allOpeningHours = openingHours?.flatMap((openingHour) =>
    // Create an internal version of each opening hour to be used within this helper context
    openingHour.hours
      .map((hour) => {
        const { hour: openHour, minute: openMinute } = getHourMinuteAsNumbers(
          hour.opens,
        );
        const { hour: closesHour, minute: closesMinute } =
          getHourMinuteAsNumbers(hour.closes);
        return <IHelperOpeningHourObject>{
          opens: getLocalHourMinuteString(openHour, openMinute, _placeTimezone),
          closes: getLocalHourMinuteString(
            closesHour,
            closesMinute,
            _placeTimezone,
          ),
          weekday: getWeekdayNumber(openingHour.weekDay),
        };
      })
      .sort(opensFirst),
  );

  let groupedAndSortedByWeekDay: {
    [key: string]: IHelperOpeningHourObject[];
  } | null = null;

  if (allOpeningHours.length > 0) {
    groupedAndSortedByWeekDay = groupBy(allOpeningHours, 'weekday') as {
      weekday: IHelperOpeningHourObject[];
    };
  }

  const getOpeningStatus = (at: DateTime): IOpeningHoursDisplayData =>
    _getOpeningStatus(at, _placeTimezone, groupedAndSortedByWeekDay);

  const isOpen = (at: DateTime): boolean =>
    allOpeningHours.some((h) => _isOpen(at, h, _placeTimezone));

  return { getOpeningStatus, isOpen };
};

/**
 * Provide all opening hours available for entity (for ex. place)
 * @param allOpeningHours
 * Used for TESTING purposes. Provide specific dateTime to extract weekday from.
 * @param at
 * @returns Array of IHours for that exist for current weekday.
 */
export const getOpeningHoursForCurrentWeekday = (
  allOpeningHours: IOpeningHours[],
  at?: DateTime,
): IHours[] => {
  const { weekday: currentWeekdayNumber } = at ?? DateTime.now();
  let openingHoursForCurrentDay: IHours[] = [];
  const isCurrentWeekDay = (weekdayString: string): boolean =>
    getWeekdayNumber(weekdayString) === currentWeekdayNumber;

  allOpeningHours.forEach((openingHour) => {
    if (isCurrentWeekDay(openingHour.weekDay)) {
      openingHoursForCurrentDay = openingHoursForCurrentDay.concat(
        openingHour.hours,
      );
    }
  });

  return openingHoursForCurrentDay;
};

/**
 * Creates a text label showing the current or next relevant opening hours to display.
 * For ex. "Opens tomorrow at 12:00" or if currently open "13:00-17:00".
 */
export const getOpeningHoursLabel = (
  openingStatus: IOpeningHoursDisplayData,
): string | undefined => {
  const { atRelation, at, weekday } = openingStatus;
  if (!at?.opens) return undefined;

  let label: string | undefined;
  const translatedWeekday = getWeekdayString(weekday);

  switch (atRelation) {
    case RelationalDay.Today:
      label = `${at.opens} – ${at.closes}`;
      break;
    case RelationalDay.Tomorrow:
      label = i18next.t('time_opens_tomorrow_at_x', { 0: at.opens });
      break;
    case RelationalDay.OtherWeekday: {
      label = i18next.t('time_opens_at_x', {
        0: `${translatedWeekday} ${at.opens}`,
      });
      break;
    }
    default:
      label = undefined;
  }
  return label;
};

export const getLongWeekday = (weekday: string): string => {
  const locale = i18next.language ?? SupportedLocale.SV;
  switch (weekday) {
    case 'MONDAY':
      return Info.weekdays('long', { locale })[0];
    case 'TUESDAY':
      return Info.weekdays('long', { locale })[1];
    case 'WEDNESDAY':
      return Info.weekdays('long', { locale })[2];
    case 'THURSDAY':
      return Info.weekdays('long', { locale })[3];
    case 'FRIDAY':
      return Info.weekdays('long', { locale })[4];
    case 'SATURDAY':
      return Info.weekdays('long', { locale })[5];
    default:
      return Info.weekdays('long', { locale })[6];
  }
};

/**
 * Gets city name of time zone name.
 * @param timeZoneName For ex. 'Europe/Stockholm'
 * @returns 'Stockholm'
 */
export const getTimeZoneCity = (timeZoneName: string | undefined): string => {
  const tzn = timeZoneName ?? '';
  return tzn.includes('/') ? tzn.split('/')[1] : tzn;
};

/**
 * Modifies list of openingHours to add closed days.
 * @param openingHours List of IOpeningHours
 * @returns List of IOpeningHours with all weekdays added
 */
export const getDaysOpen = (openingHours: IOpeningHours[]) => {
  const flatDayList = openingHours.map((x) => x.weekDay);
  if (!flatDayList.includes('MONDAY')) {
    openingHours.splice(0, 0, {
      weekDay: 'MONDAY',
      hours: [],
      inherited: false,
    });
  }
  if (!flatDayList.includes('TUESDAY')) {
    openingHours.splice(1, 0, {
      weekDay: 'TUESDAY',
      hours: [],
      inherited: false,
    });
  }
  if (!flatDayList.includes('WEDNESDAY')) {
    openingHours.splice(2, 0, {
      weekDay: 'WEDNESDAY',
      hours: [],
      inherited: false,
    });
  }
  if (!flatDayList.includes('THURSDAY')) {
    openingHours.splice(3, 0, {
      weekDay: 'THURSDAY',
      hours: [],
      inherited: false,
    });
  }
  if (!flatDayList.includes('FRIDAY')) {
    openingHours.splice(4, 0, {
      weekDay: 'FRIDAY',
      hours: [],
      inherited: false,
    });
  }
  if (!flatDayList.includes('SATURDAY')) {
    openingHours.splice(5, 0, {
      weekDay: 'SATURDAY',
      hours: [],
      inherited: false,
    });
  }
  if (!flatDayList.includes('SUNDAY')) {
    openingHours.splice(6, 0, {
      weekDay: 'SUNDAY',
      hours: [],
      inherited: false,
    });
  }
  return openingHours;
};
