import { IPriceIncludingVatValues, PriceIncludingVat } from './price';

export type FixedTip = {
  cents: number;
  // A whole number representing the percentage of the fixed tip option
  option: number;
  sortOrder: number;
  valid: boolean;
};

export interface ITipInfoResponse {
  tipAmount: IPriceIncludingVatValues;
  maxTipAmountAllowed: IPriceIncludingVatValues;
  fixedTips: FixedTip[];
}

export class TipInfo {
  tipAmount: PriceIncludingVat;
  maxTipAmountAllowed: PriceIncludingVat;
  fixedTips: FixedTip[];

  constructor(values?: ITipInfoResponse) {
    this.tipAmount = new PriceIncludingVat(values?.tipAmount);
    this.maxTipAmountAllowed = new PriceIncludingVat(
      values?.maxTipAmountAllowed,
    );
    this.fixedTips =
      values?.fixedTips.sort((a, b) => a.sortOrder - b.sortOrder) ?? [];
  }
}
