import {
  ThemeColorVariable,
  ThemeFontVariable,
  ThemeSpaceVariable,
  ThemeFontSizeVariable,
  ThemeRadiusVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import styled from 'styled-components';

export const StyledPhoneInput = styled.div`
  /* stylelint-disable selector-class-pattern */
  .PhoneInput {
    box-sizing: border-box;
    height: 48px;
    max-height: 48px;
    min-height: 48px;
  }

  .PhoneInputCountry {
    background-color: ${ThemeColorVariable.SurfaceDisabled};
    border: 1px solid ${ThemeColorVariable.OnSurfaceBorder};
    border-radius: ${ThemeRadiusVariable.Small};
    border-bottom-right-radius: unset;
    border-right: unset;
    border-top-right-radius: unset;
    box-sizing: border-box;
    color: ${ThemeColorVariable.OnSurface};
    height: 48px;
    margin-right: 0;
    max-height: 48px;
    min-height: 48px;
    padding-left: 14px;
    padding-right: ${ThemeSpaceVariable.Small};

    &:hover {
      border: 1px solid;
      border-color: ${ThemeColorVariable.OnSurface};
    }
  }

  .PhoneInputCountrySelectArrow {
    border-color: ${ThemeColorVariable.OnSurface};
    margin-left: ${ThemeSpaceVariable.Small};
  }

  input {
    background: ${ThemeColorVariable.Surface};
    border: 1px solid ${ThemeColorVariable.OnSurfaceBorder};
    border-radius: ${ThemeRadiusVariable.Small};
    border-bottom-left-radius: unset;
    border-top-left-radius: unset;
    box-sizing: border-box;
    color: ${ThemeColorVariable.OnSurface};
    font-family: ${ThemeFontVariable.Body};
    font-size: ${ThemeFontSizeVariable.Medium};
    height: 48px;
    margin: 0;
    max-height: 48px;
    min-height: 48px;
    padding: 10px;
    width: 100%;

    ::placeholder {
      color: ${ThemeColorVariable.OnSurfaceSubdued};

      /* Override default behaviour of lower opacity to have control over color */
      opacity: 1;
    }

    &[aria-invalid='true'] {
      border-color: ${ThemeColorVariable.Error};
      box-shadow: 0 0 0 4px ${ThemeColorVariable.ErrorContainer};
    }

    &:hover {
      border: 1px solid;
      border-color: ${ThemeColorVariable.OnSurface};
    }
  }

  input:focus,
  input:focus-visible,
  .PhoneInputCountry:focus-within {
    appearance: none;
    border-color: ${ThemeColorVariable.Info};
    box-shadow: 0 0 0 4px ${ThemeColorVariable.InfoContainer};
    box-sizing: border-box;
    outline: none;
  }
`;
