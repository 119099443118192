import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Heading } from '@caspeco/casper-ui-library.components.heading';

interface ITitle {
  title: string;
}

export function Title({ title }: ITitle) {
  return (
    <Heading
      color={ThemeColorVariable.OnPrimary}
      fontSize={{
        base: ThemeFontSizeVariable.XLarge,
        md: ThemeFontSizeVariable.X2Large,
      }}
      fontFamily={ThemeFontVariable.Header}
      fontWeight="bold"
      as="h1"
      textAlign="center"
      noOfLines={2}
    >
      {title}
    </Heading>
  );
}
