import { useTranslation } from 'react-i18next';
import {
  ThemeColorVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { NavigationListLink } from '@caspeco/casper-ui-library.components.navigation-list';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { IAppLinks } from 'types/app';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';

export function ListItemTermsAndConditions() {
  const links = getLocalStorage<IAppLinks>('links');
  const termsAndConditionsURL = links?.termsAndConditionsURL;
  const { t } = useTranslation();
  return (
    <NavigationListLink href={termsAndConditionsURL} target="_blank">
      <Flex align="center" justifyContent="space-between">
        <Flex gap={ThemeSpaceVariable.Small} align="center">
          <Icon
            icon={Icons.File}
            size={ThemeIconSizeVariable.Small}
            color={ThemeColorVariable.OnBackground}
          />
          <Text color={ThemeColorVariable.OnBackground}>
            {t('app_terms_and_conditions')}
          </Text>
        </Flex>
        <Icon
          icon={Icons.LinkExternal}
          size={ThemeIconSizeVariable.Small}
          color={ThemeColorVariable.OnBackground}
        />
      </Flex>
    </NavigationListLink>
  );
}
