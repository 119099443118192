import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import styled from 'styled-components';

type IStyledGridWithDividersProps = {
  columnCount: number;
  dividerColor: ThemeColorVariable;
};

export const StyledGridWithDividers = styled.div<IStyledGridWithDividersProps>`
  --gap: 32px;
  --negative-gap: -32px;

  .grid-with-dividers-griditem {
    position: relative;
    text-align: left;
    user-select: none;
    width: 100%;

    /* Horizontal divider */
    ::before {
      background: ${(props) => props.dividerColor};
      bottom: calc(var(--negative-gap) / 2);
      content: '';
      height: 1px;
      left: var(--negative-gap);
      position: absolute;
      width: calc(100% + var(--gap));
    }
  }

  .grid-with-dividers-griditem:not(
      :nth-child(${(props) => `${props.columnCount}n`})
    ) {
    /* Vertical divider to the right of all items except last item per row */
    ::after {
      background-color: ${(props) => props.dividerColor};
      content: '';
      height: 100%;
      position: absolute;
      right: calc(var(--negative-gap) / 2);
      top: 0;
      width: 1px;
    }
  }

  .grid-with-dividers-griditem:nth-child(
      ${(props) => `${props.columnCount}n+1`}
    ) {
    /* Horizontal divider for every first item in grid row */
    ::before {
      background: ${(props) => props.dividerColor};
      bottom: calc(var(--negative-gap) / 2);
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: calc(100%);
    }
  }
`;
