import { useMemo } from 'react';
import { IAppLinks } from 'types/app';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';

export function useShowTermsAndConditions(): boolean {
  const links = getLocalStorage<IAppLinks>('links');
  const termsAndConditionsURL = links?.termsAndConditionsURL;

  const show = useMemo(() => Boolean(termsAndConditionsURL), [links]);
  return show;
}
