import { useSearchParams } from 'react-router-dom';
import { SearchParams } from 'types/search-params';

const pickSearchParams = <Param extends keyof typeof SearchParams>(
  searchParams: URLSearchParams,
  params: Param[],
) =>
  params.reduce(
    (allParams, param) => ({
      ...allParams,
      [param]: searchParams.get(param) || '',
    }),
    {} as Record<Param, string>,
  );

/**
 * Custom hook used to pick values from current search params,
 * with type support.
 * @example const { deliveryOptionId } = usePickParams(['deliveryOptionId']);
 */
export const usePickSearchParams = <
  Param extends keyof typeof SearchParams = keyof typeof SearchParams,
>(
  params: Param[],
) => {
  const [searchParams] = useSearchParams();
  return pickSearchParams(searchParams, params);
};
