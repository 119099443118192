import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'config/i18n';
import { Button } from '@caspeco/casper-ui-library.components.button';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Select } from '@caspeco/casper-ui-library.components.select';
import {
  getLocaleFromCurrentLang,
  getNameOfCurrentLang,
} from 'helpers/locale-helper/locale-helper';
import { useBSTLUserAPI, useBSTLUserStore } from 'store/bstlUserStore';

export default function LanguageSelect({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [value, setValue] = useState(i18n.language);
  const { updateUser } = useBSTLUserAPI();
  const { bstlUser } = useBSTLUserStore();
  const { t } = useTranslation();
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
  };

  const onSave = () => {
    i18n.changeLanguage(value);

    // If the user is signed in, this is were we update their language setting
    if (bstlUser) {
      const { name, phoneNumber, emailAddress } = bstlUser;
      const locale = getLocaleFromCurrentLang(value);
      updateUser({ name, phoneNumber, emailAddress, locale });
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader
        pt={ThemeSpaceVariable.Medium}
        px={ThemeSpaceVariable.Medium}
        display="flex"
        justifyContent="space-between"
        minH="auto"
        color={ThemeColorVariable.OnSurfaceHeader}
      >
        {t('misc_x_language', { 0: getNameOfCurrentLang() })}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody p={ThemeSpaceVariable.Medium}>
        <Select
          size="lg"
          name="language"
          w="100%"
          onChange={handleSelect}
          value={value}
          id="languageSelect"
        >
          <option value="sv">{t('misc_swedish')}</option>
          <option value="nb">{t('misc_norwegian')}</option>
          <option value="en">{t('misc_english')}</option>
        </Select>
      </ModalBody>
      <ModalFooter
        pt={ThemeSpaceVariable.Medium}
        px={ThemeSpaceVariable.Medium}
        pb="calc(1em + env(safe-area-inset-bottom))"
        display="flex"
        gap={ThemeSpaceVariable.Medium}
        justifyContent="flex-end"
      >
        <Button
          variant="primary"
          onClick={onSave}
          size={{ base: 'lg', md: 'md' }}
        >
          {t('action_save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
