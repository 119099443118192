import { useTranslation } from 'react-i18next';
import { Control, useController } from 'react-hook-form';
import {
  FormLabel,
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from '@caspeco/casper-ui-library.components.formcontrol';
import { Textarea } from '@caspeco/casper-ui-library.components.textarea';
import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { isValidMinLength } from 'helpers/validation-helper/validation-helper';
import { IAppFeedbackRequest } from 'types/app';

interface IFeedbackFormMessageField {
  control: Control<IAppFeedbackRequest, string>;
}

export function FeedbackFormMessageField({
  control,
}: IFeedbackFormMessageField) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    rules: {
      required: {
        value: true,
        message: t('form_enter_message'),
      },
      validate: (value) => isValidMinLength(value) || t('form_enter_message'),
      maxLength: 1000,
    },
    name: 'message',
  });

  const currentCount = field.value?.length ?? 0;
  const countLabel = `${currentCount}/1000`;

  return (
    <FormControl isRequired isInvalid={Boolean(error)}>
      <FormLabel color={ThemeColorVariable.OnSurface}>
        {t('form_message')}
      </FormLabel>
      <Textarea value={field.value} onChange={field.onChange} />
      {!error && (
        <FormHelperText
          color={ThemeColorVariable.OnSurfaceSubdued}
          textAlign="right"
        >
          {countLabel}
        </FormHelperText>
      )}
      <Flex justifyContent="space-between">
        <FormErrorMessage>{error?.message}</FormErrorMessage>
        <FormErrorMessage textAlign="right">{countLabel}</FormErrorMessage>
      </Flex>
    </FormControl>
  );
}
