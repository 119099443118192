import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DeliveryType } from 'types/deliveryOption';
import { getDeliveryTypeTitle } from 'helpers/deliveryoptions-helper/deliveryoptions-helper';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { RadioCard } from '@caspeco/casper-ui-library.components.radio';
import { useRadioGroup } from '@caspeco/casper-ui-library.components.radio-group';
import {
  FormControl,
  FormLabel,
} from '@caspeco/casper-ui-library.components.formcontrol';
import { ThemeSpaceVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { IDeliveryFormValues } from './DeliveryForm.types';

interface IDeliveryTypesField {
  options: DeliveryType[];
  field: ControllerRenderProps<FieldValues, 'type'>;
  fieldState: ControllerFieldState;
  onChange?: (value: string, fieldName: keyof IDeliveryFormValues) => void;
  isSingleType: boolean;
  /** When false, the field will be a hidden input. */
  isVisible?: boolean;
}

export function DeliveryTypesField({
  options,
  field,
  fieldState,
  onChange,
  isSingleType,
  isVisible = true,
}: IDeliveryTypesField) {
  const { t } = useTranslation();
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: field.name,
    onChange: (nextValue) => {
      if (onChange) onChange(nextValue, field.name);
      field.onChange(nextValue);
    },
    value: field.value,
  });
  const { ...radioGroupProps } = getRootProps();

  const visibility = isVisible ? 'visible' : 'hidden';
  const visibilityClassName = isVisible ? undefined : 'visually-hidden';
  const ariaHidden = isVisible ? undefined : true;

  return (
    <FormControl
      as="fieldset"
      isRequired
      visibility={visibility}
      className={visibilityClassName}
      aria-hidden={ariaHidden}
      my={isVisible ? ThemeSpaceVariable.Small : ThemeSpaceVariable.None}
      isDisabled={isSingleType}
      isInvalid={Boolean(fieldState.error)}
    >
      <FormLabel
        as="legend"
        m={0}
        className="visually-hidden"
        aria-hidden={ariaHidden}
      >
        {t('delivery_select_option')}
      </FormLabel>
      <Stack
        direction="row"
        w="100%"
        aria-hidden={ariaHidden}
        {...radioGroupProps}
      >
        {options.map((option) => {
          const radioProps = getRadioProps({ value: option });
          const title = getDeliveryTypeTitle(option);
          return (
            <RadioCard
              aria-hidden={ariaHidden}
              w="100%"
              value={option}
              key={option}
              {...radioProps}
            >
              {title}
            </RadioCard>
          );
        })}
      </Stack>
    </FormControl>
  );
}
