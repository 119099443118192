import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/confirm-dialog/ConfirmDialog';
import { useCartGuard } from 'hooks/useCartGuard';
import { useCartAPI, useCartStore } from 'store/cartStore';

export function CartGuard() {
  const { t } = useTranslation();
  const { cart } = useCartStore();
  const { deleteCart } = useCartAPI();
  const { blocker, nextLocation } = useCartGuard(cart);
  const { appAlias } = useParams<'appAlias'>();

  const title =
    nextLocation?.pathname === `/${appAlias}`
      ? t('location_change_question')
      : t('purchase_cart_will_be_emptied');

  const message =
    nextLocation?.pathname === `/${appAlias}`
      ? t('purchase_cart_will_be_emptied')
      : t('order_cancel_order_confirmation');

  const onConfirmLeave = () => {
    if (cart) deleteCart(cart.cartId);
    blocker.proceed?.();
  };

  const onCancel = () => {
    blocker.reset?.();
  };

  return (
    <ConfirmationDialog
      isOpen={blocker?.state === 'blocked'}
      title={title}
      message={message}
      primaryAction={onConfirmLeave}
      secondaryAction={onCancel}
      onClose={onCancel}
      primaryButtonText={t('action_continue')}
      secondaryButtonText={t('action_cancel')}
    />
  );
}
