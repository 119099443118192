import { useTranslation } from 'react-i18next';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import {
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Alert } from '@caspeco/casper-ui-library.components.alert';
import {
  getDaysOpen,
  getTimeZoneCity,
} from 'helpers/opening-hours-helper/opening-hours-helper';
import { Place } from 'types/place';
import OpeningHoursText from './OpeningHoursText';

type TOpeningHours = {
  headerColor: ThemeColorVariable;
} & Pick<Place, 'timeZoneName' | 'openingHours'>;

function OpeningHours({
  timeZoneName,
  openingHours,
  headerColor,
}: TOpeningHours) {
  const { t } = useTranslation();
  const userTimeZoneName: string =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userIsInPlaceTimezone: boolean = Boolean(
    timeZoneName?.includes(userTimeZoneName),
  );

  const list = getDaysOpen(openingHours);

  return (
    <Box as="section">
      {!userIsInPlaceTimezone && (
        <Box mb={ThemeSpaceVariable.Medium}>
          <Alert type="info">
            {t('time_timezone_x_differs_from_x_description', {
              0: getTimeZoneCity(timeZoneName),
              1: getTimeZoneCity(userTimeZoneName),
            })}
          </Alert>
        </Box>
      )}
      <Box mb={ThemeSpaceVariable.Small}>
        <Heading
          as="h3"
          color={headerColor}
          fontFamily={ThemeFontVariable.SubHeader}
          fontWeight="medium"
          fontSize={ThemeFontSizeVariable.Large}
        >
          {t('time_opening_hours')}
        </Heading>
      </Box>
      {list.map((openingHour, i) => (
        <OpeningHoursText
          key={i}
          hours={openingHour.hours}
          weekDay={openingHour.weekDay}
        />
      ))}
    </Box>
  );
}

export default OpeningHours;
