import { NavigationList } from '@caspeco/casper-ui-library.components.navigation-list';
import { ProtectedRoutes } from 'types/routes';
import { useShowContactAppName } from 'components/side-navigation/hooks/useShowContactApp';
import { useShowAccount } from '../../hooks/useShowAccount';
import { useShowChangeDelivery } from '../../hooks/useShowChangeDelivery';
import { useShowChangePlace } from '../../hooks/useShowChangePlace';
import { useShowOpeningHoursAndContact } from '../../hooks/useShowOpeningHoursAndContact';
import { useShowPrivacyPolicy } from '../../hooks/useShowPrivacyPolicy';
import { useShowSignIn } from '../../hooks/useShowSignIn';
import { useShowTermsAndConditions } from '../../hooks/useShowTermsAndConditions';
import { ListItemChangeDelivery } from '../side-navigation-list-items/ListItemChangeDelivery';
import { ListItemChangeLanguage } from '../side-navigation-list-items/ListItemChangeLanguage';
import { ListItemChangePlace } from '../side-navigation-list-items/ListItemChangePlace';
import { ListItemCookies } from '../side-navigation-list-items/ListItemCookies';
import { ListItemOpeningHoursAndContact } from '../side-navigation-list-items/ListItemOpeningHoursAndContact';
import { ListItemPrivacyPolicy } from '../side-navigation-list-items/ListItemPrivacyPolicy';
import { ListItemShowAccount } from '../side-navigation-list-items/ListItemShowAccount';
import { ListItemShowHistory } from '../side-navigation-list-items/ListItemShowHistory';
import { ListItemSignIn } from '../side-navigation-list-items/ListItemSignIn';
import { ListItemTermsAndConditions } from '../side-navigation-list-items/ListItemTermsAndConditions';
import { ListItemDivider } from '../side-navigation-list-items/ListItemDivider';
import { ListItemContactAppName } from '../side-navigation-list-items/ListItemContactAppName';

interface ISideNavigationList {
  route: ProtectedRoutes;
  onClickHistory: () => void;
  onClickChangePlace: () => void;
  onClickOpeningHoursAndContant: () => void;
  onClickChangeLanguage: () => void;
  onClickChangeDeliveryOption: () => void;
  onClickCookies: () => void;
  onClickAccount: () => void;
  onClickSignIn: () => void;
  onClickContactAppName: () => void;
}

export function SideNavigationList({
  route,
  onClickHistory,
  onClickChangePlace,
  onClickOpeningHoursAndContant,
  onClickChangeLanguage,
  onClickChangeDeliveryOption,
  onClickCookies,
  onClickAccount,
  onClickSignIn,
  onClickContactAppName,
}: ISideNavigationList) {
  const showAccount = useShowAccount();
  const showSignIn = useShowSignIn();
  const showPrivacyPolicy = useShowPrivacyPolicy();
  const showTermsAndConditionsURL = useShowTermsAndConditions();
  const showChangePlace = useShowChangePlace();
  const showOpeningHoursAndContact = useShowOpeningHoursAndContact();
  const showChangeDeliveryOption = useShowChangeDelivery();
  const showContactAppName = useShowContactAppName();

  // Potentially NONE of the first three list items are shown,
  // and in that case the first divider should not show
  const showFirstDivider: boolean =
    showChangePlace || showChangeDeliveryOption || showOpeningHoursAndContact;

  return (
    <NavigationList>
      {showChangePlace && <ListItemChangePlace onClick={onClickChangePlace} />}
      {showOpeningHoursAndContact && (
        <ListItemOpeningHoursAndContact
          onClick={onClickOpeningHoursAndContant}
        />
      )}
      {showChangeDeliveryOption && (
        <ListItemChangeDelivery
          onClick={onClickChangeDeliveryOption}
          route={route}
        />
      )}
      {showFirstDivider && <ListItemDivider />}
      {showAccount && <ListItemShowAccount onClick={onClickAccount} />}
      {showSignIn && <ListItemSignIn onClick={onClickSignIn} />}
      <ListItemShowHistory onClick={onClickHistory} />
      <ListItemDivider />
      <ListItemChangeLanguage onClick={onClickChangeLanguage} />
      {showContactAppName && (
        <ListItemContactAppName onClick={onClickContactAppName} />
      )}
      {showPrivacyPolicy && <ListItemPrivacyPolicy />}
      {showTermsAndConditionsURL && <ListItemTermsAndConditions />}
      <ListItemCookies onClick={onClickCookies} />
    </NavigationList>
  );
}
