import { datadogLogs } from '@datadog/browser-logs';
import { BSTLApiError } from 'types/api-error';
import { LogAttributeKey, LogLevel, LogPrefix } from 'types/logging';
import { config, EEnvironment } from '../../config/app.config';
import packageJson from '../../../package.json';

enum DataDogEnvironment {
  PROD = 'prod',
  DEMO = 'demo',
  STAGE = 'stage',
  DEV = 'dev',
}

function mapEnvironmentToDatadogEnv(): string | undefined {
  switch (config.environment) {
    case EEnvironment.demo:
      return DataDogEnvironment.DEMO;
    case EEnvironment.dev:
    case EEnvironment.devLocal:
      return DataDogEnvironment.DEV;
    case EEnvironment.prod:
      return DataDogEnvironment.PROD;
    case EEnvironment.stage:
      return DataDogEnvironment.STAGE;
    default:
      return undefined;
  }
}

export function addLogAttribute(key: LogAttributeKey, value: string): void {
  datadogLogs.setGlobalContextProperty(key, value);
}

export function removeLogAttribute(key: LogAttributeKey): void {
  datadogLogs.removeGlobalContextProperty(key);
}

/**
 * Inits logging with DataDog.
 */
export function setupLogger(): void {
  const datadogEnvironment = mapEnvironmentToDatadogEnv();
  if (!datadogEnvironment) {
    return;
  }

  datadogLogs.init({
    clientToken: 'pub6a803083e90dd1afdc69841693bd55f1',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: false,
    env: datadogEnvironment,
    sessionSampleRate: 100,
  });
  datadogLogs.setGlobalContext({
    application: 'bstl-web',
    version: packageJson.version,
  });
}

export function shouldLog(): boolean {
  if (config.environment !== EEnvironment.devLocal) {
    return true;
  }
  return false;
}

function shouldConsoleLog(): boolean {
  return (
    config.environment === EEnvironment.devLocal ||
    config.environment === EEnvironment.local ||
    config.environment === EEnvironment.msw
  );
}

// Errors that we don't want to send to DataDog, as we cannot do anything about them
const suppressedErrors = [
  'Failed to fetch',
  'Load failed',
  'network-request-failed',
  'NetworkError when attempting to fetch resource',
];

function shouldBeSuppressed(message: string): boolean {
  return suppressedErrors.some((error) => new RegExp(error).test(message));
}

function log(level: LogLevel, prefix: LogPrefix, message: string): void {
  const messageWithPrefix = `${prefix} ${message}`;
  const shouldLogLocally = shouldConsoleLog();
  switch (level) {
    case LogLevel.Debug:
      datadogLogs.logger.debug(messageWithPrefix);
      if (shouldLogLocally) console.log(messageWithPrefix);
      break;
    case LogLevel.Warn:
      datadogLogs.logger.warn(messageWithPrefix);
      if (shouldLogLocally) console.warn(messageWithPrefix);
      break;
    case LogLevel.Info:
      datadogLogs.logger.info(messageWithPrefix);
      if (shouldLogLocally) console.log(messageWithPrefix);
      break;
    case LogLevel.Error:
      if (shouldBeSuppressed(message)) return;

      datadogLogs.logger.error(messageWithPrefix);
      if (shouldLogLocally) console.error(messageWithPrefix);
      break;
    default:
  }
}

/**
 * Send message to logs as type "info".
 */
export function logInfo(prefix: LogPrefix, message: string): void {
  log(LogLevel.Info, prefix, message);
}

/**
 * Send message to logs as type "debug".
 * Use for events we want to track that are of neither other log types (error, info, warn).
 */
export function logDebug(prefix: LogPrefix, message: string): void {
  log(LogLevel.Debug, prefix, message);
}

/**
 * Send message to logs as type "warn".
 */
export function logWarn(prefix: LogPrefix, message: string): void {
  log(LogLevel.Warn, prefix, message);
}

/**
 * Send message to logs as type "error".
 * Use for error responses from API.
 * @param error Error object from catch clause. Will always be unknown as JS/TS catch clause will
 * catch any exception that is thrown, not just exceptions of a specified type.
 */
export function logError(
  prefix: LogPrefix,
  error: unknown,
  message: string,
): void {
  let errorLogString = message;

  if (error instanceof BSTLApiError || error instanceof Error) {
    // Is instance of Error, apply the original error message to log message
    errorLogString = `${message}. (Error: ${error.message})`;
  } else {
    // Not an instance of BSTL API Error, something else has gone wrong
    type AssumedError = { name?: string; message?: string };
    const errorName = (error as AssumedError)?.name;
    const errorMessage = (error as AssumedError)?.message;

    // If error has name and message properties, apply them to log string
    if (errorName && errorMessage) {
      errorLogString = `${message}. (Error: ${errorName}, ${errorMessage})`;
    }
  }

  log(LogLevel.Error, prefix, errorLogString);
}
