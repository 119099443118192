import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Icons } from '@caspeco/casper-ui-library.components.icon';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { AlertType } from '@caspeco/casper-ui-library.components.alert';
import { BSTLApiError } from 'types/api-error';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { useOrderAPI, useOrderStore } from 'store/orderStore';
import { downloadReceipt } from 'helpers/order-helper/order-helper';

export function HistoryOrderReceiptButton() {
  const { t } = useTranslation();
  const { getReceipt } = useOrderAPI();
  const { order } = useOrderStore();
  const { showToast } = useToast();

  const [isLoading, setLoading] = React.useState(false);

  const onClick = async () => {
    try {
      if (!order) throw new Error();
      setLoading(true);
      await getReceipt(order.orderId).then((receipt) =>
        downloadReceipt(receipt, order.paidAt, order.orderNumber),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      let type: AlertType = 'error';
      let description = t('error_get_receipt');
      if (error instanceof BSTLApiError && error.statusCode === 404) {
        description = t('order_receipt_not_created');
        type = 'info';
      }
      showToast({
        type,
        description,
      });
    }
  };

  const receiptIdExists = Boolean(order?.receiptId);

  return (
    <div>
      <Button
        type="button"
        variant="secondary"
        w="fit-content"
        onClick={onClick}
        isLoading={isLoading}
        leftIcon={Icons.Receipt}
      >
        <span>
          {receiptIdExists ? t('order_show_receipt') : t('order_get_receipt')}
        </span>
      </Button>
      {!receiptIdExists && (
        <Text
          mt={ThemeSpaceVariable.Small}
          fontSize={ThemeFontSizeVariable.Small}
          color={ThemeColorVariable.OnSurfaceSubdued}
        >
          {t('order_receipt_ready_in_a_few_minutes')}
        </Text>
      )}
    </div>
  );
}
