import { parsePhoneNumber } from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js/types';
import { SupportedLanguage } from 'types/language';

/**
 * Use to pre-select a country code for Phone number input,
 * based on existing phone number or (fallback) stored language preferences.
 */
export const getPhoneNumberCountryCode = (
  phoneNumber: string | undefined,
): CountryCode => {
  const storedLanguage = window.localStorage.getItem('i18nextLng');
  let defaultCountryCode: CountryCode = 'SE'; // Always fallback to Sweden for now

  if (phoneNumber) {
    // If phonenumber exists, try to identify and use that country code
    const parsedCountry = parsePhoneNumber(phoneNumber)?.country;
    if (parsedCountry) defaultCountryCode = parsedCountry;
  } else if (storedLanguage === SupportedLanguage.NB) {
    // Assume Norway as country code if the selected lang is NB
    defaultCountryCode = 'NO';
  }
  return defaultCountryCode;
};
