import { getLocaleFromCurrentLang } from 'helpers/locale-helper/locale-helper';
import { capitalizeFirstLetter } from 'helpers/string-helper/string-helper';
import { DateTime, Zone } from 'luxon';

/**
 * @returns true if a and b are the same date
 */
export function isSameDate(a: string | DateTime, b: string | DateTime) {
  const firstDate: DateTime =
    a === typeof 'string' ? DateTime.fromISO(a) : (a as DateTime);
  const secondDate: DateTime =
    b === typeof 'string' ? DateTime.fromISO(b) : (b as DateTime);

  return (
    firstDate.hasSame(secondDate, 'day') &&
    firstDate.hasSame(secondDate, 'month') &&
    firstDate.hasSame(secondDate, 'year')
  );
}

/**
 * Formats time in relation to today
 *
 * @param time DateTime to format.
 * @param timeZone Time zone, defaults to Europe/Stockholm.
 * @param shouldCapitalizeFirstLetter should capitalize first letter of day, defaults to true
 * @param locale optional locale, defaults to current selected language in application
 *
 * @returns If time is today - "Today H:mm"
 * If time is tomorrow - "Tomorrow H:mm"
 * If time is yesterday - "Yesterday H:mm"
 * If time is none of the above - "D H:mm"
 */
export const formatRelativeTimeAndDayWithLocale = (
  time: DateTime | string,
  timeZone: string | Zone = 'Europe/Stockholm',
  shouldCapitalizeFirstLetter: boolean = true,
  locale: undefined | string = getLocaleFromCurrentLang(),
): string => {
  let tempResult;

  if (typeof time === 'string') {
    tempResult = DateTime.fromISO(time).setZone(timeZone);
  } else {
    tempResult = time.setZone(timeZone);
  }
  const now = DateTime.local({ zone: timeZone });
  const formattedHourMinute = tempResult.toFormat('H:mm');

  // Relative date variables provided for readability
  const dateIsYesterday = isSameDate(now.minus({ day: 1 }), tempResult);
  const dateIsTomorrow = isSameDate(now.plus({ day: 1 }), tempResult);
  const dateIsToday = isSameDate(now, tempResult);

  const rtf = new Intl.RelativeTimeFormat(locale, {
    style: 'long',
    numeric: 'auto',
  });

  let result;
  switch (true) {
    case dateIsYesterday:
      result = `${rtf.format(-1, 'day')} ${formattedHourMinute}`;
      break;
    case dateIsToday:
      result = `${rtf.format(0, 'day')} ${formattedHourMinute}`;
      break;
    case dateIsTomorrow:
      result = `${rtf.format(1, 'day')} ${formattedHourMinute}`;
      break;
    default:
      // Return as formatted date with numbers, no capitalizing needed
      return tempResult.toFormat('D H:mm', { locale });
  }
  if (shouldCapitalizeFirstLetter) return capitalizeFirstLetter(result);
  return result;
};

/**
 * @param time
 * @param timeZone place time zone, defaults to Europe/Stockholm
 * @param locale optional locale, defaults to system
 *
 * @returns for ex. format24hTimeShort("2023-11-08T15:20:00.000Z", Europe/Stockholm, sv-SE) => "16:20"
 */
export const formatTimeShortWithLocale = (
  time: DateTime | string,
  timeZone: string = 'Europe/Stockholm',
  locale?: string,
): string => {
  let result;
  if (typeof time === 'string') {
    result = DateTime.fromISO(time).toJSDate();
  } else {
    result = time.toJSDate();
  }
  const rtf = new Intl.DateTimeFormat(locale, {
    timeStyle: 'short',
    timeZone,
  });
  return rtf.format(result);
};

/**
 * Formats time in relation to today
 *
 * @param time DateTime to format.
 * @param timeZone Time zone, defaults to Europe/Stockholm.
 * @param locale optional locale, defaults to system
 * @param monthFormat optional month format, defaults to short, for ex. "jan" compared to "january"
 *
 * @returns If time is today - "Today"
 * If time is tomorrow - "Tomorrow"
 * If time is none of the above - "EEEE dd MMM" (for ex. Saturday 18 Nov)
 * If time is another year - "yyyy-MM-dd" (for ex. 2029-11-08)
 */
export const formatRelativeDateWithLocale = (
  time: DateTime | string,
  timeZone: string | Zone = 'Europe/Stockholm',
  locale?: string,
  monthFormat?: 'long' | 'short',
): string => {
  let tempResult;

  if (typeof time === 'string') {
    tempResult = DateTime.fromISO(time).setZone(timeZone);
  } else {
    tempResult = time.setZone(timeZone);
  }
  const now = DateTime.local({ zone: timeZone });

  // Relative date variables provided for readability
  const dateIsToday = isSameDate(now, tempResult);
  const dateIsTomorrow = isSameDate(now.plus({ day: 1 }), tempResult);
  const dateIsAnotherYear = now.year !== tempResult.year;

  const rtf = new Intl.RelativeTimeFormat(locale, {
    style: 'long',
    numeric: 'auto',
  });

  let result;
  switch (true) {
    case dateIsToday:
      result = `${rtf.format(0, 'day')}`;
      break;
    case dateIsTomorrow:
      result = `${rtf.format(1, 'day')}`;
      break;
    case dateIsAnotherYear:
      result = tempResult.toFormat('yyyy-MM-dd', { locale });
      break;
    default:
      if (monthFormat === 'long') {
        result = tempResult.toFormat('EEEE dd MMMM', { locale });
      } else {
        result = tempResult.toFormat('EEEE dd MMM', { locale });
      }
  }
  return capitalizeFirstLetter(result);
};

/**
 * Used when in need of the word 'Today' and wanting to make sure it exactly
 * matches how 'Today' is displayed within relative time strings in other areas of the app.
 * ('Today' does not exist as a common translation string)
 *
 * @param locale locale to use for formatting, defaults to current selected language in application
 * @returns the string 'Today' created with Intl relative time formatting API
 */
export const getTodayStringWithLocale = (
  locale: undefined | string = getLocaleFromCurrentLang(),
) => {
  const rtf = new Intl.RelativeTimeFormat(locale, {
    style: 'long',
    numeric: 'auto',
  });
  return capitalizeFirstLetter(`${rtf.format(0, 'day')}`);
};
