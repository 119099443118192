import { useTranslation } from 'react-i18next';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { TStep } from '@caspeco/casper-ui-library.components.stepper';
import { DeliveryStatus } from 'types/order';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { useBaseColorValue } from 'components/base-color-context';
import { formatRelativeTimeAndDayWithLocale } from 'helpers/time-helper/time-helper';

export const ReadyStep = ({
  deliveryStatus,
  readyTime,
  placeTimezone,
  expectedPreparationMinutes,
}: {
  deliveryStatus?: DeliveryStatus;
  readyTime?: string;
  placeTimezone?: string;
  expectedPreparationMinutes?: number;
}): TStep => {
  const { t } = useTranslation();
  const { baseColor } = useBaseColorValue();
  const isReady = deliveryStatus === 'DELIVERED' || deliveryStatus === 'READY';
  const formattedReadyTime =
    readyTime &&
    formatRelativeTimeAndDayWithLocale(readyTime, placeTimezone, false);

  let labelHeadingTextColor: ThemeColorVariable;
  let readyTimeTextColor: ThemeColorVariable;
  let defaultDividerAndStepColor: ThemeColorVariable;

  switch (baseColor) {
    case 'background':
      labelHeadingTextColor = ThemeColorVariable.OnBackgroundHeader;
      readyTimeTextColor = ThemeColorVariable.OnBackgroundSubdued;
      defaultDividerAndStepColor = ThemeColorVariable.OnBackgroundBorder;
      break;
    case 'surface':
    default:
      labelHeadingTextColor = ThemeColorVariable.OnSurfaceHeader;
      readyTimeTextColor = ThemeColorVariable.OnSurfaceSubdued;
      defaultDividerAndStepColor = ThemeColorVariable.OnSurfaceBorder;
      break;
  }

  const expectedPreparationMinutesExceedsZero = Boolean(
    expectedPreparationMinutes && expectedPreparationMinutes > 0,
  );

  const renderReadyTimeStampString = () => {
    if (!readyTime) return null;

    if (isReady && readyTime) {
      // Render ready time as is, the time is when order was marked as ready
      return <Text color={readyTimeTextColor}>{formattedReadyTime}</Text>;
    }

    if (!isReady && expectedPreparationMinutesExceedsZero) {
      // Render ready time as expected
      return (
        <Text color={readyTimeTextColor}>
          {`${t('order_expected_ready')}\xa0${formattedReadyTime}`}
        </Text>
      );
    }

    return null;
  };

  return {
    label: (
      <Flex direction="column" justifyContent="flex-start" textAlign="left">
        <Text fontWeight="medium" color={labelHeadingTextColor}>
          {t('delivery_status_ready')}
        </Text>
        {renderReadyTimeStampString()}
      </Flex>
    ),
    iconColor: ThemeColorVariable.OnSuccess,
    color: isReady ? ThemeColorVariable.Success : defaultDividerAndStepColor,
    dividerColor:
      deliveryStatus === 'DELIVERED'
        ? ThemeColorVariable.Success
        : defaultDividerAndStepColor,
    icon: isReady ? 'check' : undefined,
  };
};
