import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@caspeco/casper-ui-library.components.input';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@caspeco/casper-ui-library.components.formcontrol';
import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { IAppFeedbackRequest } from 'types/app';

interface IFeedbackFormNameField {
  control: Control<IAppFeedbackRequest, string>;
}

export function FeedbackFormNameField({ control }: IFeedbackFormNameField) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: 'name',
    rules: {
      validate: (value) =>
        !value || value.trim().length > 0 || t('form_name_characters_rule'),
      minLength: { value: 1, message: t('form_name_characters_rule') },
      maxLength: { value: 50, message: t('form_name_characters_rule') },
    },
  });

  return (
    <FormControl isReadOnly isInvalid={Boolean(error)}>
      <FormLabel color={ThemeColorVariable.OnSurface}>
        {t('form_name')}
      </FormLabel>
      <Input
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        size="lg"
        type="text"
        autoComplete="name"
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
