import { useTranslation } from 'react-i18next';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
  ThemeFontVariable,
  ThemeColorVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Place } from 'types/place';
import { Link } from '@caspeco/casper-ui-library.components.link';

type TPlaceContactInfo = { headerColor: ThemeColorVariable } & Pick<
  Place,
  'phone' | 'email' | 'corporateId'
>;
function PlaceContactInfo({
  phone,
  email,
  corporateId,
  headerColor,
}: TPlaceContactInfo) {
  const { t } = useTranslation();
  return (
    <Stack spacing={ThemeSpaceVariable.Small}>
      <Heading
        as="h3"
        color={headerColor}
        fontFamily={ThemeFontVariable.SubHeader}
        fontWeight="medium"
        fontSize={ThemeFontSizeVariable.Large}
      >
        {`${t('misc_contact_info')}`}
      </Heading>
      {phone && (
        <Box
          display="flex"
          justifyContent="space-between"
          gap={ThemeSpaceVariable.Small}
        >
          <Text>{`${t('form_phone_number')}`}</Text>
          <Link color="inherit" href={`tel:${phone}`}>
            <Text decoration="underline" textAlign="right">
              {phone}
            </Text>
          </Link>
        </Box>
      )}
      {email && (
        <Box
          display="flex"
          justifyContent="space-between"
          gap={ThemeSpaceVariable.Small}
        >
          <Text whiteSpace="nowrap">{`${t('form_email_address')}`}</Text>
          <Link color="inherit" href={`mailto:${email}`}>
            <Text
              wordBreak="break-all"
              decoration="underline"
              textAlign="right"
            >
              {email}
            </Text>
          </Link>
        </Box>
      )}
      {corporateId && (
        <Box
          display="flex"
          justifyContent="space-between"
          gap={ThemeSpaceVariable.Small}
        >
          <Text>{`${t('misc_corporate_id')}`}</Text>
          <Text textAlign="right">{corporateId}</Text>
        </Box>
      )}
    </Stack>
  );
}

export default PlaceContactInfo;
