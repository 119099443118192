import PhoneInput from 'react-phone-number-input';
import { RefCallBack } from 'react-hook-form';
import { CountryCode } from 'libphonenumber-js/types';
import { StyledPhoneInput } from './CustomPhoneInput.styles';
import 'react-phone-number-input/style.css';

interface IPhoneInput {
  id: string;
  /** Required HTML name attribute for field. */
  name: string;
  /** Label that identifies the input. */
  label: JSX.Element;
  placeholder?: string;
  /** Set to "national" to show the initial value in "national" format rather than "international".
   * By default, initialValueFormat is undefined */
  initialValueFormat?: 'national' | undefined;
  isInvalid?: boolean;
  defaultCountry?: CountryCode;
  value: string | undefined;
  onChange: (
    phoneValue: string | undefined,
    countryValue: CountryCode | undefined,
    fieldName: string,
  ) => void;
  onBlur?: (e: React.FocusEvent) => void;
  refCallBack?: RefCallBack;
  autoComplete?: string;
}

function CustomPhoneInput({
  placeholder,
  initialValueFormat,
  isInvalid,
  defaultCountry,
  name,
  value,
  label,
  onChange,
  onBlur,
  id,
  refCallBack,
  autoComplete,
}: IPhoneInput) {
  const handlePhoneChange = (value: string | undefined) => {
    const countrySelect = document.querySelector(
      'select[name="phoneNumberCountry"]',
    ) as HTMLSelectElement;
    const countryCode = (countrySelect?.value as CountryCode) ?? undefined;
    onChange(value, countryCode, name);
  };

  // To find documentation for Phone input component visit:
  // https://catamphetamine.gitlab.io/react-phone-number-input/docs/index.html#phoneinputwithcountry
  return (
    <StyledPhoneInput>
      {label}
      <PhoneInput
        id={id}
        aria-invalid={isInvalid}
        ref={refCallBack}
        placeholder={placeholder}
        initialValueFormat={initialValueFormat}
        defaultCountry={defaultCountry}
        name={name}
        value={value}
        onChange={handlePhoneChange}
        autoComplete={autoComplete}
        onBlur={onBlur}
      />
    </StyledPhoneInput>
  );
}

export default CustomPhoneInput;
