import { useTranslation } from 'react-i18next';
import React from 'react';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import {
  ThemeSpaceVariable,
  ThemeFontSizeVariable,
  ThemeColorVariable,
  ThemeFontVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Message } from '@caspeco/casper-ui-library.components.message';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { useOrderStore } from 'store/orderStore';
import { useBaseColorValue } from 'components/base-color-context';
import { formatRelativeTimeAndDayWithLocale } from 'helpers/time-helper/time-helper';

export default function OrderMessages() {
  const { order } = useOrderStore();
  const { t } = useTranslation();
  const { baseColor } = useBaseColorValue();

  let labelTextColor;
  switch (baseColor) {
    case 'background':
      labelTextColor = ThemeColorVariable.OnBackgroundHeader;
      break;
    case 'surface':
    default:
      labelTextColor = ThemeColorVariable.OnSurfaceHeader;
      break;
  }

  return (
    <Stack mt={ThemeSpaceVariable.XLarge}>
      <Heading
        as="h3"
        fontSize={ThemeFontSizeVariable.Large}
        fontFamily={ThemeFontVariable.SubHeader}
        fontWeight="medium"
        color={labelTextColor}
        mb={ThemeSpaceVariable.XSmall}
      >
        {t('messages')}
      </Heading>
      <Stack spacing={ThemeSpaceVariable.Small}>
        {order?.messages.map((message) => (
          <React.Fragment key={message.id}>
            <Message
              time={formatRelativeTimeAndDayWithLocale(
                message.createdAt.toString(),
                order.placeTimezone,
              )}
            >
              {message.message}
            </Message>
          </React.Fragment>
        ))}
      </Stack>
    </Stack>
  );
}
