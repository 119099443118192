import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { DeliveryOption } from 'types/deliveryOption';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@caspeco/casper-ui-library.components.formcontrol';
import { Select } from '@caspeco/casper-ui-library.components.select';
import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { IDeliveryFormValues } from './DeliveryForm.types';

interface IDeliveryOptionField {
  fieldName: keyof IDeliveryFormValues;
  options: DeliveryOption[];
  onChange?: (value: string, fieldName: keyof IDeliveryFormValues) => void;
  control: Control;
  /** When true the field will be hidden. Defaults to false. */
  isHidden?: boolean;
}

export function DeliveryOptionField({
  fieldName,
  options = [],
  onChange,
  control,
  isHidden = false,
}: IDeliveryOptionField) {
  const { t } = useTranslation();
  const visibility = isHidden ? 'hidden' : 'visible';
  const visibilityClassName = isHidden ? 'visually-hidden' : 'visible';

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: {
          value: true,
          message: t('misc_select_place'),
        },
      }}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={Boolean(fieldState.error)}
          isRequired
          aria-hidden={isHidden}
          visibility={visibility}
          className={visibilityClassName}
        >
          <FormLabel color={ThemeColorVariable.OnSurface}>
            {t('misc_select_place')}
          </FormLabel>
          <Select
            size="lg"
            name={field.name}
            value={field.value}
            aria-hidden={isHidden ? undefined : false}
            w="100%"
            placeholder={t('form_none_selected')}
            onChange={(e) => {
              if (onChange) onChange(e.target.value, fieldName);
              field.onChange(e);
            }}
          >
            {options.map((x) => (
              <option key={x.id} value={x.id}>
                {x.title}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
