import { AspectRatio } from 'types/ui';
import { Routes, ProtectedRoutes, NonProtectedRoutes } from 'types/routes';
import { AppImages } from 'types/app';
import { ImageMap } from 'types/imageMap';

export const routesWithCoverImageEnabled = [
  ProtectedRoutes.PLACES,
  ProtectedRoutes.PLACE,
  ProtectedRoutes.MENUS,
  NonProtectedRoutes.CONFIRM_EMAIL,
];

export const routesWithCoverImageDisabled = [
  NonProtectedRoutes.NOT_FOUND,
  NonProtectedRoutes.COOKIES,
  NonProtectedRoutes.LINKS,
  NonProtectedRoutes.APP_REDIRECT_INFO,
  ProtectedRoutes.CHECKOUT,
  ProtectedRoutes.ORDER_CONFIRMATION,
  ProtectedRoutes.HISTORY,
];

/**
 * Determines wether the route wrapper should show a app cover image or not.
 *
 * @param route current visited app route
 * @param images image data from app
 * @param placeImages images of current visited place
 */
export const getShowCoverImage = (
  route: Routes,
  images: AppImages | undefined,
  placeImages: ImageMap | undefined,
): boolean => {
  if (!images || !images?.cover) return false;

  if (
    !images.cover.hasImage(AspectRatio.OneByOne) ||
    !images.cover.hasImage(AspectRatio.ThirtyTwoByNine)
  ) {
    // Cover image does not exists with both required ratio values
    return false;
  }

  // Not visiting a route that should show a cover image by design
  if (!routesWithCoverImageEnabled.includes(route)) return false;

  if (
    route === ProtectedRoutes.PLACE &&
    placeImages?.hasImage(AspectRatio.SixteenByNine)
  ) {
    // Place image exists and has higher priority than cover image (they should not both be displayed)
    return false;
  }

  return true;
};

/**
 * Determines what logo image to display in route wrapper header, if any.
 * Returns the logo that should be easiest to read based on it's background.
 *
 * @param isShowingCoverImage if header is currently showing cover image
 * @param images image data from app
 *
 * @returns logo image source string or undefined if no logo should show
 */
export function getLogoImage(
  isShowingCoverImage: boolean,
  images: AppImages | undefined,
): ImageMap | undefined {
  if (!images) return undefined;

  // Cover image and logo (on image variant) exist
  if (isShowingCoverImage && images.logotypeOnImage?.THIRTYTWO_BY_NINE) {
    return images.logotypeOnImage;
  }

  // No cover image exist, but logo (on primary variant) exist
  if (!isShowingCoverImage && images.logotypeOnPrimary?.THIRTYTWO_BY_NINE) {
    return images.logotypeOnPrimary;
  }

  return undefined;
}
