import { createLocalOpeningHoursHelper } from 'helpers/opening-hours-helper/opening-hours-helper';
import { DateTime } from 'luxon';
import { SubArticle, ISubArticleResponse } from './article';
import { IOpeningHours, IOpeningHoursDisplayData } from './openingHours';
import { ImageMapValues, ImageMap } from './imageMap';

export interface ISectionValues {
  id?: string;
  name?: string;
  openingHours?: IOpeningHours[];
  imageMap?: ImageMapValues;
  sortOrder?: number;
  articles?: ISubArticleResponse[];
  canTakeOrders?: boolean;
}

export class Section {
  id: string;
  name: string;
  imageMap: ImageMap;
  sortOrder: number;
  articles: SubArticle[];
  openingHours: IOpeningHours[];
  open: boolean;
  openingStatus: IOpeningHoursDisplayData;
  canTakeOrders: boolean;

  constructor(values: ISectionValues) {
    const now = DateTime.now();
    const { isOpen, getOpeningStatus } = createLocalOpeningHoursHelper(
      values?.openingHours ?? [],
    );
    this.id = values?.id ?? '';
    this.name = values?.name ?? '';
    this.imageMap = new ImageMap(values?.imageMap);
    this.sortOrder = values?.sortOrder ?? -1;
    this.articles =
      values?.articles
        ?.map((a) => new SubArticle(a))
        .sort((a, b) => a.sortOrder - b.sortOrder) ?? <SubArticle[]>[];
    this.openingHours = values?.openingHours ?? [];
    this.open = isOpen(now) ?? false;
    this.openingStatus = getOpeningStatus(now);
    this.canTakeOrders = values?.canTakeOrders ?? true;
  }
}
