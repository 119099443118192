import { createContext, useContext, useMemo, useReducer } from 'react';
import { logError } from 'helpers/log-helper/log-helper';
import { LogPrefix } from 'types/logging';
import { OrderHistory } from 'types/history';
import { HistoryService } from 'services/History.service';

interface IHistoryStore {
  /**
   * List of order history entries that are categorized as ongoing.
   */
  readonly ongoingOrders: OrderHistory[];
  /**
   * List of order history entries that are categorized as processed.
   */
  readonly processedOrders: OrderHistory[];
  /**
   * Is currently fetching history
   */
  readonly isLoadingHistory: boolean;
  /**
   * Has finished fetch request for history,
   * true no matter if the response was an error or success
   */
  readonly hasLoadedHistory: boolean;
}

interface IHistoryAPI {
  /**
   * Fetch history of ongoing orders
   */
  getOngoingOrders: () => Promise<OrderHistory[]>;
  /**
   * Fetch history of processed orders
   */
  getProcessedOrders: () => Promise<OrderHistory[]>;
}

const initialState: IHistoryStore = {
  ongoingOrders: [],
  processedOrders: [],
  isLoadingHistory: false,
  hasLoadedHistory: false,
};

enum ActionType {
  SET_ONGOING_HISTORY,
  SET_PROCESSED_HISTORY,
  START_LOADING_HISTORY,
  STOP_LOADING_HISTORY,
}

type Action =
  | { type: ActionType.SET_ONGOING_HISTORY; payload: OrderHistory[] }
  | { type: ActionType.SET_PROCESSED_HISTORY; payload: OrderHistory[] }
  | { type: ActionType.START_LOADING_HISTORY }
  | { type: ActionType.STOP_LOADING_HISTORY };

const HistoryAPI = createContext<IHistoryAPI>({} as IHistoryAPI);
const HistoryStore = createContext<IHistoryStore>({} as IHistoryStore);

const reducer = (state: IHistoryStore, action: Action): IHistoryStore => {
  switch (action.type) {
    case ActionType.SET_ONGOING_HISTORY:
      return {
        ...state,
        ongoingOrders: action.payload,
        isLoadingHistory: false,
        hasLoadedHistory: true,
      };
    case ActionType.SET_PROCESSED_HISTORY:
      return {
        ...state,
        processedOrders: action.payload,
        isLoadingHistory: false,
        hasLoadedHistory: true,
      };
    case ActionType.START_LOADING_HISTORY:
      return {
        ...state,
        isLoadingHistory: true,
        hasLoadedHistory: false,
      };
    case ActionType.STOP_LOADING_HISTORY:
      return {
        ...state,
        isLoadingHistory: false,
        hasLoadedHistory: true,
      };
    default:
      return state;
  }
};

export function HistoryProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: IHistoryStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: IHistoryAPI = useMemo(() => {
    const getOngoingOrders = async () => {
      try {
        dispatch({ type: ActionType.START_LOADING_HISTORY });
        const { orders } = await HistoryService.getOngoingOrders();
        dispatch({
          type: ActionType.SET_ONGOING_HISTORY,
          payload: orders,
        });
        return orders;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_HISTORY });
        logError(LogPrefix.History, error, 'Could not get Ongoing Orders');
        throw error;
      }
    };

    const getProcessedOrders = async () => {
      try {
        dispatch({ type: ActionType.START_LOADING_HISTORY });
        const { orders } = await HistoryService.getProcessedOrders();
        dispatch({
          type: ActionType.SET_PROCESSED_HISTORY,
          payload: orders,
        });
        return orders;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_HISTORY });
        logError(LogPrefix.History, error, 'Could not get Processed Orders');
        throw error;
      }
    };
    return { getOngoingOrders, getProcessedOrders };
  }, []);

  return (
    <HistoryAPI.Provider value={api}>
      <HistoryStore.Provider value={state}>{children}</HistoryStore.Provider>
    </HistoryAPI.Provider>
  );
}

export const useHistoryStore = (): IHistoryStore => useContext(HistoryStore);
export const useHistoryAPI = (): IHistoryAPI => useContext(HistoryAPI);
