import { createContext, useContext, useMemo, useReducer } from 'react';
import { logError } from 'helpers/log-helper/log-helper';
import { MenuService } from 'services/Menu.service';
import {
  deleteSessionStorage,
  getSessionStorage,
  setSessionStorage,
} from 'helpers/session-storage-helper/session-storage-helper';
import { Menu } from 'types/menu';
import { LogPrefix } from 'types/logging';
import { Section } from 'types/section';

interface IMenuStore {
  readonly menu: Menu | undefined;
  /**
   * Is currently fetching menu
   */
  readonly isLoadingMenu: boolean;
  /**
   * Has finished fetch request for menu,
   * true no matter if the response was an error or success
   */
  readonly hasLoadedMenu: boolean;
  /**
   * Current selected menu section
   */
  readonly selectedSection: Section | undefined;
}

interface IMenuAPI {
  /**
   * Fetch menu with given id
   */
  getMenu: (menuId: string) => Promise<Menu>;
  /**
   * Set current selected menu section
   */
  setSelectedSection: (section: Section | undefined) => void;
}

const initialState: IMenuStore = {
  menu: undefined,
  isLoadingMenu: false,
  hasLoadedMenu: false,
  selectedSection: undefined,
};

enum ActionType {
  SET_MENU,
  SET_SELECTED_SECTION,
  START_LOADING_MENU,
  STOP_LOADING_MENU,
}

type Action =
  | { type: ActionType.SET_MENU; payload: Menu | undefined }
  | { type: ActionType.SET_SELECTED_SECTION; payload: Section | undefined }
  | { type: ActionType.START_LOADING_MENU }
  | { type: ActionType.STOP_LOADING_MENU };

const MenuAPI = createContext<IMenuAPI>({} as IMenuAPI);
const MenuStore = createContext<IMenuStore>({} as IMenuStore);

const reducer = (state: IMenuStore, action: Action): IMenuStore => {
  switch (action.type) {
    case ActionType.SET_MENU:
      return {
        ...state,
        menu: action.payload,
        isLoadingMenu: false,
        hasLoadedMenu: true,
      };
    case ActionType.SET_SELECTED_SECTION:
      return {
        ...state,
        selectedSection: action.payload,
      };
    case ActionType.START_LOADING_MENU:
      return {
        ...state,
        isLoadingMenu: true,
        hasLoadedMenu: false,
      };
    case ActionType.STOP_LOADING_MENU:
      return {
        ...state,
        isLoadingMenu: false,
        hasLoadedMenu: true,
      };
    default:
      return state;
  }
};

export function MenuProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: IMenuStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: IMenuAPI = useMemo(() => {
    const getMenu = async (menuId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_MENU });
        const menu = await MenuService.getMenu(menuId);
        const section: Section | undefined =
          menu.sections.find(
            // Check if any stored section id should be initally selected
            (s) => s?.id === getSessionStorage<string>('selectedSectionId'),
            // Fallback to the first section
          ) ?? menu.sections[0];

        if (section) {
          // Additionally store section to keep selection on page reload
          setSessionStorage('selectedSectionId', section?.id);
          dispatch({ type: ActionType.SET_SELECTED_SECTION, payload: section });
        }

        dispatch({ type: ActionType.SET_MENU, payload: menu });
        return menu;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_MENU });
        logError(LogPrefix.Menu, error, 'Could not get Menu');
        throw error;
      }
    };

    const setSelectedSection = (section: Section | undefined) => {
      dispatch({ type: ActionType.SET_SELECTED_SECTION, payload: section });

      // Handle references in Session storage as well
      if (section === undefined) {
        deleteSessionStorage('selectedSectionId');
      } else {
        // Additionally store section to keep selection on page reload
        setSessionStorage('selectedSectionId', section.id);
      }
    };
    return { getMenu, setSelectedSection };
  }, []);

  return (
    <MenuAPI.Provider value={api}>
      <MenuStore.Provider value={state}>{children}</MenuStore.Provider>
    </MenuAPI.Provider>
  );
}

export const useMenuStore = (): IMenuStore => useContext(MenuStore);
export const useMenuAPI = (): IMenuAPI => useContext(MenuAPI);
