import { Box } from '@caspeco/casper-ui-library.components.box';

import {
  ThemeColorVariable,
  ThemeRadiusVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';

function SkeletonDeliveryIcon() {
  return (
    <Box display="flex" flexDirection="column" alignContent="center">
      <Box
        w="60px"
        h="60px"
        borderRadius={ThemeRadiusVariable.XLarge}
        bg={ThemeColorVariable.OnSurfaceSubdued}
      />
      <Box
        mt={ThemeSpaceVariable.Small}
        mb={ThemeSpaceVariable.Large}
        h={ThemeSpaceVariable.Medium}
        w="48px"
        bg={ThemeColorVariable.OnSurfaceSubdued}
      />
    </Box>
  );
}
export function SkeletonDeliveryOptions() {
  return (
    <span data-testid="loading-indicator">
      <Box
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        display="flex"
        px={ThemeSpaceVariable.X2Large}
        py={ThemeSpaceVariable.Medium}
      >
        <Box
          mt={ThemeSpaceVariable.Small}
          mb={ThemeSpaceVariable.Large}
          h={ThemeSpaceVariable.Medium}
          w="180px"
          bg={ThemeColorVariable.OnSurfaceSubdued}
        />
        <Box
          flexDirection="row"
          justifyContent="center"
          alignContent="center"
          display="flex"
          gap="20px"
        >
          <SkeletonDeliveryIcon />
          <SkeletonDeliveryIcon />
        </Box>
        <Box
          borderRadius={ThemeRadiusVariable.XLarge}
          h="48px"
          w="100%"
          bg={ThemeColorVariable.OnSurfaceSubdued}
        />
      </Box>
    </span>
  );
}
