import { sortPlaceByName } from 'helpers/places-helper/places-helper';
import {
  IPlaceDetailResponse,
  IPlaceListingResponse,
  IPlaceResponse,
  Place,
  PlaceDetails,
} from 'types/place';
import { ApiService } from './Api.service';

// API layer
export class PlaceAPIService {
  static async getPlace(placeId: string): Promise<IPlaceResponse> {
    return ApiService.fetch(`places/${placeId}`);
  }

  static async getPlaces(): Promise<IPlaceListingResponse> {
    return ApiService.fetch(`places`);
  }
}

// DTO layer
export class PlaceService {
  static async getPlaces(): Promise<Place[]> {
    return PlaceAPIService.getPlaces().then((data: IPlaceListingResponse) =>
      data.places
        ?.map((p: IPlaceResponse) => new Place(p))
        .sort(sortPlaceByName),
    );
  }

  static async getPlace(placeId: string): Promise<PlaceDetails> {
    return PlaceAPIService.getPlace(placeId).then(
      (data: IPlaceDetailResponse) => new PlaceDetails(data),
    );
  }
}
