import { createContext, useContext, useMemo, useReducer } from 'react';
import { logError } from 'helpers/log-helper/log-helper';
import { DeliveryOptionsService } from 'services/DeliveryOptions.service';
import { BSTLApiError } from 'types/api-error';
import { DeliveryOption } from 'types/deliveryOption';
import { LogPrefix } from 'types/logging';

interface IDeliveryOptionsStore {
  /**
   * Root of delivery option tree (can have delivery option tree nodes)
   *
   * @important Is implicitly a tree of nodes with delivery type EAT_HERE,
   * as this is the only type that (for now) has a root and tree structure.
   * It is (again, for now) safe to assume that all nodes within this root is of delivery type EAT_HERE.
   * In the future we might have root delivery options for other delivery types.
   */
  readonly rootDeliveryOption: DeliveryOption | undefined;
  /**
   * Is currently fetching rootDeliveryOption
   */
  readonly isLoadingRootDeliveryOption: boolean;
  /**
   * Has finished fetch request for rootDeliveryOption,
   * true no matter if the response was an error or success
   */
  readonly hasLoadedRootDeliveryOption: boolean;
}

interface IDeliveryOptionsAPI {
  /**
   * Fetch root delivery option
   */
  getRootDeliveryOption: (
    deliveryOptionId: string,
  ) => Promise<DeliveryOption | undefined>;
}

const initialState: IDeliveryOptionsStore = {
  rootDeliveryOption: undefined,
  isLoadingRootDeliveryOption: false,
  hasLoadedRootDeliveryOption: false,
};

enum ActionType {
  SET_ROOT_DELIVERY_OPTION,
  START_LOADING_ROOT_DELIVERY_OPTION,
  STOP_LOADING_ROOT_DELIVERY_OPTION,
}

type Action =
  | {
      type: ActionType.SET_ROOT_DELIVERY_OPTION;
      payload: DeliveryOption | undefined;
    }
  | { type: ActionType.START_LOADING_ROOT_DELIVERY_OPTION }
  | { type: ActionType.STOP_LOADING_ROOT_DELIVERY_OPTION };

const DeliveryOptionsAPI = createContext<IDeliveryOptionsAPI>(
  {} as IDeliveryOptionsAPI,
);
const DeliveryOptionsStore = createContext<IDeliveryOptionsStore>(
  {} as IDeliveryOptionsStore,
);

const reducer = (
  state: IDeliveryOptionsStore,
  action: Action,
): IDeliveryOptionsStore => {
  switch (action.type) {
    case ActionType.SET_ROOT_DELIVERY_OPTION:
      return {
        rootDeliveryOption: action.payload,
        isLoadingRootDeliveryOption: false,
        hasLoadedRootDeliveryOption: true,
      };
    case ActionType.START_LOADING_ROOT_DELIVERY_OPTION:
      return {
        ...state,
        isLoadingRootDeliveryOption: true,
        hasLoadedRootDeliveryOption: false,
      };
    case ActionType.STOP_LOADING_ROOT_DELIVERY_OPTION:
      return {
        ...state,
        isLoadingRootDeliveryOption: false,
        hasLoadedRootDeliveryOption: true,
      };
    default:
      return state;
  }
};

export function DeliveryOptionsProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: IDeliveryOptionsStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: IDeliveryOptionsAPI = useMemo(() => {
    const getRootDeliveryOption = async (rootId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_ROOT_DELIVERY_OPTION });
        const rootDeliveryOption =
          await DeliveryOptionsService.getDeliveryOption(rootId);
        dispatch({
          type: ActionType.SET_ROOT_DELIVERY_OPTION,
          payload: rootDeliveryOption,
        });

        return rootDeliveryOption;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_ROOT_DELIVERY_OPTION });

        // Currently 404 is expected and ignored if root delivery option is hidden (QR-only for all nodes)
        if (error instanceof BSTLApiError && error.statusCode === 404) {
          return undefined;
        }

        logError(LogPrefix.Place, error, 'Could not get root delivery option');
        throw error;
      }
    };

    return { getRootDeliveryOption };
  }, []);

  return (
    <DeliveryOptionsAPI.Provider value={api}>
      <DeliveryOptionsStore.Provider value={state}>
        {children}
      </DeliveryOptionsStore.Provider>
    </DeliveryOptionsAPI.Provider>
  );
}

export const useDeliveryOptionsStore = (): IDeliveryOptionsStore =>
  useContext(DeliveryOptionsStore);
export const useDeliveryOptionsAPI = (): IDeliveryOptionsAPI =>
  useContext(DeliveryOptionsAPI);
