import { Link, useParams } from 'react-router-dom';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { getOpeningHoursLabel } from 'helpers/opening-hours-helper/opening-hours-helper';
import { Place } from 'types/place';
import {
  ThemeBorderVariable,
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import PlaceAvailableStatus from 'components/place-available-status';
import { getDistanceString } from 'routes/places-route/helpers/PlacesPage.helpers';

interface IPlaceCard {
  place: Place;
}

function PlaceCard({ place }: IPlaceCard) {
  const { appAlias } = useParams<'appAlias'>();
  const { name, address, open, openingStatus, preOrderEnabled, location } =
    place;

  const distance = location.distanceInKm
    ? getDistanceString(location.distanceInKm)
    : null;

  return (
    <Box
      className="link-card"
      borderBottom={ThemeBorderVariable.XSmall}
      borderColor={ThemeColorVariable.OnBackgroundBorder}
      py={ThemeSpaceVariable.Medium}
    >
      <Stack spacing={ThemeSpaceVariable.XSmall}>
        <Heading
          as="h2"
          color={ThemeColorVariable.OnBackgroundHeader}
          fontSize={{
            base: ThemeFontSizeVariable.Large,
            md: ThemeFontSizeVariable.XLarge,
          }}
          textAlign="left"
          fontWeight="medium"
        >
          <Link to={`/${appAlias}/${place.id}`}>{name}</Link>
        </Heading>
        <Box flexDirection="row" justifyContent="space-between" display="flex">
          <Text
            noOfLines={2}
            textAlign="left"
            color={ThemeColorVariable.OnBackground}
            fontSize={ThemeFontSizeVariable.Medium}
          >
            {`${address.streetAddress}, ${address.city}`}
          </Text>
          {distance !== null && (
            <Text
              align="right"
              color={ThemeColorVariable.OnBackground}
              fontSize={ThemeFontSizeVariable.Medium}
            >
              {distance}
            </Text>
          )}
        </Box>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <Box display="flex" flexDirection="row" alignContent="center">
            <PlaceAvailableStatus
              open={open}
              preOrderEnabled={preOrderEnabled}
            />
          </Box>
          <Text
            fontSize={ThemeFontSizeVariable.Medium}
            color={ThemeColorVariable.OnBackground}
            align="right"
          >
            {getOpeningHoursLabel(openingStatus)}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
}

export default PlaceCard;
