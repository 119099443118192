import { useTranslation } from 'react-i18next';

import { IconButton } from '@caspeco/casper-ui-library.components.icon-button';
import { Icons } from '@caspeco/casper-ui-library.components.icon';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Flex } from '@caspeco/casper-ui-library.components.flex';

interface ISideNavigationHeader {
  onClose: () => void;
}
export function SideNavigationHeader({ onClose }: ISideNavigationHeader) {
  const { t } = useTranslation();
  return (
    <Flex
      align="center"
      w="100%"
      bg={ThemeColorVariable.Background}
      px={ThemeSpaceVariable.Large}
      py={ThemeSpaceVariable.Small}
      mb={ThemeSpaceVariable.Medium}
    >
      <Flex py={ThemeSpaceVariable.Medium}>
        <IconButton
          aria-label={t('action_close')}
          icon={Icons.Close}
          onClick={onClose}
          size={{ base: 'md', md: 'lg' }}
        />
      </Flex>
    </Flex>
  );
}
