import { DeliveryOption, DeliveryType } from 'types/deliveryOption';
import i18n from 'config/i18n';
import { Icons } from '@caspeco/casper-ui-library.components.icon';

export function getIconForDeliveryType(
  deliveryOptionType?: DeliveryType,
): Icons {
  switch (deliveryOptionType) {
    case 'EAT_AT_RESTAURANT':
      return Icons.EatIn;
    case 'DELIVERY_TO_ADDRESS':
      return Icons.Delivery;
    case 'PICKUP_AT_RESTAURANT':
      return Icons.TakeAway;
    default:
      return Icons.Question;
  }
}

export const isDeliveryTypeThatCanHaveNodes = (type: DeliveryType): boolean =>
  type === 'EAT_AT_RESTAURANT';

/**
 * @returns Flattened array of all delivery options within root delivery option.
 */
export const flattenDeliveryOptions = (
  rootDeliveryOption: DeliveryOption | undefined,
): DeliveryOption[] => {
  if (!rootDeliveryOption) return [];

  const firstLevelOpts: DeliveryOption[] = rootDeliveryOption.deliveryOptions;
  const secondLevelOpts: DeliveryOption[] = firstLevelOpts.flatMap(
    (d) => d.deliveryOptions,
  );
  const thirdLevelOpts: DeliveryOption[] = secondLevelOpts.flatMap(
    (d) => d.deliveryOptions,
  );
  // Flatten all levels into one array without context
  return firstLevelOpts.concat([secondLevelOpts, thirdLevelOpts].flat(1));
};

export const getDeliveryOptionLevels = (
  rootDeliveryOption: DeliveryOption | undefined,
): {
  levelOne: DeliveryOption[];
  levelTwo: DeliveryOption[];
  levelThree: DeliveryOption[];
} => {
  const levelOne: DeliveryOption[] = rootDeliveryOption?.deliveryOptions ?? [];
  const levelTwo: DeliveryOption[] = levelOne.flatMap((d) => d.deliveryOptions);
  const levelThree: DeliveryOption[] = levelTwo.flatMap(
    (d) => d.deliveryOptions,
  );

  return {
    levelOne,
    levelTwo,
    levelThree,
  };
};

interface IDeliveryOptionSelection {
  type: DeliveryType | undefined;
  levelOne: DeliveryOption | undefined;
  levelTwo: DeliveryOption | undefined;
  levelThree: DeliveryOption | undefined;
}

/**
 * Identifies each level of delivery option selection (hierarchy structure of three levels)
 * based on the current selected delivery option id.
 *
 * @param rootDeliveryOption All delivery options found on menu, see other references to rootDeliveryOption.
 * @param currentSelectedOptionId Id for current selected delivery option, no need
 * to know what hierarchy level the selection belongs two.
 */
export const getFullDeliveryOptionSelection = (
  rootDeliveryOption: DeliveryOption | undefined,
  deliveryTypes?: DeliveryType[],
  currentSelectedOptionId?: string | null,
  currentSelectedType?: DeliveryType,
): IDeliveryOptionSelection => {
  const newSelection: IDeliveryOptionSelection = {
    type: currentSelectedType,
    levelOne: undefined,
    levelTwo: undefined,
    levelThree: undefined,
  };

  // Only one type option available, and no previous selection exists - select the one available
  if (!currentSelectedType && deliveryTypes?.length === 1) {
    newSelection.type = deliveryTypes[0];
  }

  if (
    !rootDeliveryOption || // No root exists
    !newSelection.type || // No delivery type could be determined above
    !isDeliveryTypeThatCanHaveNodes(newSelection.type)
  ) {
    // Don't bother with the rest of the logic if we don't have a root or a delivery type that can have nodes
    return newSelection;
  }

  // Find our selected option among all delivery options
  const selectedOption = flattenDeliveryOptions(rootDeliveryOption).find(
    (d) => d.id === currentSelectedOptionId,
  );

  // Split our root delivery options into three levels, each level indicating their position in the root
  const firstLevelOpts: DeliveryOption[] = rootDeliveryOption.deliveryOptions;
  const secondLevelOpts: DeliveryOption[] = firstLevelOpts.flatMap(
    (d) => d.deliveryOptions,
  );
  const thirdLevelOpts: DeliveryOption[] = secondLevelOpts.flatMap(
    (d) => d.deliveryOptions,
  );

  if (!selectedOption && firstLevelOpts.length === 1) {
    // No selected option, and the first level option is a lone sibling
    // Assume we can select it by default
    newSelection.levelOne = firstLevelOpts[0];
  }

  if (selectedOption && firstLevelOpts.includes(selectedOption)) {
    // Our selected option was found on the FIRST level, others remain undefined
    newSelection.levelOne = selectedOption;
    return newSelection;
  }

  if (selectedOption && secondLevelOpts.includes(selectedOption)) {
    // Our selected option was found on the SECOND level
    newSelection.levelTwo = selectedOption;
    // Find the level one parent to our selected option
    newSelection.levelOne = firstLevelOpts.find((d) =>
      d.deliveryOptions.includes(selectedOption),
    );
  }

  if (selectedOption && thirdLevelOpts.includes(selectedOption)) {
    // Our selected option was found on the THIRD and final level
    newSelection.levelThree = selectedOption;

    // Find the level two parent to our selected option
    newSelection.levelTwo = secondLevelOpts.find((d) =>
      d.deliveryOptions.includes(selectedOption),
    );
    // Find the level one parent to our level two option
    newSelection.levelOne = firstLevelOpts.find((d) =>
      d.deliveryOptions.includes(
        newSelection.levelTwo as unknown as DeliveryOption,
      ),
    );
  }

  return newSelection;
};

/** Translated string for delivery type title. */
export const getDeliveryTypeTitle = (
  deliveryType?: DeliveryType | null,
): string => {
  switch (deliveryType) {
    case 'EAT_AT_RESTAURANT':
      return i18n.t('delivery_option_type_eat_here');
    case 'PICKUP_AT_RESTAURANT':
      return i18n.t('delivery_option_type_takeaway');
    case 'DELIVERY_TO_ADDRESS':
      return i18n.t('delivery_option_type_delivery');
    default:
      return '';
  }
};

/**
 * Create a string to be presented in the ui with the name
 * of delivery type and delivery option (if any).
 * @param deliveryType Delivery type, required
 * @param deliveryOption Either a delivery option title or a string (name of delivery option)
 */
export const getDeliveryOptionBreadCrumb = (
  deliveryType: DeliveryType,
  deliveryOption?: DeliveryOption | string,
): string => {
  const deliveryTypeName = getDeliveryTypeTitle(deliveryType);
  let deliveryOptionName;

  if (deliveryOption && typeof deliveryOption === 'string') {
    deliveryOptionName = deliveryOption;
  } else if (deliveryOption && typeof deliveryOption !== 'string') {
    deliveryOptionName = deliveryOption?.title;
  }

  switch (true) {
    case Boolean(deliveryType) && Boolean(deliveryOption):
      return `${deliveryTypeName}, ${deliveryOptionName}`;
    case Boolean(deliveryType):
      return deliveryTypeName;
    default:
      return '';
  }
};

export const hasNodes = (deliveryOption?: DeliveryOption): boolean =>
  Boolean(deliveryOption?.deliveryOptions.length);

/**
 * Try to find any delivery node that can be "selected automatically",
 * meaning it is the only available option in it's tree of nodes.
 * Only applicable if the delivery type is one that supports delivery nodes.
 * This is currently only 'EAT_AT_RESTAURANT'.
 *
 * @param rootDeliveryOption Root delivery option for delivery type. Holds nodes.
 * @param selectedType Delivery type selected by user.
 */
export const getAutoSelectableDeliveryNode = (
  rootDeliveryOption: DeliveryOption | undefined,
  selectedType: DeliveryType,
): DeliveryOption | undefined => {
  if (!rootDeliveryOption) return undefined;
  let node: DeliveryOption | undefined;
  // Idenfity all three possible levels of delivery nodes within root
  const { levelOne, levelTwo, levelThree } =
    getDeliveryOptionLevels(rootDeliveryOption);

  const firstLevelIsOutmostNode: boolean =
    levelOne.length === 1 && levelTwo.length === 0;
  const secondLevelIsOutmostNode: boolean =
    levelOne.length === 1 && levelTwo.length === 1 && levelThree.length === 0;
  const thirdLevelIsOutmostNode: boolean =
    levelOne.length === 1 && levelTwo.length === 1 && levelThree.length === 1;

  if (firstLevelIsOutmostNode) {
    node = levelOne[0];
  } else if (secondLevelIsOutmostNode) {
    node = levelTwo[0];
  } else if (thirdLevelIsOutmostNode) {
    node = levelThree[0];
  } else if (!isDeliveryTypeThatCanHaveNodes(selectedType)) {
    node = undefined;
  }

  return node;
};

/**
 * Search for a delivery option (child node) within a root delivery option (parent tree).
 * @param rootDeliveryOption root delivery option with (potential) tree structure
 * @param nodeId id of the node to identify within the root tree
 */
export const findDeliveryNodeInRoot = (
  rootDeliveryOption: DeliveryOption,
  nodeId: string,
): DeliveryOption | undefined =>
  flattenDeliveryOptions(rootDeliveryOption).find((node) => node.id === nodeId);

export function isValidDeliveryTypeString(deliveryTypeString: string): boolean {
  if (
    deliveryTypeString === 'EAT_AT_RESTAURANT' ||
    deliveryTypeString === 'PICKUP_AT_RESTAURANT' ||
    deliveryTypeString === 'DELIVERY_TO_ADDRESS'
  ) {
    return true;
  }
  return false;
}
