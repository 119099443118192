import { useMemo } from 'react';
import { useMenuStore } from 'store/menuStore';
import { usePickSearchParams } from 'hooks/usePickSearchParams';
import { isValidDeliveryTypeString } from 'helpers/deliveryoptions-helper/deliveryoptions-helper';

export function useShowChangeDelivery(): boolean {
  const { menu } = useMenuStore();
  const { deliveryOptionType } = usePickSearchParams(['deliveryOptionType']);

  const show = useMemo(
    () =>
      Boolean(
        menu &&
          deliveryOptionType &&
          isValidDeliveryTypeString(deliveryOptionType),
      ),
    [deliveryOptionType, menu],
  );

  return show;
}
