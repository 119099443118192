import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
  ThemeFontVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { DeliveryStatus } from 'types/order';

export default function OrderNumber({
  orderNumber,
  deliveryStatus,
}: {
  orderNumber?: number;
  deliveryStatus?: DeliveryStatus;
}) {
  return (
    <Box
      mt={[ThemeSpaceVariable.Medium, ThemeSpaceVariable.Large]}
      px={ThemeSpaceVariable.Medium}
      w="fit-content"
      borderRadius={ThemeSpaceVariable.Small}
      bg={
        deliveryStatus === 'DELIVERED' || deliveryStatus === 'READY'
          ? ThemeColorVariable.Secondary
          : ThemeColorVariable.OnBackground
      }
    >
      <Text
        letterSpacing="0.24em"
        data-testid="order-number"
        fontWeight="bold"
        textAlign="center"
        fontFamily={ThemeFontVariable.Header}
        color={
          deliveryStatus === 'DELIVERED' || deliveryStatus === 'READY'
            ? ThemeColorVariable.OnSecondary
            : ThemeColorVariable.Background
        }
        fontSize={{
          base: ThemeFontSizeVariable.X4Large,
          md: ThemeFontSizeVariable.X5Large,
        }}
      >
        {orderNumber}
      </Text>
    </Box>
  );
}
