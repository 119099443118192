import { IOrderHistoryListResponse, OrderHistoryList } from 'types/history';
import { ApiService } from './Api.service';

export class HistoryAPIService {
  static async getOngoingOrders(): Promise<IOrderHistoryListResponse> {
    return ApiService.fetch('orders/ongoing');
  }

  static async getProcessedOrders(): Promise<IOrderHistoryListResponse> {
    return ApiService.fetch('orders/processed');
  }
}

export class HistoryService {
  static async getOngoingOrders(): Promise<OrderHistoryList> {
    return HistoryAPIService.getOngoingOrders().then(
      (data) => new OrderHistoryList(data),
    );
  }

  static async getProcessedOrders(): Promise<OrderHistoryList> {
    return HistoryAPIService.getProcessedOrders().then(
      (data) => new OrderHistoryList(data),
    );
  }
}
