import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@caspeco/casper-ui-library.components.formcontrol';
import { Input } from '@caspeco/casper-ui-library.components.input';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { useAuthAPI, useAuthStore } from 'store/authStore';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Icons } from '@caspeco/casper-ui-library.components.icon';
import { EMAIL_REGEX_PATTERN } from 'helpers/validation-helper/validation-helper';

interface ISignInWithEmailForm {
  email: string;
}

export function SignInWithEmailForm({ onGoBack }: { onGoBack: () => void }) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { showToast } = useToast();
  const { placeId, menuId, appAlias } = useParams<
    'placeId' | 'menuId' | 'appAlias'
  >();
  const { sendSignInLink } = useAuthAPI();
  const { isSignInModalManuallyOpened } = useAuthStore();
  const [showSuccessfullySentEmail, setShowSuccessfullySentEmail] =
    useState(false);

  // Create form
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<ISignInWithEmailForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  // Define email field
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: 'email',
    defaultValue: '',
    rules: {
      pattern: {
        value: EMAIL_REGEX_PATTERN,
        message: t('error_format_enter_a_valid_email'),
      },
      required: {
        value: true,
        message: t('form_enter_email'),
      },
    },
  });

  const onSubmit = async (formData: ISignInWithEmailForm) => {
    try {
      if (!isSignInModalManuallyOpened) {
        // The only time the sign in modal is NOT manually opened is when the user is
        // trying to go to checkout. Create a checkout url to use in our sign in email-link,
        // that will redirect them to checkout after sign in
        const checkoutUrl = `${window.location.origin}/${appAlias}/${placeId}/${menuId}/checkout${search}`;
        await sendSignInLink(formData.email, checkoutUrl);
      } else {
        // Don't provide any url, sign in will redirect to current location
        await sendSignInLink(formData.email);
      }
      setShowSuccessfullySentEmail(true);
    } catch {
      showToast({
        type: 'error',
        description: t('error_generic'),
      });
    }
  };

  const hideSuccessfulltSentEmail = () => {
    reset();
    setShowSuccessfullySentEmail(false);
  };

  if (showSuccessfullySentEmail) {
    return (
      <>
        <ModalHeader p={ThemeSpaceVariable.Medium}>
          <Flex align="center" justify="space-between">
            <Button
              variant="ghost"
              onClick={hideSuccessfulltSentEmail}
              leftIcon={Icons.Back}
            >
              {t('account_resend_sign_in_link')}
            </Button>
            <ModalCloseButton />
          </Flex>
        </ModalHeader>
        <ModalBody
          minH="330px"
          px={[ThemeSpaceVariable.Large, ThemeSpaceVariable.XLarge]}
          pb={ThemeSpaceVariable.Large}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Heading
            as="h2"
            textAlign="center"
            fontSize={ThemeFontSizeVariable.XLarge}
            color={ThemeColorVariable.OnSurfaceHeader}
            fontWeight="medium"
            mb={ThemeSpaceVariable.Large}
          >
            {t('account_sign_in_email_link_sent_to_x', { 0: field.value })}
          </Heading>
          <Text textAlign="center">
            {t('account_finish_registration_instructions')}
          </Text>
        </ModalBody>
      </>
    );
  }

  return (
    <>
      <ModalHeader p={ThemeSpaceVariable.Medium}>
        <Flex align="center" justify="space-between">
          <Button variant="ghost" onClick={onGoBack} leftIcon={Icons.Back}>
            {t('account_change_sign_in_method')}
          </Button>
          <ModalCloseButton />
        </Flex>
      </ModalHeader>
      <ModalBody
        px={[ThemeSpaceVariable.Large, ThemeSpaceVariable.XLarge]}
        py={ThemeSpaceVariable.Large}
      >
        <Heading
          textAlign="center"
          as="h2"
          color={ThemeColorVariable.OnSurfaceHeader}
          mb={ThemeSpaceVariable.Medium}
        >
          {t('account_sign_in')}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormControl
            isRequired
            isInvalid={Boolean(error)}
            mt={ThemeSpaceVariable.Large}
          >
            <FormLabel color={ThemeColorVariable.OnSurfaceHeader}>
              {t('form_email_address')}
            </FormLabel>
            <Input
              type="email"
              autoFocus
              onChange={field.onChange}
              value={field.value}
              size="lg"
            />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
            <FormHelperText>
              {t('account_sign_in_email_description')}
            </FormHelperText>
          </FormControl>
          <Button
            size="lg"
            my={ThemeSpaceVariable.Large}
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            w="100%"
          >
            <span>{t('account_sign_in_send_link')}</span>
          </Button>
        </form>
      </ModalBody>
    </>
  );
}
