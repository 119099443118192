import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@caspeco/casper-ui-library.components.modal';
import { useBSTLUserAPI, useBSTLUserStore } from 'store/bstlUserStore';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { getLocaleFromCurrentLang } from 'helpers/locale-helper/locale-helper';
import { IAccountFormFields } from './subcomponents/AccountForm.types';
import { AccountDelete } from './subcomponents/AccountDelete';
import { AccountForm } from './subcomponents/AccountForm';

export function AccountModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [isConfirmingAccountDelete, setIsConfirmingAccountDelete] =
    useState(false);
  const { showToast } = useToast();
  const { bstlUser } = useBSTLUserStore();
  const { updateUser } = useBSTLUserAPI();
  const { t } = useTranslation();

  // Add state cleanup on open/close
  useEffect(
    () => () => {
      setIsConfirmingAccountDelete(false);
    },
    [isOpen],
  );

  const onSubmit = async (formData: IAccountFormFields) => {
    try {
      if (!bstlUser) {
        throw new Error('No user found when attempting to update user');
      }
      await updateUser({
        name: formData.name,
        phoneNumber: formData.phoneNumber,
        locale: bstlUser.locale ?? getLocaleFromCurrentLang(),
        emailAddress: bstlUser.emailAddress, // Cannot be changed
      });
      onClose();
    } catch {
      showToast({
        type: 'error',
        description: t('error_generic'),
      });
    }
  };

  const onClickDeleteAccount = () => {
    setIsConfirmingAccountDelete(true);
  };

  const resetIsConfirmingAccountDelete = () => {
    setIsConfirmingAccountDelete(false);
  };

  const defaultValues: IAccountFormFields = {
    email: bstlUser?.emailAddress ?? '',
    name: bstlUser?.name ?? undefined,
    phoneNumber: bstlUser?.phoneNumber ?? undefined,
  };

  let modalContent;
  if (isConfirmingAccountDelete) {
    modalContent = (
      <AccountDelete
        onGoBack={resetIsConfirmingAccountDelete}
        onClose={onClose}
      />
    );
  } else {
    modalContent = (
      <AccountForm
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        onClickDeleteAccount={onClickDeleteAccount}
      />
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      {modalContent}
    </Modal>
  );
}
