import { DeliveryOption, IDeliveryOptionResponse } from 'types/deliveryOption';
import { ApiService } from './Api.service';

// API layer
export class DeliveryOptionsAPIService {
  static async getDeliveryOption(id: string): Promise<IDeliveryOptionResponse> {
    return ApiService.fetch(`delivery-options/${id}`, {}, false, 'v2');
  }
}

// Dto layer
export class DeliveryOptionsService {
  static async getDeliveryOption(id: string): Promise<DeliveryOption> {
    return DeliveryOptionsAPIService.getDeliveryOption(id).then(
      (data: IDeliveryOptionResponse) => new DeliveryOption(data),
    );
  }
}
