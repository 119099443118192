import { IOfferListingResponse, IOfferResponse, Offer } from 'types/offer';
import { AbortAction, ApiService } from './Api.service';

// API layer
export class OffersAPIService {
  static async getOffers(cartId: string): Promise<IOfferListingResponse> {
    return ApiService.fetch(`offers?cartId=${cartId}`);
  }

  static async getOffer(
    cartId: string,
    offerId: string,
  ): Promise<IOfferResponse> {
    return ApiService.fetch(
      `offers/${offerId}?cartId=${cartId}`,
      undefined,
      undefined,
      'v1',
      AbortAction.OFFER_GET,
    );
  }
}

// DTO layer
export class OffersService {
  static async getOffers(cartId: string): Promise<Offer[]> {
    return OffersAPIService.getOffers(cartId).then((data) =>
      data.offers
        ?.map((offerResponse: IOfferResponse) => new Offer(offerResponse))
        .sort((a, b) => a.sortOrder - b.sortOrder),
    );
  }

  static async getOffer(cartId: string, offerId: string): Promise<Offer> {
    return OffersAPIService.getOffer(cartId, offerId).then(
      (offerResponse) => new Offer(offerResponse),
    );
  }
}
