import styled from 'styled-components';
import { AspectRatio } from 'types/ui';

type IAspectRatioWrapper = {
  aspectRatio: AspectRatio;
};

export const AspectRatioWrapper = styled.div<IAspectRatioWrapper>`
  > * {
    aspect-ratio: ${(props) => props.aspectRatio};
  }
`;
