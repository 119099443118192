import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@caspeco/casper-ui-library.components.input';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@caspeco/casper-ui-library.components.formcontrol';
import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { IAppFeedbackRequest } from 'types/app';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { isValidMinLength } from 'helpers/validation-helper/validation-helper';

interface IFeedbackFormSubjectField {
  control: Control<IAppFeedbackRequest, string>;
}

export function FeedbackFormSubjectField({
  control,
}: IFeedbackFormSubjectField) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: 'subject',
    rules: {
      required: {
        value: true,
        message: t('form_enter_subject'),
      },
      validate: (value) => isValidMinLength(value) || t('form_enter_subject'),
      maxLength: 50,
    },
  });

  const currentCount = field.value?.length ?? 0;
  const countLabel = `${currentCount}/50`;

  return (
    <FormControl isRequired isInvalid={Boolean(error)}>
      <FormLabel color={ThemeColorVariable.OnSurface}>
        {t('form_subject')}
      </FormLabel>
      <Input
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        size="lg"
        type="text"
        autoFocus
        autoComplete="off"
      />
      {!error && (
        <FormHelperText
          color={ThemeColorVariable.OnSurfaceSubdued}
          textAlign="right"
        >
          {countLabel}
        </FormHelperText>
      )}
      <Flex justifyContent="space-between">
        <FormErrorMessage>{error?.message}</FormErrorMessage>
        <FormErrorMessage textAlign="right">{countLabel}</FormErrorMessage>
      </Flex>
    </FormControl>
  );
}
