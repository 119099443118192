/**
 * Group items in array by shared key value.
 * @param array array of any type of items.
 * @param key key value to group items by.
 */
export const groupBy = <T, K extends keyof T>(
  array: T[],
  key: K,
): Record<string, T[]> =>
  array.reduce(
    (result, item) => {
      const groupKey = String(item[key]);
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(item);
      return result;
    },
    {} as Record<string, T[]>,
  );
