import { useState, useEffect } from 'react';
import { IRouteRequestState } from 'types/routeRequestState';
import { useAuthAPI, useAuthStore } from 'store/authStore';
import { useBSTLUserAPI, useBSTLUserStore } from 'store/bstlUserStore';
import { useAppStore } from 'store/appStore';
import { BSTLClientErrorCode } from 'types/api-error';

interface IProtectedRouteRequestsState extends IRouteRequestState {
  isAuthenticated: boolean;
}

/**
 * Handles authentication requests, used to determine if user is
 * currently authenticated and allowed to visit a protected route.
 *
 * Note that detection of email-link sign in is not handled here,
 * e-mail sign in links leads to unprotected route /:appAlias/confirmEmail.
 * @see useConfirmEmailRouteRequests.ts
 *
 * Initally and until all requests have finished, will return @param isLoading = true.
 * If any request fails, will return @param error with error contents.
 * If authenticated, returns @param isAuthenticated = true.
 *
 * Used to display a shared initial loading and error state for a route.
 *
 * @returns shared loading state, auth and error state for route.
 */
export const useProtectedRouteRequests = (): IProtectedRouteRequestsState => {
  const { getUser } = useBSTLUserAPI();
  const { appData } = useAppStore();
  const { bstlUser } = useBSTLUserStore();
  const { firebaseUser } = useAuthStore();
  const { signInAnonymously, signOut } = useAuthAPI();

  const [requestState, setRequestState] =
    useState<IProtectedRouteRequestsState>({
      isLoading: true,
      error: undefined,
      isAuthenticated: false,
    });

  const handleAuth = async () => {
    try {
      if (!appData) throw new Error(BSTLClientErrorCode.NO_APP_DATA);
      const isSignedInFirebaseUser = firebaseUser && !firebaseUser.isAnonymous;

      if (isSignedInFirebaseUser && !bstlUser) {
        // Check for Bstl user connected to signed in Firebase user,
        // at this point it would mean or user is fully registered and valid
        const isFullyRegisteredUser = await getUser(firebaseUser.uid);
        if (!isFullyRegisteredUser) {
          // User never finished registration, sign out firebase user and get an anonymous token instead
          await signOut();
          await signInAnonymously();
        }
      }

      if (!firebaseUser) {
        // No user is found whatsoever, sign in anonymously
        await signInAnonymously();
      }

      setRequestState({
        isLoading: false,
        error: undefined,
        isAuthenticated: true,
      });
    } catch (error) {
      setRequestState({
        error,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    handleAuth();
  }, []);

  return requestState;
};
