import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@caspeco/casper-ui-library.components.input';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@caspeco/casper-ui-library.components.formcontrol';
import { validateName } from 'helpers/validation-helper/validation-helper';
import { IRegisterUserFormFields } from './types';

interface INameField {
  control: Control<IRegisterUserFormFields, string>;
}

export function NameField({ control }: INameField) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: 'name',
    rules: {
      required: {
        value: true,
        message: t('form_enter_name'),
      },
      validate: validateName,
      minLength: { value: 1, message: t('form_name_characters_rule') },
      maxLength: { value: 50, message: t('form_name_characters_rule') },
    },
  });

  return (
    <FormControl isRequired isInvalid={Boolean(error)}>
      <FormLabel>{t('form_name')}</FormLabel>
      <Input
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        type="text"
        size="lg"
        autoComplete="name"
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
