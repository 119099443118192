import { Box } from '@caspeco/casper-ui-library.components.box';
import { Divider } from '@caspeco/casper-ui-library.components.divider';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';

export function ListItemDivider() {
  return (
    <Box px={ThemeSpaceVariable.Medium} my={ThemeSpaceVariable.Medium}>
      <Divider borderColor={ThemeColorVariable.OnBackgroundBorder} />
    </Box>
  );
}
