import { Box } from '@caspeco/casper-ui-library.components.box';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { CSSVarHeaderHeight } from 'components/global-styles/GlobalStyles';

interface IHeader {
  /** Component to be displayed on right side of title. */
  middleComponent: JSX.Element | null;
  /** Component to be displayed on left side of title. */
  leftComponent: JSX.Element | null;
  /** Image to be displayed below header, covering full header width. */
  coverImage: JSX.Element | null;
}

function Header({ leftComponent, middleComponent, coverImage }: IHeader) {
  const position = coverImage ? 'absolute' : 'initial';
  const bg = coverImage ? 'transparent' : ThemeColorVariable.Primary;
  return (
    <>
      <Box
        as="header"
        bg={bg}
        position={position}
        display="flex"
        justifyContent="center"
        alignItems="center"
        w="100%"
        h={CSSVarHeaderHeight}
        maxH={CSSVarHeaderHeight}
        minH={CSSVarHeaderHeight}
        px={ThemeSpaceVariable.Large}
        zIndex={2}
      >
        <Box as="nav" display="flex" alignItems="center" w="100%">
          <Box display="flex" flex={1} justifyContent="flex-start">
            {leftComponent}
          </Box>
          <Box
            flex={3}
            overflow="hidden"
            display="flex"
            justifyContent="center"
          >
            {middleComponent}
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" />
        </Box>
      </Box>

      {coverImage}
    </>
  );
}

export default Header;
