import { CampaignDiscount, ICampaignDiscountValues } from './campaign';
import { DeliveryType } from './deliveryOption';
import { PaymentStatus } from './payment';
import { IPriceIncludingVatValues, PriceIncludingVat } from './price';

export type DeliveryStatus =
  | 'NEW'
  | 'PREPARING'
  | 'READY'
  | 'DELIVERED'
  | 'DENIED';

export interface IOrderResponse {
  orderId: string;
  orderNumber: number;
  status: PaymentStatus;
  deliveryStatus: DeliveryStatus;
  paidAt: string | null;
  currency: string;
  tipAmount: IPriceIncludingVatValues;
  paidAmount: IPriceIncludingVatValues;
  paidAmountExcludingTip: IPriceIncludingVatValues;
  placeName: string;
  placeTimezone: string;
  placeId: string;
  menuId: string;
  message: string | null;
  deliveryOptionName: string;
  deliveryOptionType: DeliveryType;
  deliveryOptionId: string;
  items: IOrderItemValues[];
  readyTime: string;
  receiptId: string | null;
  campaignDiscounts: CampaignDiscount[];
  totalAmountForArticleRows: IPriceIncludingVatValues;
  messages: IOrderMessage[];
  expectedPreparationMinutes: number;
}

interface IOrderItemValues {
  id: string;
  name: string;
  unitPriceIncludingVat: IPriceIncludingVatValues;
  itemTotalAmountIncludingVat: IPriceIncludingVatValues;
  quantity: number;
  taxPercentage: number;
  extras: IOrderItemExtra[];
  options: IOrderItemOption[];
}

export class OrderItem {
  id: string;
  name: string;
  unitPriceIncludingVat: PriceIncludingVat;
  itemTotalAmountIncludingVat: PriceIncludingVat;
  quantity: number;
  taxPercentage: number;
  extras: IOrderItemExtra[];
  options: IOrderItemOption[];

  constructor(values: IOrderItemValues) {
    this.id = values?.id ?? '';
    this.name = values?.name ?? '';
    this.unitPriceIncludingVat = new PriceIncludingVat(
      values?.unitPriceIncludingVat,
    );
    this.itemTotalAmountIncludingVat = new PriceIncludingVat(
      values?.itemTotalAmountIncludingVat,
    );
    this.quantity = values?.quantity ?? 0;
    this.taxPercentage = values?.taxPercentage ?? 0;
    this.extras = values?.extras ?? [];
    this.options = values?.options ?? [];
  }
}

interface IOrderItemExtra {
  id: string;
  name: string;
  unitPriceIncludingVat: PriceIncludingVat;
  taxPercentage: number;
}

interface IOrderItemOption {
  id: string;
  name: string;
  unitPriceIncludingVat: PriceIncludingVat;
  taxPercentage: number;
}

interface IOrderMessage {
  id: string;
  title: string;
  message: string;
  createdAt: string;
  read: boolean;
  placeName: string;
}

export class Order {
  orderId: string;
  orderNumber: number;
  status: PaymentStatus;
  deliveryStatus: DeliveryStatus;
  paidAt: string;
  currency: string;
  tipAmount: PriceIncludingVat;
  paidAmount: PriceIncludingVat;
  paidAmountExcludingTip: PriceIncludingVat;
  placeName: string;
  placeTimezone: string;
  placeId: string;
  menuId: string;
  message: string;
  deliveryOptionName: string;
  deliveryOptionType: DeliveryType;
  deliveryOptionId: string;
  items: OrderItem[];
  readyTime: string;
  receiptId: string | null;
  campaignDiscounts: ICampaignDiscountValues[];
  totalAmountForArticleRows: IPriceIncludingVatValues;
  messages: IOrderMessage[];
  expectedPreparationMinutes: number;

  constructor(values: IOrderResponse) {
    this.orderId = values?.orderId ?? '';
    this.orderNumber = values?.orderNumber ?? 0;
    this.status = values?.status ?? 'CREATED';
    this.deliveryStatus = values?.deliveryStatus ?? 'NEW';
    this.paidAt = values?.paidAt ?? '';
    this.currency = values?.currency ?? 'SEK';
    this.tipAmount = new PriceIncludingVat(values?.tipAmount);
    this.paidAmount = new PriceIncludingVat(values?.paidAmount);
    this.paidAmountExcludingTip = new PriceIncludingVat(
      values?.paidAmountExcludingTip,
    );
    this.placeTimezone = values?.placeTimezone ?? '';
    this.placeName = values?.placeName ?? '';
    this.placeId = values?.placeId ?? '';
    this.menuId = values?.menuId ?? '';
    this.message = values?.message ?? '';
    this.deliveryOptionName = values?.deliveryOptionName ?? '';
    this.deliveryOptionType = values?.deliveryOptionType ?? 'EAT_AT_RESTAURANT';
    this.deliveryOptionId = values?.deliveryOptionId ?? '';
    this.items = values?.items?.map((item) => new OrderItem(item)) ?? [];
    this.readyTime = values?.readyTime ?? '';
    this.receiptId = values?.receiptId ?? null;
    this.campaignDiscounts =
      values?.campaignDiscounts.map((c) => new CampaignDiscount(c)) ?? [];
    this.totalAmountForArticleRows = new PriceIncludingVat(
      values?.totalAmountForArticleRows,
    );
    this.messages = values?.messages ?? [];
    this.expectedPreparationMinutes = values?.expectedPreparationMinutes ?? 0;
  }
}
