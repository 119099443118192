import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Stepper, TStep } from '@caspeco/casper-ui-library.components.stepper';
import { BreakPoint } from 'types/ui';
import { EXTRA_TIME_TO_POLL_IN_MILLISECONDS } from 'routes/order-confirmation-route/helpers/OrderConfirmation.helper';
import { useMinWidthMediaQuery } from 'hooks/useMediaQuery';
import { useOrderStore } from 'store/orderStore';
import { PreparingStep } from './steps/PreparingStep';
import { ReadyStep } from './steps/ReadyStep';
import { RecievedStep } from './steps/RecievedStep';
import { CanceledStep } from './steps/CanceledStep';
import { AwaitingConfirmationStep } from './steps/AwaitingConfirmationStep';

function OrderStatusStepper() {
  const { order } = useOrderStore();
  const [activeStep, setActiveStep] = useState(1);
  const isLargerScreen = useMinWidthMediaQuery(BreakPoint.Small);

  const {
    status,
    paidAt,
    deliveryStatus,
    readyTime,
    placeTimezone,
    expectedPreparationMinutes,
  } = order ?? {};

  const isPastReadyTimeAndExtraTime = Boolean(
    readyTime &&
      DateTime.now() >
        DateTime.fromISO(readyTime).plus({
          milliseconds: EXTRA_TIME_TO_POLL_IN_MILLISECONDS,
        }),
  );

  useEffect(() => {
    if (deliveryStatus === 'NEW') {
      setActiveStep(1);
      return;
    }
    if (isPastReadyTimeAndExtraTime && deliveryStatus === 'PREPARING') {
      // Order is past ready time but is still stuck in preparing state, no step is to be considered active
      // and the polling will have stopped at this point
      setActiveStep(0);
      return;
    }
    if (deliveryStatus === 'PREPARING') {
      setActiveStep(2);
      return;
    }
    if (deliveryStatus === 'READY' || deliveryStatus === 'DELIVERED') {
      setActiveStep(3);
      return;
    }
    if (status === 'REFUNDED' || deliveryStatus === 'DENIED') {
      setActiveStep(1);
    }
  }, [deliveryStatus, isPastReadyTimeAndExtraTime]);

  const awaitingPaymentConfirmation =
    status !== 'PAID' && status !== 'REFUNDED' && deliveryStatus !== 'DENIED';

  const recievedStatus = RecievedStep({
    awaitingPaymentConfirmation,
    paidAt,
    deliveryStatus,
    status,
  });
  const preparingStatus = PreparingStep({
    deliveryStatus,
    isPastReadyTimeAndExtraTime,
  });
  const readyStatus = ReadyStep({
    deliveryStatus,
    readyTime,
    placeTimezone,
    expectedPreparationMinutes,
  });
  const canceledStatus = CanceledStep();
  const awaitingConfirmationStatus = AwaitingConfirmationStep();

  const awaitingConfirmationSteps: TStep[] = [
    awaitingConfirmationStatus,
    preparingStatus,
    readyStatus,
  ];
  const successfulSteps: TStep[] = [
    recievedStatus,
    preparingStatus,
    readyStatus,
  ];
  const canceledSteps: TStep[] = [canceledStatus];

  let steps: TStep[];
  switch (true) {
    case status === 'REFUNDED' || deliveryStatus === 'DENIED':
      steps = canceledSteps;
      break;
    case awaitingPaymentConfirmation:
      steps = awaitingConfirmationSteps;
      break;
    default:
      steps = successfulSteps;
      break;
  }

  return (
    <Stepper
      orientation={isLargerScreen ? 'horizontal' : 'vertical'}
      activeStep={activeStep}
      steps={steps}
    />
  );
}

export default OrderStatusStepper;
