import { IUpsellArticleValues, UpsellArticle } from './article';

export interface IUpsellGroupResponse {
  sortOrder: number;
  title: string;
  description: string;
  articles: IUpsellArticleValues[];
}

export class UpsellGroup {
  sortOrder: number;
  title: string;
  description: string;
  articles: UpsellArticle[];
  constructor(values?: IUpsellGroupResponse) {
    this.sortOrder = values?.sortOrder ?? -1;
    this.title = values?.title ?? '';
    this.description = values?.description ?? '';
    this.articles =
      values?.articles
        ?.map((i) => new UpsellArticle(i))
        .sort((a, b) => a.sortOrder - b.sortOrder) ?? [];
  }
}

export interface IUpsellGroupListResponse {
  upsellGroups: IUpsellGroupResponse[];
}

export class UpsellGroupList {
  upsellGroups: UpsellGroup[];
  constructor(values?: IUpsellGroupListResponse) {
    this.upsellGroups =
      values?.upsellGroups
        ?.filter((upsellGroup) => upsellGroup.articles.length > 0)
        ?.map((i) => new UpsellGroup(i))
        .sort((a, b) => a.sortOrder - b.sortOrder) ?? [];
  }
}
