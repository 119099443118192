import { useTranslation } from 'react-i18next';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { DateTime } from 'luxon';
import i18next from 'i18next';
import { getLongWeekday } from 'helpers/opening-hours-helper/opening-hours-helper';
import { SupportedLocale } from 'types/locale';
import { IHours } from 'types/openingHours';

interface IOpeningHoursChip {
  weekDay: string;
  hours: IHours[];
}
function OpeningHoursText({ weekDay, hours }: IOpeningHoursChip) {
  const locale = i18next.language ?? SupportedLocale.SV;
  const translatedWeekday = getLongWeekday(weekDay);
  const today = DateTime.now().setLocale(locale).weekdayLong;
  const dateIsToday = today === translatedWeekday;
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="space-between"
      py={ThemeSpaceVariable.XSmall}
    >
      <Text casing="capitalize" fontWeight={dateIsToday ? 'bold' : 'normal'}>
        {getLongWeekday(weekDay)}
      </Text>
      <Box>
        {hours.length
          ? hours.map((hour, i) => (
              <Text
                key={i}
                fontSize={ThemeFontSizeVariable.Medium}
                fontWeight={dateIsToday ? 'bold' : 'normal'}
              >{`${hour.opens} - ${hour.closes}`}</Text>
            ))
          : t('time_closed')}
      </Box>
    </Box>
  );
}

export default OpeningHoursText;
