import { Box } from '@caspeco/casper-ui-library.components.box';
import {
  ThemeBorderVariable,
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeRadiusVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Divider } from '@caspeco/casper-ui-library.components.divider';
import { Skeleton } from '@caspeco/casper-ui-library.components.skeleton';
import {
  CSSVarColumnWidthLarge,
  CSSVarColumnWidthSmall,
} from 'components/global-styles/GlobalStyles';

function SkeletonPaymentMethods() {
  return (
    <Box borderRadius={ThemeRadiusVariable.Medium}>
      <Box
        h="1.25rem"
        w="50%"
        mt={ThemeSpaceVariable.Medium}
        mb={ThemeSpaceVariable.Small}
      />
      <Box flexDirection="row" gap={ThemeSpaceVariable.Medium}>
        <Skeleton h="58px" borderTopRadius={ThemeRadiusVariable.Medium} />
        <Skeleton h="58px" flex="1" />
        <Skeleton h="58px" borderBottomRadius={ThemeRadiusVariable.Medium} />
      </Box>
      <Box
        mt={ThemeSpaceVariable.Medium}
        flexDirection="row"
        alignContent="center"
        gap={ThemeSpaceVariable.Small}
      >
        <Skeleton h="56px" w="100%" borderRadius={ThemeRadiusVariable.XLarge} />
      </Box>
    </Box>
  );
}

function SkeletonTip() {
  return (
    <Box borderRadius={ThemeRadiusVariable.Medium}>
      <Stack spacing={ThemeSpaceVariable.Medium}>
        <Skeleton h="1.25rem" w="20%" mt={ThemeSpaceVariable.Medium} />
        <Skeleton h="3rem" borderRadius={ThemeRadiusVariable.Small} />
      </Stack>
    </Box>
  );
}

function SkeletonContactForm() {
  return (
    <Stack spacing={ThemeSpaceVariable.Medium}>
      <Skeleton h="1.25rem" w="20%" />
      <Skeleton h="3rem" borderRadius={ThemeRadiusVariable.Small} />
      <Skeleton h="1.25rem" w="30%" mt={ThemeSpaceVariable.Medium} />
      <Skeleton h="3rem" borderRadius={ThemeRadiusVariable.Small} />
      <Skeleton h="1.25rem" w="30%" mt={ThemeSpaceVariable.Medium} />
      <Skeleton h="3rem" borderRadius={ThemeRadiusVariable.Small} />
      <Skeleton h="1.25rem" w="30%" mt={ThemeSpaceVariable.Medium} />
      <Skeleton h="3rem" borderRadius={ThemeRadiusVariable.Small} />
    </Stack>
  );
}

function SkeletonMessage() {
  return (
    <Box borderRadius={ThemeRadiusVariable.Medium}>
      <Stack spacing={ThemeSpaceVariable.Medium}>
        <Skeleton h="1.25rem" w="70%" mt={ThemeSpaceVariable.Medium} />
        <Skeleton h="4.5rem" borderRadius={ThemeRadiusVariable.Small} />
      </Stack>
    </Box>
  );
}

function SkeletonCheckoutSummary() {
  return (
    <Box
      mt={ThemeSpaceVariable.Large}
      borderRadius={ThemeRadiusVariable.Medium}
      p={ThemeSpaceVariable.Medium}
      w="100%"
      border={ThemeBorderVariable.XSmall}
      borderColor={ThemeColorVariable.OnBackgroundBorder}
    >
      <Stack w="100%" spacing={ThemeSpaceVariable.Medium}>
        <Skeleton h="1.25rem" w="80%" />
        <Box flexDirection="row" justifyContent="space-between">
          <Skeleton h="1.25rem" w="50%" mb={ThemeSpaceVariable.Small} />
          <Skeleton h="1.25rem" w="70%" />
        </Box>
        <Box flexDirection="row" justifyContent="space-between">
          <Skeleton h="1.25rem" w="45%" mb={ThemeSpaceVariable.Small} />
          <Skeleton h="1.25rem" w="40%" />
        </Box>
      </Stack>
      <Box h="2rem" />
    </Box>
  );
}

export function SkeletonCheckout() {
  return (
    <span data-testid="loading-indicator">
      <Flex
        justify="center"
        align="center"
        w="100%"
        px={ThemeSpaceVariable.Medium}
      >
        <Flex
          direction={{ base: 'column-reverse', md: 'row' }}
          gap={{ base: ThemeSpaceVariable.None, md: ThemeSpaceVariable.Medium }}
          w="100%"
          justify="center"
          pb={ThemeSpaceVariable.Medium}
        >
          <Stack
            spacing={ThemeSpaceVariable.Small}
            className="checkout-form"
            w={['100%', null, '608px']}
            maxW={CSSVarColumnWidthLarge}
          >
            <Box display={{ base: 'none', md: 'block' }}>
              <Skeleton
                height={ThemeFontSizeVariable.X2Large}
                w="30%"
                mt={ThemeSpaceVariable.Large}
                mb={ThemeSpaceVariable.Medium}
              />
              <Skeleton
                mb={ThemeSpaceVariable.Large}
                height={ThemeFontSizeVariable.Medium}
                w="40%"
              />
            </Box>
            <SkeletonContactForm />
            <SkeletonMessage />
            <SkeletonTip />
            <SkeletonTip />
            <SkeletonPaymentMethods />
          </Stack>
          <Box display={{ base: 'none', md: 'block' }} minH="100%" zIndex={1}>
            <Divider orientation="vertical" />
          </Box>
          <Box as="aside" w={{ base: '100%', md: CSSVarColumnWidthSmall }}>
            <Box display={{ md: 'none' }}>
              <Box
                mt={{
                  base: ThemeSpaceVariable.Small,
                  md: ThemeSpaceVariable.Large,
                }}
                mb={ThemeSpaceVariable.Medium}
              >
                <Box display={{ base: 'block', md: 'none' }}>
                  <Skeleton
                    height={ThemeFontSizeVariable.X2Large}
                    w="30%"
                    mt={ThemeSpaceVariable.Large}
                    mb={ThemeSpaceVariable.Medium}
                  />
                  <Skeleton
                    mb={ThemeSpaceVariable.Large}
                    height={ThemeFontSizeVariable.Medium}
                    w="40%"
                  />
                </Box>
                <Skeleton h="130px" w="100%" mb={ThemeSpaceVariable.Small} />
              </Box>
              <SkeletonContactForm />
              <SkeletonMessage />
              <SkeletonTip />
              <SkeletonTip />
              <SkeletonPaymentMethods />
            </Box>
            <Box display={{ base: 'none', md: 'block' }}>
              <SkeletonCheckoutSummary />
            </Box>
          </Box>
        </Flex>
      </Flex>
    </span>
  );
}
