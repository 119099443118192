import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Divider } from '@caspeco/casper-ui-library.components.divider';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { useBSTLUserAPI } from 'store/bstlUserStore';
import { useAuthAPI } from 'store/authStore';
import { useCartAPI, useCartStore } from 'store/cartStore';
import { useDisclosure } from '@caspeco/casper-ui-library.components.modal';
import ConfirmationDialog from 'components/confirm-dialog/ConfirmDialog';

export function SignOutButton() {
  const { t } = useTranslation();
  const { appAlias } = useParams<'appAlias'>();
  const { showToast } = useToast();
  const { resetUser } = useBSTLUserAPI();
  const { signOut, signInAnonymously } = useAuthAPI();
  const { cart } = useCartStore();
  const { deleteCart } = useCartAPI();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();

  const onSignOut = async () => {
    if (cart) {
      // Cart must be deleted before signing out, else the user loses access to delete cart
      // Should be deleted even if empty, to provide correct access to potential new cart
      await deleteCart(cart.cartId);
    }

    // Sign out the identified user
    signOut()
      .then(() => {
        // Remove any stored BSTL user from our local store
        resetUser();
        // Sign in immedietly as a anonymous user to grab a new valid token
        signInAnonymously();
        // Navigate to start page
        navigate(`/${appAlias}`);
        showToast({
          type: 'success',
          description: t('account_now_signed_out'),
        });
      })
      .catch(() => {
        showToast({
          type: 'error',
          description: t('account_sign_out_failed'),
        });
      });
  };

  const onClick = () => {
    if (cart && !cart.isEmpty()) {
      // Open dialog to confirm deleting cart with items in it
      onOpen();
    } else {
      onSignOut();
    }
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        primaryAction={onSignOut}
        secondaryAction={onClose}
        title={t('account_sign_out')}
        message={t('account_sign_out_empty_cart_confirmation')}
        primaryButtonText={t('account_sign_out')}
        secondaryButtonText={t('action_cancel')}
      />
      <Box px={ThemeSpaceVariable.Medium} my={ThemeSpaceVariable.Medium}>
        <Divider borderColor={ThemeColorVariable.OnBackgroundBorder} />
      </Box>
      <Button ml={ThemeSpaceVariable.Medium} onClick={onClick}>
        {t('account_sign_out')}
      </Button>
    </>
  );
}
