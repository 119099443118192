import { useTranslation } from 'react-i18next';

import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { TStep } from '@caspeco/casper-ui-library.components.stepper';

export const CanceledStep = (): TStep => {
  const { t } = useTranslation();
  return {
    label: (
      <Text
        fontWeight="medium"
        color={ThemeColorVariable.OnBackgroundHeader}
        fontSize={ThemeFontSizeVariable.Medium}
      >
        {t('order_denied')}
      </Text>
    ),
    color: ThemeColorVariable.Error,
    iconColor: ThemeColorVariable.OnError,
    icon: 'times',
  };
};
