import { Place } from 'types/place';
import { ThemeSpaceVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import PlaceCard from '../place-card/PlaceCard';

interface IPlacesList {
  places: Place[];
}

function PlacesList({ places }: IPlacesList) {
  // Create a min height that matches cover image if present,
  // use to prevent unwanted layout shifts on smaller screens for places/search results with two or less items
  const coverImgHeight = document.querySelector('img[id=cover]')?.clientHeight;
  const minHeightForSmallScreen =
    coverImgHeight && places.length <= 2 ? `${coverImgHeight}px` : 'auto';

  return (
    <Flex
      direction="column"
      mt={ThemeSpaceVariable.Large}
      w="100%"
      minH={{ base: minHeightForSmallScreen, md: 'auto' }}
    >
      {places.map((place) => (
        <PlaceCard place={place} key={place.id} />
      ))}
    </Flex>
  );
}

export default PlacesList;
