import { useTranslation } from 'react-i18next';
import {
  ThemeColorVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Input } from '@caspeco/casper-ui-library.components.input';
import {
  FormControl,
  FormLabel,
} from '@caspeco/casper-ui-library.components.formcontrol';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { useMinWidthMediaQuery } from 'hooks/useMediaQuery';
import { BreakPoint } from 'types/ui';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';
import { IconButton } from '@caspeco/casper-ui-library.components.icon-button';

interface IPlacesSearchBar {
  onChange: (value: string) => void;
  value: string;
  onClick: () => void;
}

function PlacesSearchBar({ onChange, value, onClick }: IPlacesSearchBar) {
  const { t } = useTranslation();
  const isLargerScreen = useMinWidthMediaQuery(BreakPoint.Medium);

  const clearSearchQuery = () => {
    onChange('');
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const renderRightElement = (): JSX.Element | undefined => {
    if (value.length > 0) {
      return (
        <button
          type="button"
          aria-label={t('misc_clear_search')}
          onClick={clearSearchQuery}
        >
          <Icon
            color={ThemeColorVariable.OnSurface}
            size={ThemeIconSizeVariable.Small}
            icon={Icons.Close}
          />
        </button>
      );
    }
    return undefined;
  };

  const renderSortButtonContent = (): JSX.Element => {
    if (isLargerScreen)
      return (
        <Button minW="200px" variant="secondary" onClick={onClick}>
          <Flex gap={ThemeSpaceVariable.XSmall}>
            <Icon
              color="inherit"
              size={ThemeIconSizeVariable.Small}
              icon={Icons.Pin}
            />
            {t('location_sort')}
          </Flex>
        </Button>
      );
    return (
      <IconButton
        icon={Icons.Pin}
        aria-label={t('location_sort')}
        variant="secondary"
        onClick={onClick}
      />
    );
  };

  return (
    <FormControl>
      <FormLabel className="visually-hidden">{t('location_search')}</FormLabel>
      <Flex gap={ThemeSpaceVariable.Medium} align="center">
        <Input
          size="lg"
          placeholder={t('misc_search_here')}
          w="100%"
          name="search-bar"
          type="text"
          value={value}
          onChange={handleOnChange}
          leftElement={
            <Icon
              icon={Icons.Search}
              color={ThemeColorVariable.OnSurface}
              size={ThemeIconSizeVariable.Small}
            />
          }
          rightElement={renderRightElement()}
        />
        {renderSortButtonContent()}
      </Flex>
    </FormControl>
  );
}

export default PlacesSearchBar;
