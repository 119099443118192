import { ImageMap, ImageMapValues } from './imageMap';

export type OfferStatus = 'ACTIVE' | 'INACTIVE' | 'LOCKED_BY_OTHER';

export interface IOfferResponse {
  id: string;
  name: string;
  description: string;
  image: ImageMapValues;
  // A date string formatted as YYYY-MM-DD
  toDate: string;
  sortOrder: number;
  status: string;
  // ISO Date string in UTC or null if not locked
  lockExpirationDate: string | null;
}

export interface IOfferListingResponse {
  offers: IOfferResponse[];
}

export class Offer {
  id: string;
  name: string;
  description: string;
  image: ImageMap;
  toDate: string;
  sortOrder: number;
  status: OfferStatus;
  lockExpirationDate: string | undefined;

  constructor(values?: IOfferResponse) {
    this.id = values?.id ?? '';
    this.name = values?.name ?? '';
    this.description = values?.description ?? '';
    this.image = new ImageMap(values?.image);
    this.toDate = values?.toDate ?? '';
    this.sortOrder = values?.sortOrder ?? 0;
    this.status = (values?.status as OfferStatus) ?? 'ACTIVE';
    this.lockExpirationDate = values?.lockExpirationDate ?? undefined;
  }
}
