import { Skeleton } from '@caspeco/casper-ui-library.components.skeleton';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import {
  ThemeBorderVariable,
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';

const startColor = ThemeColorVariable.Surface;
const endColor = ThemeColorVariable.OnSurfaceSubdued;

function SkeletonHistoryCard() {
  return (
    <Box
      borderBottom={ThemeBorderVariable.XSmall}
      borderColor={ThemeColorVariable.OnSurfaceBorder}
    >
      <Flex
        direction="column"
        my={ThemeSpaceVariable.Medium}
        gap={ThemeSpaceVariable.Small}
      >
        <Flex justify="space-between" align="flex-end">
          <Skeleton
            height={ThemeFontSizeVariable.Large}
            endColor={endColor}
            startColor={startColor}
            w="200px"
          />
          <Skeleton
            endColor={endColor}
            startColor={startColor}
            height={ThemeFontSizeVariable.Large}
            w="50px"
          />
        </Flex>
        <Flex justify="space-between" align="flex-end">
          <Flex gap={ThemeSpaceVariable.XSmall}>
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              height="25px"
              w="45px"
            />
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              height="25px"
              w="85px"
            />
          </Flex>
          <Skeleton
            endColor={endColor}
            startColor={startColor}
            height={ThemeFontSizeVariable.Small}
            w="100px"
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export function HistorySkeleton() {
  return (
    <Flex justify="center">
      <Box w="100%">
        <Box mb={ThemeSpaceVariable.XLarge}>
          <Skeleton
            endColor={endColor}
            startColor={startColor}
            height={ThemeFontSizeVariable.X2Large}
            w="250px"
          />
          {[...Array(2)].map((_entry, i) => (
            <SkeletonHistoryCard key={i} />
          ))}
        </Box>
      </Box>
    </Flex>
  );
}
