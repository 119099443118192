import { createRoot } from 'react-dom/client';
import rg4js from 'raygun4js';
import 'config/i18n';
import { getInitialCrashReportingSetting } from 'components/app/App.helper';
import App from 'components/app/App';
import packageJson from '../package.json';
import { GlobalStyles } from './components/global-styles/GlobalStyles';
import reportWebVitals from './reportWebVitals';
import { config, EEnvironment } from './config/app.config';
import { setupLogger, shouldLog } from './helpers/log-helper/log-helper';

async function enableMocking(): Promise<ServiceWorkerRegistration | undefined> {
  if (
    config.environment === EEnvironment.msw ||
    config.environment === EEnvironment.mswCI
  ) {
    const { worker } = await import('./test/browser');
    // `worker.start()` returns a Promise that resolves once the Service Worker is up
    // and ready to intercept requests with mock responses, used for testing environments
    return worker.start({
      onUnhandledRequest(request) {
        // Don't warn for requests to Firebase Emulator
        if (request.url.includes('127.0.0.1:9099')) return;
        // Nor for requests to the test and image fixtures
        if (request.url.includes('test/fixtures')) return;
        // Nor for requests to the fonts
        if (request.url.includes('fonts')) return;

        // DO warn for other unhandled requests
        console.warn(
          'MSW Found an unhandled %s request to %s',
          request.method,
          request.url,
        );
      },
    });
  }
  return undefined;
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

enableMocking().then(() => {
  root.render(
    <>
      <GlobalStyles />
      <App />
    </>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Setup Raygun
rg4js('apiKey', 'DdUZ3taTwBiSG73Ir8LuDw');
rg4js('enableCrashReporting', getInitialCrashReportingSetting());
rg4js('setVersion', packageJson.version);
rg4js('withTags', [String(config.environment)]);
rg4js('options', {
  excludedHostnames: ['localhost'],
  ignoreAjaxError: true,
  ignore3rdPartyErrors: true,
});

if (shouldLog()) {
  setupLogger();
}
