import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  ThemeBorderVariable,
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeRadiusVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { ICampaignDiscountValues } from 'types/campaign';
import { Cart } from 'types/cart';
import { Order } from 'types/order';
import Price from 'components/price';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Divider } from '@caspeco/casper-ui-library.components.divider';
import { useBaseColorValue } from 'components/base-color-context';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';
import { getSummary, ISummaryAddon } from './summary-helpers';

function SummaryAddon({
  quantity,
  addon,
}: {
  quantity: number;
  addon: ISummaryAddon;
}) {
  const { baseColor } = useBaseColorValue();

  let textColor;
  switch (baseColor) {
    case 'background':
      textColor = ThemeColorVariable.OnBackgroundSubdued;
      break;
    case 'surface':
    default:
      textColor = ThemeColorVariable.OnSurfaceSubdued;
      break;
  }

  const { name, price } = addon;
  return (
    <Flex
      ml={ThemeSpaceVariable.Small}
      gap={ThemeSpaceVariable.XSmall}
      color={textColor}
      align="flex-end"
    >
      <Text fontSize={ThemeFontSizeVariable.Small}>
        {`${quantity} x ${name}`}{' '}
        {price && (
          <Price
            as="span"
            prepend="("
            append=")"
            fontSize={ThemeFontSizeVariable.Small}
            inHousePrice={price}
            showCurrency={false}
            forceDecimals
          />
        )}
      </Text>
    </Flex>
  );
}

interface ISummary {
  data: Cart | Order;
  campaigns?: ICampaignDiscountValues[];
}

/**
 * Shows a summary of items to be purchased (if @param data is a Cart)
 * or items that have been purchased (if @param data is an Order).
 * The appearance is the same.
 */
export function Summary({ data, campaigns }: ISummary) {
  const { t } = useTranslation();
  const { baseColor } = useBaseColorValue();

  let textColor: ThemeColorVariable;
  let borderColor: ThemeColorVariable;
  let detailColor: ThemeColorVariable;
  switch (baseColor) {
    case 'background':
      textColor = ThemeColorVariable.OnBackground;
      borderColor = ThemeColorVariable.OnBackgroundBorder;
      detailColor = ThemeColorVariable.OnBackgroundSubdued;
      break;
    case 'surface':
    default:
      textColor = ThemeColorVariable.OnSurface;
      borderColor = ThemeColorVariable.OnSurfaceBorder;
      detailColor = ThemeColorVariable.OnSurfaceSubdued;
      break;
  }

  const summary = getSummary(data);
  if (!summary) return null;

  const { tipAmount, totalPrice, totalPriceExcludingTips, items } = summary;
  const hasTip = tipAmount && tipAmount.cents > 0;

  return (
    <Box
      color={textColor}
      p={ThemeSpaceVariable.Medium}
      borderRadius={ThemeRadiusVariable.Small}
      border={ThemeBorderVariable.XSmall}
      borderColor={borderColor}
    >
      <Stack spacing={ThemeSpaceVariable.XSmall}>
        {items.map((item) => (
          <Box key={item.id}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                <Text>{`${item.quantity} x ${item.name}`}</Text>
                <>
                  {item.options.length > 0 &&
                    item.options.map((option) => (
                      <React.Fragment key={option.id}>
                        <SummaryAddon quantity={item.quantity} addon={option} />
                      </React.Fragment>
                    ))}
                  {item.extras.length > 0 &&
                    item.extras.map((extra) => (
                      <React.Fragment key={extra.id}>
                        <SummaryAddon quantity={item.quantity} addon={extra} />
                      </React.Fragment>
                    ))}
                </>
              </Box>
              <Price
                showCurrency={false}
                inHousePrice={item.price}
                forceDecimals
                hideIfZero={false}
              />
            </Box>
          </Box>
        ))}
        {campaigns &&
          campaigns.map((campaign, index) => (
            // Use index as key as campaigns are not unique, both names and ids can appear multiple times
            <Box as="article" key={index}>
              <Flex align="center" gap={ThemeSpaceVariable.Small}>
                <Icon color="inherit" icon={Icons.Discount} />
                <Flex justify="space-between" w="100%">
                  <Text>{campaign.name}</Text>
                  <Flex align="center">
                    <Price
                      prepend="-"
                      inHousePrice={campaign.discountAmount}
                      showCurrency={false}
                      forceDecimals
                    />
                  </Flex>
                </Flex>
              </Flex>
              {campaign.code && (
                <Text
                  fontSize={ThemeFontSizeVariable.Small}
                  color={detailColor}
                >
                  {campaign.code}
                </Text>
              )}
            </Box>
          ))}
      </Stack>
      <Divider my={ThemeSpaceVariable.Small} borderColor={borderColor} />
      <Stack spacing={ThemeSpaceVariable.XSmall}>
        {hasTip && (
          <>
            <Box display="flex" justifyContent="space-between">
              <Text>{t('money_subtotal')}</Text>
              <Price
                inHousePrice={totalPriceExcludingTips}
                showCurrency={false}
                forceDecimals
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Text>{t('money_tip')}</Text>
              <Price
                showCurrency={false}
                forceDecimals
                inHousePrice={tipAmount}
              />
            </Box>
          </>
        )}
        <Flex justifyContent="space-between">
          <Flex gap={ThemeSpaceVariable.XSmall}>
            <Text fontWeight="bold">
              {`${t('money_total')} ${totalPrice.currency}`}
            </Text>
            <Text color={detailColor}>{`(${t('money_including_vat')})`}</Text>
          </Flex>
          <Price
            fontWeight="bold"
            align="right"
            inHousePrice={totalPrice}
            showCurrency={false}
            forceDecimals
            hideIfZero={false}
          />
        </Flex>
      </Stack>
    </Box>
  );
}
