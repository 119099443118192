import { useTranslation } from 'react-i18next';
import { NavigationListButton } from '@caspeco/casper-ui-library.components.navigation-list';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeColorVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';
import { getNameOfCurrentLang } from 'helpers/locale-helper/locale-helper';

export function ListItemChangeLanguage({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <NavigationListButton
      onClick={onClick}
      aria-label={t('misc_change_language')}
    >
      <Flex gap={ThemeSpaceVariable.Small} align="center">
        <Icon
          icon={Icons.Language}
          size={ThemeIconSizeVariable.Small}
          color={ThemeColorVariable.OnBackground}
        />
        <Text color={ThemeColorVariable.OnBackground}>
          {t('misc_x_language', { 0: getNameOfCurrentLang() })}
        </Text>
      </Flex>
    </NavigationListButton>
  );
}
