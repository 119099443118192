import { Place } from 'types/place';

const deg2rad = (deg: number) => deg * (Math.PI / 180);

const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d; // distance returned
};

const getUserPosition = () =>
  new Promise<GeolocationPosition>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (location) => resolve(location),
      (error) => reject(new Error(error.message)),
    );
  });

const sortByClosest = (a: Place, b: Place) =>
  a.location.distanceInKm! - b.location.distanceInKm!;

export const sortByLocation = async (places: Place[]): Promise<Place[]> => {
  if (!navigator?.geolocation) return places;

  let position: GeolocationPosition;
  try {
    position = await getUserPosition();
  } catch (_error) {
    // Access to position denied, throw error for ui to respond with toast
    throw new Error();
  }

  const placesWithDistance = places.map((place) => {
    const distanceInKm = getDistanceFromLatLonInKm(
      position.coords.latitude,
      position.coords.longitude,
      place.location.latitude,
      place.location.longitude,
    );
    place.setLocationDistance(distanceInKm);
    return place;
  });

  return placesWithDistance.sort(sortByClosest);
};

/**
 * Create user friendly string to display distance.
 * When distance in kilometers is less than 1 the string will represent meters instead.
 */
export const getDistanceString = (distanceInKm: number): string => {
  // Distance is less than one km, display in meter
  if (Math.round(distanceInKm) === 0) {
    const distanceInMeterRounded: number = Math.round(distanceInKm * 1000);
    // '\xa0' is 'non breaking space', used to avoid word break between distance and unit
    return `${distanceInMeterRounded}\xa0m`;
  }
  // In kilometers we want to display a locale formatted string including one decimal
  const distanceInKmWithOneDecimal: string = distanceInKm.toLocaleString(
    undefined,
    {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
  );
  return `${distanceInKmWithOneDecimal}\xa0km`;
};

/**
 * Returns true if query has a match in
 * Place name OR
 * Place adress OR
 * Place city
 */
export const matchesQuery = (place: Place, searchQuery: string): boolean => {
  const { name, address } = place;

  if (name.toLowerCase().includes(searchQuery)) return true;
  if (address?.city?.toLowerCase().includes(searchQuery)) return true;
  if (address?.streetAddress?.toLowerCase().includes(searchQuery)) return true;

  return false;
};
