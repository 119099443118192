import { DeliveryType } from './deliveryOption';
import { DeliveryStatus } from './order';
import { PaymentStatus } from './payment';
import { IPriceIncludingVatValues, PriceIncludingVat } from './price';

interface IOrderHistoryResponse {
  orderId: string;
  orderNumber: string;
  status: PaymentStatus;
  deliveryStatus: DeliveryStatus;
  paidAt: string;
  currency: string;
  placeName: string;
  placeTimezone: string;
  placeId: string;
  deliveryOptionName: string;
  deliveryOptionType: DeliveryType;
  readyTime: string;
  itemCount: number;
  hasUpdate: boolean;
  paidAmount: IPriceIncludingVatValues;
}

export interface IOrderHistoryListResponse {
  orders: IOrderHistoryResponse[];
}

export class OrderHistory {
  orderId: string;
  orderNumber: string;
  status: PaymentStatus;
  deliveryStatus: DeliveryStatus;
  paidAt: string;
  currency: string;
  placeName: string;
  placeTimezone: string;
  placeId: string;
  deliveryOptionName: string;
  deliveryOptionType: DeliveryType;
  readyTime: string;
  itemCount: number;
  hasUpdate: boolean;
  paidAmount: PriceIncludingVat;

  constructor(values: IOrderHistoryResponse) {
    this.orderId = values?.orderId;
    this.orderNumber = values?.orderNumber;
    this.status = values?.status;
    this.deliveryStatus = values?.deliveryStatus;
    this.paidAt = values?.paidAt;
    this.currency = values?.currency;
    this.placeName = values?.placeName;
    this.placeTimezone = values?.placeTimezone;
    this.placeId = values?.placeId;
    this.deliveryOptionName = values?.deliveryOptionName;
    this.deliveryOptionType = values?.deliveryOptionType;
    this.readyTime = values?.readyTime;
    this.itemCount = values?.itemCount;
    this.hasUpdate = values?.hasUpdate;
    this.paidAmount = new PriceIncludingVat(values?.paidAmount);
  }
}

export class OrderHistoryList {
  orders: OrderHistory[];
  constructor(values?: IOrderHistoryListResponse) {
    this.orders = values?.orders?.map((order) => new OrderHistory(order)) ?? [];
  }
}
