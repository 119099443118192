import { useTranslation } from 'react-i18next';
import { NavigationListButton } from '@caspeco/casper-ui-library.components.navigation-list';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeColorVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';
import { useOrderStore } from 'store/orderStore';
import { usePlaceStore } from 'store/placeStore';

export function ListItemChangePlace({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();
  const { order } = useOrderStore();
  const { place } = usePlaceStore();

  const placeName = place?.name || order?.placeName;

  return (
    <>
      <NavigationListButton onClick={onClick} aria-label={t('location_change')}>
        <Flex justifyContent="space-between" gap={ThemeSpaceVariable.Small}>
          <Flex align="center" gap={ThemeSpaceVariable.Small}>
            <Icon
              icon={Icons.Pin}
              size={ThemeIconSizeVariable.Small}
              color={ThemeColorVariable.OnBackground}
            />
            <Text
              color={ThemeColorVariable.OnBackground}
              whiteSpace="break-spaces"
              wordBreak="break-all"
            >
              {placeName}
            </Text>
          </Flex>
          <Text color={ThemeColorVariable.OnBackground} decoration="underline">
            {t('action_change')}
          </Text>
        </Flex>
      </NavigationListButton>
    </>
  );
}
