import { useState, useEffect } from 'react';
import { IRouteRequestState } from 'types/routeRequestState';
import { usePlacesAPI } from 'store/placesStore';

/**
 * Handles all requests that need to be made initially for this route.
 * Initally and until all requests have finished, will return @param isLoading = true.
 * If any request fails, will return @param error with error contents.
 *
 * Used to display a shared initial loading and error state for a route.
 *
 * @returns shared loading state and error state for all requests.
 */
export const usePlacesRouteRequests = (): IRouteRequestState => {
  const { getPlaces } = usePlacesAPI();
  const [requestState, setRequestState] = useState<IRouteRequestState>({
    isLoading: true,
    error: undefined,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        await getPlaces();
        setRequestState({
          isLoading: false,
          error: undefined,
        });
      } catch (error) {
        setRequestState({
          isLoading: false,
          error,
        });
      }
    };
    fetch();
  }, []);

  return requestState;
};
