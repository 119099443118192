import { useSearchParams } from 'react-router-dom';
import {
  getLocalPriceFormat,
  getPriceTypeFromDeliveryType,
  isFree,
} from 'helpers/price-helper/price-helper';
import { PriceIncludingVat, PriceType } from 'types/price';
import { Text, TextProps } from '@caspeco/casper-ui-library.components.text';
import { DeliveryType } from 'types/deliveryOption';

export interface IPrice extends TextProps {
  /** Use as default price if you only want to provide a single price with pre-determined price type. */
  inHousePrice: PriceIncludingVat;
  /** Use when you want to provide two prices, one for inHouse and one for takeAway. */
  takeAwayPrice?: PriceIncludingVat;
  /** Displays no price if prize is zero (free). Defaults to true. */
  hideIfZero?: boolean;
  /** Shows price with currency string, the being translated with Number.toLocaleString and
   * user broser locale. Defaults to true. */
  showCurrency?: boolean;
  /** When true, will show two decimal digits even if price is even. Defaults to false. */
  forceDecimals?: boolean;
  /** String to add before price number. For ex "+" */
  prepend?: string;
  /** String to add after price number. */
  append?: string;
}

/**
 * How to use:
 * PriceIncludingVat should always be displayed in the interface with the Price component.
 * It formats price currency based on user locale and determines whether to show @param inHousePrice
 * or @param takeAwayPrice based on current deliveryOption.
 * Example: See usage in SingleCartRow price display.
 */
function Price({
  inHousePrice,
  takeAwayPrice,
  prepend,
  append,
  hideIfZero = true,
  showCurrency = true,
  forceDecimals = false,
  fontFamily = 'inherit',
  fontWeight = 'inherit',
  fontSize = 'inherit',
  ...textProperties
}: IPrice) {
  const [searchParams] = useSearchParams();

  const deliveryOptionType = searchParams.get('deliveryOptionType') as
    | DeliveryType
    | undefined;

  /**
   * Cart and order never exist at the same time, base price on cart first
   * and order second. Helper falls back to in house-pricetype if neither exists.
   */
  const priceType = getPriceTypeFromDeliveryType(deliveryOptionType);
  let localPrice: string;

  if (priceType === PriceType.TAKEAWAY && takeAwayPrice) {
    localPrice = getLocalPriceFormat(
      takeAwayPrice,
      undefined,
      showCurrency,
      forceDecimals,
    );
  } else {
    localPrice = getLocalPriceFormat(
      inHousePrice,
      undefined,
      showCurrency,
      forceDecimals,
    );
  }

  if (isFree(localPrice) && hideIfZero) return null;

  return (
    <Text
      {...textProperties}
      whiteSpace="nowrap"
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
    >
      {prepend || null}
      {localPrice}
      {append || null}
    </Text>
  );
}

export default Price;
