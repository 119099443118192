import { useTranslation } from 'react-i18next';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Link } from '@caspeco/casper-ui-library.components.link';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { IAppGeneralFeatures } from 'types/appFeatures';
import { Icons } from '@caspeco/casper-ui-library.components.icon';

interface ISignInModalBecomeBusinessCustomer {
  onGoBack: () => void;
}

export function SignInModalBecomeBusinessCustomer({
  onGoBack,
}: ISignInModalBecomeBusinessCustomer) {
  const { t } = useTranslation();
  const b2bEmailAddress =
    getLocalStorage<IAppGeneralFeatures>('appFeatures')?.b2bEmailAddress;

  return (
    <>
      <ModalHeader p={ThemeSpaceVariable.Medium}>
        <Flex align="center" justify="space-between">
          <Button variant="ghost" onClick={onGoBack} leftIcon={Icons.Back}>
            {t('account_sign_in')}
          </Button>
          <ModalCloseButton />
        </Flex>
      </ModalHeader>
      <ModalBody
        px={ThemeSpaceVariable.XLarge}
        pb={ThemeSpaceVariable.XLarge}
        pt={ThemeSpaceVariable.Large}
        textAlign="center"
      >
        <Heading color={ThemeColorVariable.OnSurfaceHeader} as="h2">
          {t('account_become_business_customer')}
        </Heading>
        <Text my={ThemeSpaceVariable.Large}>
          {t('account_become_business_customer_description')}
        </Text>
        <Link
          color={ThemeColorVariable.OnSurface}
          href={`mailto:${b2bEmailAddress}`}
        >
          {b2bEmailAddress}
        </Link>
      </ModalBody>
    </>
  );
}
