import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ThemeSpaceVariable,
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { t } from 'i18next';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { Icons } from '@caspeco/casper-ui-library.components.icon';
import { useBSTLUserAPI, useBSTLUserStore } from 'store/bstlUserStore';
import { useAuthAPI } from 'store/authStore';
import { useCartAPI, useCartStore } from 'store/cartStore';

interface IAccountDelete {
  onGoBack: () => void;
  onClose: () => void;
}

export function AccountDelete({ onGoBack, onClose }: IAccountDelete) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { appAlias } = useParams<'appAlias'>();
  const navigate = useNavigate();
  const { bstlUser } = useBSTLUserStore();
  const { cart } = useCartStore();
  const { deleteCart } = useCartAPI();
  const { deleteUser } = useBSTLUserAPI();
  const { signOut, signInAnonymously } = useAuthAPI();
  const { showToast } = useToast();

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      if (!bstlUser) {
        throw new Error('Found no user when attempting to delete user');
      }
      // Now we perform operations in the specific order below

      // First, if the user had a cart, delete it (even if it is empty)
      if (cart) {
        await deleteCart(cart.cartId);
      }
      // Second - delete our user
      await deleteUser(bstlUser.userId);
      // Now sign out the associated firebase user
      await signOut();
      // Finally sign in as anonymous user instead
      await signInAnonymously();

      // Finish up and indicate success to user
      setIsDeleting(false);
      onClose();

      // Navigate to start page
      navigate(`/${appAlias}`);

      showToast({
        type: 'success',
        description: t('account_has_been_removed'),
      });
    } catch {
      setIsDeleting(false);
      showToast({
        type: 'error',
        description: t('error_generic'),
      });
    }
  };

  return (
    <>
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={ThemeSpaceVariable.Medium}
      >
        <Button variant="ghost" onClick={onGoBack} leftIcon={Icons.Back}>
          {t('account_back_to_my_account')}
        </Button>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        pt={ThemeSpaceVariable.Large}
        px={ThemeSpaceVariable.XLarge}
        pb={ThemeSpaceVariable.XLarge}
      >
        <Heading
          textAlign="center"
          fontSize={{
            base: ThemeFontSizeVariable.XLarge,
            md: ThemeFontSizeVariable.X2Large,
          }}
          color={ThemeColorVariable.OnSurfaceHeader}
          mb={ThemeSpaceVariable.Medium}
          fontFamily={ThemeFontVariable.SubHeader}
          as="h2"
        >
          {t('account_remove')}
        </Heading>
        <Text mt={ThemeSpaceVariable.Large} textAlign="center">
          {t('account_remove_description')}
          {` `}
          {cart && !cart.isEmpty() && t('purchase_cart_will_be_emptied')}
        </Text>
      </ModalBody>
      <ModalFooter p={ThemeSpaceVariable.Medium}>
        <Button onClick={onDelete} variant="primary" isLoading={isDeleting}>
          <span>{t('account_remove')}</span>
        </Button>
      </ModalFooter>
    </>
  );
}
