import { createContext, useContext } from 'react';

export interface IBaseColorContext {
  /** Background color of the area that the ui is displayed upon. */
  baseColor: 'background' | 'surface';
}

export const BaseColorContext = createContext<IBaseColorContext>(
  {} as IBaseColorContext,
);

export const useBaseColorValue = (): IBaseColorContext =>
  useContext(BaseColorContext);
