import { useMemo } from 'react';
import { isAppWithSignInFeature } from 'helpers/app-helper/app-helper';
import { useBSTLUserStore } from 'store/bstlUserStore';

export function useShowSignIn(): boolean {
  const { bstlUser } = useBSTLUserStore();
  const isSignInSupported = isAppWithSignInFeature();

  const show = useMemo(
    () => Boolean(isSignInSupported && !bstlUser),
    [bstlUser, isSignInSupported],
  );

  return show;
}
