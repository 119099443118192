import { useTranslation } from 'react-i18next';
import {
  ThemeBorderVariable,
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Box } from '@caspeco/casper-ui-library.components.box';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { Badge } from '@caspeco/casper-ui-library.components.badge';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { OrderHistory } from 'types/history';
import Price from 'components/price';
import { formatRelativeTimeAndDayWithLocale } from 'helpers/time-helper/time-helper';

interface IHistoryCard {
  item: OrderHistory;
  isProcessed?: boolean;
  onClick: (orderId: string, isProcessed: boolean) => void;
}

export function HistoryCard({
  item,
  onClick,
  isProcessed = false,
}: IHistoryCard) {
  const { t } = useTranslation();
  const {
    orderId,
    orderNumber,
    deliveryStatus,
    paidAmount,
    paidAt,
    status,
    placeName,
    placeTimezone,
  } = item;

  function renderOrderStatusBadge() {
    if (deliveryStatus === 'NEW') {
      return <Badge.Info>{t('order_status_received')}</Badge.Info>;
    }
    if (deliveryStatus === 'PREPARING') {
      return <Badge.Info>{t('delivery_status_preparing')}</Badge.Info>;
    }
    if (deliveryStatus === 'READY' || deliveryStatus === 'DELIVERED') {
      return <Badge.Success>{t('delivery_status_ready')}</Badge.Success>;
    }
    if (status === 'REFUNDED' && deliveryStatus === 'DENIED') {
      return <Badge.Error>{t('purchase_refunded')}</Badge.Error>;
    }
    if (deliveryStatus === 'DENIED') {
      return <Badge.Error>{t('order_denied')}</Badge.Error>;
    }
    return <Badge.Info>{t('order_status_received')}</Badge.Info>;
  }

  const handleOnClick = () => {
    onClick(orderId, isProcessed);
  };

  return (
    <Box
      data-testid="history-card"
      className="button-card"
      color={ThemeColorVariable.OnSurface}
      borderBottom={ThemeBorderVariable.XSmall}
      borderColor={ThemeColorVariable.OnSurfaceBorder}
    >
      <Flex
        direction="column"
        my={ThemeSpaceVariable.Medium}
        gap={ThemeSpaceVariable.Small}
      >
        <Flex justify="space-between" align="flex-end">
          <button
            onClick={handleOnClick}
            type="button"
            aria-label={`${t('action_show')} ${t('order')} ${orderNumber}`}
          >
            <Heading
              as="h4"
              textAlign="left"
              fontFamily={ThemeFontVariable.SubHeader}
              fontSize={ThemeFontSizeVariable.Large}
              fontWeight="medium"
              color={ThemeColorVariable.OnSurfaceHeader}
            >
              {placeName}
            </Heading>
          </button>
          <Price
            fontSize={ThemeFontSizeVariable.Large}
            fontFamily={ThemeFontVariable.SubHeader}
            fontWeight="medium"
            inHousePrice={paidAmount}
          />
        </Flex>
        <Flex justify="space-between" align="flex-end">
          <Flex gap={ThemeSpaceVariable.XSmall} flexWrap="wrap">
            <Badge>{orderNumber.toString()}</Badge>
            {renderOrderStatusBadge()}
          </Flex>
          <Text
            color={ThemeColorVariable.OnSurfaceSubdued}
            fontSize={ThemeFontSizeVariable.Small}
          >
            {formatRelativeTimeAndDayWithLocale(paidAt, placeTimezone)}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
