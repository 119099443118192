import { createSearchParams } from 'react-router-dom';

/**
 * Creates a URLSearchParams object using the given BSTL-specific initializers.
 * Internally uses createSearchParams from react-router-dom.
 *
 * @param deliveryOptionType
 * @param deliveryOptionId
 * @param showMenuSectionList
 * @example createBSTLSearchParams('EAT_AT_RESTAURANT') => ?deliveryOptionType=EAT_AT_RESTAURANT
 */
export const createBSTLSearchParams = (
  deliveryOptionType?: string,
  deliveryOptionId?: string,
  showMenuSectionList?: boolean,
): URLSearchParams =>
  // Deconstructing used to assign values to search object by condition (if they exist)
  createSearchParams({
    ...(deliveryOptionType && { deliveryOptionType }),
    ...(deliveryOptionId && { deliveryOptionId }),
    ...(showMenuSectionList && {
      showMenuSectionList: showMenuSectionList.toString(),
    }),
  });
