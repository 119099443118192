import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@caspeco/casper-ui-library.components.input';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@caspeco/casper-ui-library.components.formcontrol';
import { EMAIL_REGEX_PATTERN } from 'helpers/validation-helper/validation-helper';
import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { IAppFeedbackRequest } from 'types/app';

interface IFeedbackFormEmailField {
  control: Control<IAppFeedbackRequest, string>;
}

export function FeedbackFormEmailField({ control }: IFeedbackFormEmailField) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: 'emailAddress',
    rules: {
      pattern: {
        value: EMAIL_REGEX_PATTERN,
        message: t('error_format_enter_a_valid_email'),
      },
      required: {
        value: true,
        message: t('form_enter_email'),
      },
    },
  });

  return (
    <FormControl isReadOnly isInvalid={Boolean(error)}>
      <FormLabel color={ThemeColorVariable.OnSurface}>
        {t('form_email_address')}
      </FormLabel>
      <Input
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        size="lg"
        type="email"
        autoComplete="email"
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
