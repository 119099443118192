import { createContext, useContext, useMemo, useReducer } from 'react';
import { logError } from 'helpers/log-helper/log-helper';
import { PlaceService } from 'services/Place.service';
import { PlaceDetails } from 'types/place';
import { LogPrefix } from 'types/logging';

interface IPlaceStore {
  readonly place: PlaceDetails | undefined;
  /**
   * Is currently fetching place
   */
  readonly isLoadingPlace: boolean;
  /**
   * Has finished fetch request for place,
   * true no matter if the response was an error or success
   */
  readonly hasLoadedPlace: boolean;
}

interface IPlaceAPI {
  /**
   * Fetch place with given id
   */
  getPlace: (placeId: string) => Promise<PlaceDetails>;
}

const initialState: IPlaceStore = {
  place: undefined,
  isLoadingPlace: false,
  hasLoadedPlace: false,
};

enum ActionType {
  SET_PLACE,
  START_LOADING_PLACE,
  STOP_LOADING_PLACE,
}

type Action =
  | { type: ActionType.SET_PLACE; payload: PlaceDetails }
  | { type: ActionType.START_LOADING_PLACE }
  | { type: ActionType.STOP_LOADING_PLACE };

const PlaceAPI = createContext<IPlaceAPI>({} as IPlaceAPI);
const PlaceStore = createContext<IPlaceStore>({} as IPlaceStore);

const reducer = (state: IPlaceStore, action: Action): IPlaceStore => {
  switch (action.type) {
    case ActionType.SET_PLACE:
      return {
        place: action.payload,
        isLoadingPlace: false,
        hasLoadedPlace: true,
      };
    case ActionType.START_LOADING_PLACE:
      return {
        ...state,
        isLoadingPlace: true,
        hasLoadedPlace: false,
      };
    case ActionType.STOP_LOADING_PLACE:
      return {
        ...state,
        isLoadingPlace: false,
        hasLoadedPlace: true,
      };
    default:
      return state;
  }
};

export function PlaceProvider({
  children,
  values = initialState,
}: {
  children: React.ReactNode;
  /** Initial state values, used for testing. */
  values?: IPlaceStore;
}) {
  const [state, dispatch] = useReducer(reducer, values);

  const api: IPlaceAPI = useMemo(() => {
    const getPlace = async (placeId: string) => {
      try {
        dispatch({ type: ActionType.START_LOADING_PLACE });
        const place = await PlaceService.getPlace(placeId);
        dispatch({
          type: ActionType.SET_PLACE,
          payload: place,
        });
        return place;
      } catch (error) {
        dispatch({ type: ActionType.STOP_LOADING_PLACE });
        logError(LogPrefix.Place, error, 'Could not get Place');
        throw error;
      }
    };
    return { getPlace };
  }, []);

  return (
    <PlaceAPI.Provider value={api}>
      <PlaceStore.Provider value={state}>{children}</PlaceStore.Provider>
    </PlaceAPI.Provider>
  );
}

export const usePlaceStore = (): IPlaceStore => useContext(PlaceStore);
export const usePlaceAPI = (): IPlaceAPI => useContext(PlaceAPI);
