import { createLocalOpeningHoursHelper } from 'helpers/opening-hours-helper/opening-hours-helper';
import { DateTime } from 'luxon';
import { CurrencyString } from './currency';
import { ImageMap, ImageMapValues } from './imageMap';
import { IOpeningHours, IOpeningHoursDisplayData } from './openingHours';
import { Section, ISectionValues } from './section';
import { DeliveryType } from './deliveryOption';

export class Menu {
  id: string;
  displayName: string;
  description: string;
  deliveryOptionsId: string;
  currency: CurrencyString;
  iconUrl: string;
  imageMap: ImageMap;
  open: boolean;
  openingHours: IOpeningHours[];
  openingStatus: IOpeningHoursDisplayData;
  preparationTimeInMin: number;
  sections: Section[];
  canTakeOrders: boolean;
  deliveryTypesSupported: DeliveryType[];
  constructor(values: IMenuResponse) {
    const now = DateTime.now();
    const { isOpen, getOpeningStatus } = createLocalOpeningHoursHelper(
      values?.openingHours ?? [],
    );

    this.id = values?.id ?? '';
    this.displayName = values?.displayName ?? '';
    this.description = values?.description ?? '';
    this.deliveryOptionsId = values?.deliveryOptionsId ?? '';
    this.currency = values?.currency ?? 'SEK';
    this.iconUrl = values?.iconUrl ?? '';
    this.imageMap = new ImageMap(values?.imageMap);
    this.open = isOpen(now) ?? false;
    this.openingHours = values?.openingHours ?? [];
    this.openingStatus = getOpeningStatus(now);
    this.preparationTimeInMin = values?.preparationTimeInMin ?? 0;
    this.sections =
      values?.sections
        ?.map((s) => new Section(s))
        ?.sort((a, b) => a.sortOrder - b.sortOrder) ?? <Section[]>[];
    this.canTakeOrders = values?.canTakeOrders ?? false;
    this.deliveryTypesSupported = values?.deliveryTypesSupported ?? [];
  }
}

export interface IMenuResponse {
  id?: string;
  displayName?: string;
  description?: string;
  deliveryOptionsId?: string;
  currency?: CurrencyString;
  iconUrl?: string;
  imageMap?: ImageMapValues;
  openingHours?: IOpeningHours[];
  preparationTimeInMin?: number;
  sections?: ISectionValues[];
  canTakeOrders?: boolean;
  deliveryTypesSupported?: DeliveryType[];
}

export interface IMenuListingResponse {
  menus: IMenuResponse[];
}
