export enum ProtectedRoutes {
  PLACES = 'PLACES',
  PLACE = 'PLACE',
  MENUS = 'MENUS',
  MENU = 'MENU',
  CHECKOUT = 'CHECKOUT',
  HISTORY = 'HISTORY',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
}

export enum NonProtectedRoutes {
  NOT_FOUND = 'NOT_FOUND', // 404
  COOKIES = 'COOKIES',
  LINKS = 'LINKS',
  APP_REDIRECT_INFO = 'APP_REDIRECT_INFO',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
}

export type Routes = NonProtectedRoutes | ProtectedRoutes;

export const isProtectedRoute = (route: Routes) => route in ProtectedRoutes;
