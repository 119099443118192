import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ThemeColorVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { useOrderAPI } from 'store/orderStore';
import { Icons } from '@caspeco/casper-ui-library.components.icon';

interface IHistoryModalConfirmDelete {
  orderId: string;
  toggleConfirmDelete: () => void;
  onDeleteSuccess: () => void;
}

export function HistoryModalConfirmDelete({
  orderId,
  toggleConfirmDelete,
  onDeleteSuccess,
}: IHistoryModalConfirmDelete) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();
  const { deleteOrder } = useOrderAPI();
  const { showToast } = useToast();

  const onDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteOrder(orderId);
      onDeleteSuccess();
      setIsDeleting(false);
    } catch {
      showToast({ type: 'error', description: t('error_generic') });
      setIsDeleting(false);
    }
  };

  return (
    <>
      <ModalHeader p={ThemeSpaceVariable.Medium}>
        <Flex justify="space-between" align="center">
          <Button
            variant="ghost"
            onClick={toggleConfirmDelete}
            leftIcon={Icons.Back}
          >
            {t('action_back')}
          </Button>
          <ModalCloseButton />
        </Flex>
      </ModalHeader>
      <ModalBody px={ThemeSpaceVariable.XLarge} py={ThemeSpaceVariable.Large}>
        <Heading
          textAlign="center"
          color={ThemeColorVariable.OnSurfaceHeader}
          as="h2"
          mb={ThemeSpaceVariable.Small}
        >
          {t('order_delete')}
        </Heading>
        <Text textAlign="center">{t('order_delete_description')}</Text>
      </ModalBody>
      <ModalFooter p={ThemeSpaceVariable.Medium}>
        <Button isLoading={isDeleting} onClick={onDelete}>
          <span>{t('action_remove')}</span>
        </Button>
      </ModalFooter>
    </>
  );
}
