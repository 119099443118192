import { useEffect, useState } from 'react';
import {
  Modal,
  ModalCloseButton,
} from '@caspeco/casper-ui-library.components.modal';
import {
  getCookieConsent,
  onDisableAnalyticsCookies,
  onEnableAnalyticsCookies,
  setCookieConsent,
} from 'helpers/cookie-helper/cookie-helper';
import { ICookieConsent } from 'types/cookies';
import { useCookieModalAPI, useCookieModalStore } from 'store/cookieModalStore';
import CookieSettings from './subcomponents/CookieSettings';
import InitialCookieModal from './subcomponents/InitialCookieModal';

function CookieModal() {
  const [showSettings, setShowSettings] = useState(false);
  const { setCookieModalClosed: onClose } = useCookieModalAPI();
  const { isCookieModalOpen: isOpen } = useCookieModalStore();
  const [settings, setSettings] = useState<ICookieConsent>({
    analytics: undefined,
  });

  const toggleSettings = () => setShowSettings(!showSettings);

  useEffect(() => {
    const storedConsent = getCookieConsent();
    if (storedConsent) {
      setSettings({
        analytics: storedConsent.analytics,
      });
    }
  }, [isOpen]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    const updatedSettings = {
      ...settings,
      [id]: checked,
    };
    setSettings(updatedSettings);
  };

  const onSaveSettings = () => {
    if (settings.analytics) onEnableAnalyticsCookies();
    if (!settings.analytics) onDisableAnalyticsCookies();
    setCookieConsent({
      analytics: settings.analytics,
    });
    setShowSettings(false);
    onClose();
  };

  const onCloseWithoutChanges = () => {
    setShowSettings(false);
    const storedConsent = getCookieConsent();
    if (!storedConsent) {
      // Assert necessary consent is always stored
      setCookieConsent({ analytics: false });
    }
    onClose();
  };

  let modalContent: JSX.Element = (
    <InitialCookieModal onClose={onClose} onGoBack={toggleSettings} />
  );
  if (showSettings) {
    modalContent = (
      <CookieSettings
        values={settings}
        onChange={onChange}
        onSave={onSaveSettings}
        onGoBack={toggleSettings}
      />
    );
  }

  return (
    <Modal isOpen={isOpen === true} onClose={onCloseWithoutChanges} size="md">
      <ModalCloseButton isAbsolutePositioned />
      {modalContent}
    </Modal>
  );
}

export default CookieModal;
