export type DeliveryType =
  | 'EAT_AT_RESTAURANT'
  | 'PICKUP_AT_RESTAURANT'
  | 'DELIVERY_TO_ADDRESS';

interface DeliveryBreadCrumb {
  id: string;
  name: string;
}

export interface IDeliveryOptionResponse {
  id: string;
  title: string;
  description: string;
  type: DeliveryType;
  deliveryOptions: IDeliveryOptionResponse[];
}

export class DeliveryOption {
  id: string;
  title: string;
  description: string;
  type: DeliveryType;
  deliveryOptions: DeliveryOption[];

  constructor(values: IDeliveryOptionResponse) {
    this.id = values?.id ?? '';
    this.title = values?.title ?? '';
    this.description = values?.description ?? '';
    this.type = values?.type ?? 'EAT_AT_RESTAURANT';
    this.deliveryOptions = values?.deliveryOptions ?? <DeliveryOption[]>[];
  }
}

export interface IDeliveryInfoValues {
  eatHereRootResourceId?: string;
  eatHereNodeResourceId?: string;
  eatHereNodeResourceName?: string;
  type: DeliveryType;
  eatHereNodeResourceBreadcrumbs?: DeliveryBreadCrumb[];
  availableDeliveryOptionTypes?: DeliveryType[];
}

export class DeliveryInfo {
  eatHereRootResourceId?: string;
  eatHereNodeResourceId?: string;
  eatHereNodeResourceName?: string;
  type: DeliveryType;
  eatHereNodeResourceBreadcrumbs: DeliveryBreadCrumb[];
  availableDeliveryOptionTypes: DeliveryType[];

  constructor(values?: IDeliveryInfoValues) {
    this.eatHereRootResourceId = values?.eatHereRootResourceId ?? undefined;
    this.eatHereNodeResourceId = values?.eatHereNodeResourceId ?? undefined;
    this.eatHereNodeResourceName = values?.eatHereNodeResourceName ?? undefined;
    this.type = values?.type ?? 'EAT_AT_RESTAURANT';
    this.eatHereNodeResourceBreadcrumbs =
      values?.eatHereNodeResourceBreadcrumbs ?? [];
    this.availableDeliveryOptionTypes =
      values?.availableDeliveryOptionTypes ?? [];
  }
}

export interface IChangeDeliveryTypeRequest {
  /** Id of specific resource, also known as deliveryOptionId. */
  resourceId?: string;
  type?: DeliveryType;
}
