import { useTranslation } from 'react-i18next';
import { NavigationListButton } from '@caspeco/casper-ui-library.components.navigation-list';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeColorVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { useMenuStore } from 'store/menuStore';
import { useDeliveryOptionsStore } from 'store/deliveryOptionsStore';
import { useCartStore } from 'store/cartStore';
import { usePickSearchParams } from 'hooks/usePickSearchParams';
import {
  flattenDeliveryOptions,
  getDeliveryOptionBreadCrumb,
  getIconForDeliveryType,
} from 'helpers/deliveryoptions-helper/deliveryoptions-helper';
import { getIsDeliveryOptionEditable } from 'components/side-navigation/SideNavigation.helpers';
import { DeliveryType } from 'types/deliveryOption';
import { ILinkResponse } from 'types/link';
import { ProtectedRoutes } from 'types/routes';
import { Icon } from '@caspeco/casper-ui-library.components.icon';

interface IListItemChangeDelivery {
  route: ProtectedRoutes;
  onClick: () => void;
}

export function ListItemChangeDelivery({
  route,
  onClick,
}: IListItemChangeDelivery) {
  const { t } = useTranslation();
  const { rootDeliveryOption } = useDeliveryOptionsStore();
  const { cart } = useCartStore();
  const { menu } = useMenuStore();
  const { deliveryOptionType, deliveryOptionId } = usePickSearchParams([
    'showMenuSectionList',
    'deliveryOptionType',
    'deliveryOptionId',
  ]);

  const resolvedAppLink =
    getLocalStorage<ILinkResponse>('resolvedAppLink') ?? undefined;

  let deliveryType: string | undefined;
  let deliveryNodeName: string | undefined;

  // Check at what delivery info origin to use
  switch (true) {
    // Primarily use the Cart, if any
    case Boolean(cart):
      deliveryType = cart?.deliveryInfo.type;
      deliveryNodeName = cart?.deliveryInfo.eatHereNodeResourceName;
      break;

    // Secondly, use resolved AppLink, if any
    case Boolean(resolvedAppLink):
      deliveryType = 'EAT_AT_RESTAURANT'; // No other type supported
      deliveryNodeName = resolvedAppLink?.eatHereResource?.name;
      break;

    // Fall back to data in search params
    default:
      deliveryType = deliveryOptionType;
      deliveryNodeName = flattenDeliveryOptions(rootDeliveryOption).find(
        (node) => node.id === deliveryOptionId,
      )?.title;
      break;
  }

  const deliveryIcon = getIconForDeliveryType(deliveryType as DeliveryType);
  const deliveryOptionBreadCrumb: string | undefined = deliveryType
    ? getDeliveryOptionBreadCrumb(
        deliveryType as DeliveryType,
        deliveryNodeName,
      )
    : undefined;

  const isDeliveryOptionEditable = getIsDeliveryOptionEditable(
    route,
    rootDeliveryOption,
    menu?.deliveryTypesSupported,
  );

  return (
    <NavigationListButton
      isDisabled={!isDeliveryOptionEditable}
      onClick={onClick}
      aria-label={t('delivery_change_option')}
    >
      <Flex justifyContent="space-between" gap={ThemeSpaceVariable.Small}>
        <Flex align="center" gap={ThemeSpaceVariable.Small}>
          <Icon
            icon={deliveryIcon}
            size={ThemeIconSizeVariable.Small}
            color={ThemeColorVariable.OnBackground}
          />
          <Text
            color={ThemeColorVariable.OnBackground}
            whiteSpace="break-spaces"
            wordBreak="break-all"
          >
            {deliveryOptionBreadCrumb}
          </Text>
        </Flex>
        <Text color={ThemeColorVariable.OnBackground} decoration="underline">
          {t('action_change')}
        </Text>
      </Flex>
    </NavigationListButton>
  );
}
