import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Button } from '@caspeco/casper-ui-library.components.button';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { Alert } from '@caspeco/casper-ui-library.components.alert';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { IAppFeedbackRequest } from 'types/app';
import { LogPrefix } from 'types/logging';
import { useAppStore } from 'store/appStore';
import { useBSTLUserStore } from 'store/bstlUserStore';
import { AppService } from 'services/App.service';
import { logError, logInfo } from 'helpers/log-helper/log-helper';
import { FeedbackFormEmailField } from './subcomponents/FeedbackFormEmailField';
import { FeedbackFormMessageField } from './subcomponents/FeedbackFormMessageField';
import { FeedbackFormNameField } from './subcomponents/FeedbackFormNameField';
import { FeedbackFormSubjectField } from './subcomponents/FeedbackFormSubjectField';

export function FeedbackModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const { appData } = useAppStore();
  const { showToast } = useToast();
  const { bstlUser } = useBSTLUserStore();
  const defaultValues = {
    emailAddress: bstlUser?.emailAddress ?? '',
    name: bstlUser?.name ?? '',
    subject: '',
    message: '',
  };
  const {
    handleSubmit,
    control,
    clearErrors,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm<IAppFeedbackRequest>({
    mode: 'onChange',
    defaultValues,
    reValidateMode: 'onChange',
  });

  // Subscribe to bstlUser and update the form email + name values
  useEffect(() => {
    if (bstlUser) {
      setValue('emailAddress', bstlUser.emailAddress);
      setValue('name', bstlUser.name);
    }
  }, [bstlUser, setValue]);

  const handleOnClose = () => {
    reset(defaultValues);
    clearErrors(); // Form state
    setHasError(false); // Local alert error state
    onClose();
  };

  const onSubmit = async (formData: IAppFeedbackRequest) => {
    try {
      await AppService.sendAppFeedback(formData);
      setHasError(false);
      handleOnClose();
      showToast({
        type: 'success',
        description: t('account_thanks_for_contacting_us'),
      });
      logInfo(LogPrefix.App, 'Feedback form submitted');
    } catch (error) {
      logError(LogPrefix.App, error, 'Could not submit feedback form');
      setHasError(true);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="md">
      <ModalHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={ThemeSpaceVariable.Medium}
      >
        <Heading
          as="h2"
          fontSize={ThemeFontSizeVariable.XLarge}
          color={ThemeColorVariable.OnSurfaceHeader}
          fontFamily={ThemeFontVariable.SubHeader}
          fontWeight="medium"
        >
          {t('misc_contact_x', { 0: appData?.appName })}
        </Heading>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        my={ThemeSpaceVariable.Medium}
        px={{ base: ThemeSpaceVariable.Medium, md: ThemeSpaceVariable.Large }}
      >
        <form
          id="feedback-form"
          name="feedback-form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack
            spacing={ThemeSpaceVariable.Medium}
            mb={ThemeSpaceVariable.Medium}
          >
            <FeedbackFormEmailField control={control} />
            <FeedbackFormNameField control={control} />
            <FeedbackFormSubjectField control={control} />
            <FeedbackFormMessageField control={control} />
          </Stack>
          <Flex
            py={ThemeSpaceVariable.Medium}
            flexDirection="column"
            gap={ThemeSpaceVariable.Medium}
          >
            {hasError && (
              <Alert type="error">{t('error_could_not_send_message')}</Alert>
            )}
            <Flex justify="flex-end" w="100%">
              <Button
                display={{ base: 'none', md: 'block' }}
                variant="ghost"
                onClick={handleOnClose}
                mr={ThemeSpaceVariable.Medium}
              >
                <Text
                  color={ThemeColorVariable.OnSurface}
                  fontWeight="inherit"
                  fontFamily="inherit"
                  fontSize={{
                    base: ThemeFontSizeVariable.Large,
                    md: ThemeFontSizeVariable.Medium,
                  }}
                >
                  {t('action_cancel')}
                </Text>
              </Button>
              <Button
                w={{ base: '100%', md: 'auto' }}
                size={{ base: 'lg', md: 'md' }}
                type="submit"
                form="feedback-form"
                variant="primary"
                isLoading={isSubmitting}
              >
                <span>{t('misc_send')}</span>
              </Button>
            </Flex>
          </Flex>
        </form>
      </ModalBody>
    </Modal>
  );
}
