import { IPriceIncludingVatValues } from 'types/price';
import { ImageMap, ImageMapValues } from './imageMap';
import { PriceIncludingVat } from './price';

export interface ICartArticleValues {
  id: string;
  name: string;
  info: string;
  imageMap: ImageMapValues;
  optionGroups: IAddonGroup[];
  extraGroups: IAddonGroup[];
  unitPrice: IPriceIncludingVatValues;
  priceIncludingAddons: IPriceIncludingVatValues;
}

export class CartArticle {
  id: string;
  name: string;
  info: string;
  imageMap: ImageMap;
  optionGroups: IAddonGroup[];
  extraGroups: IAddonGroup[];
  unitPrice: PriceIncludingVat;
  priceIncludingAddons: PriceIncludingVat;

  constructor(values: ICartArticleValues) {
    this.id = values?.id ?? '';
    this.name = values?.name ?? '';
    this.info = values?.info ?? '';
    this.imageMap = new ImageMap(values?.imageMap);
    this.optionGroups = values?.optionGroups ?? <IAddonGroup>{};
    this.extraGroups = values?.extraGroups ?? <IAddonGroup>{};
    this.unitPrice = new PriceIncludingVat(values?.unitPrice);
    this.priceIncludingAddons = new PriceIncludingVat(
      values?.priceIncludingAddons,
    );
  }
}

interface IAddon {
  id: string;
  name: string;
  description: string;
  amount: PriceIncludingVat;
}

export interface IAddonGroup {
  name: string;
  addons: IAddon[];
}
