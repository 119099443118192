import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { ThemeSpaceVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { useBSTLUserAPI, useBSTLUserStore } from 'store/bstlUserStore';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import { useAuthStore } from 'store/authStore';
import { useToast } from '@caspeco/casper-ui-library.components.toast';
import { EMAIL_REGEX_PATTERN } from 'helpers/validation-helper/validation-helper';
import { useCartAPI, useCartStore } from 'store/cartStore';
import { getSessionStorage } from 'helpers/session-storage-helper/session-storage-helper';
import { IRegisterUserFormFields } from './types';
import { RegisterUserForm } from './RegisterUserForm';

export function RegisterUserModal() {
  const location = useLocation();
  const { placeId, menuId, appAlias } = useParams<
    'placeId' | 'menuId' | 'appAlias'
  >();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { changeCartUser, setIsCartModalOpen } = useCartAPI();
  const { isCartModalOpen } = useCartStore();
  const { setIsRegisterUserModalOpen, createUser } = useBSTLUserAPI();
  const { isRegisterUserModalOpen } = useBSTLUserStore();
  const { firebaseUser } = useAuthStore();
  const { isSignInModalManuallyOpened } = useAuthStore();
  const { t } = useTranslation();

  const onGoToCheckout = () => {
    // Close mobile cart before leaving route
    if (isCartModalOpen) setIsCartModalOpen(false);
    navigate(`/${appAlias}/${placeId}/${menuId}/checkout${location.search}`);
  };

  const onClose = () => {
    setIsRegisterUserModalOpen(false);
  };

  const onCreateUser = async (formData: IRegisterUserFormFields) => {
    try {
      await createUser(formData.name, formData.email, formData.phoneNumber);
      // Check for cartId to be transferred to now signed in user
      const cartId = getSessionStorage<string | null>('cartId');
      if (cartId) {
        await changeCartUser(cartId);

        if (!isSignInModalManuallyOpened) {
          // The only time the sign in modal (and via that - the register modal) is NOT being manually opened
          // is when the user is trying to go to checkout, so we can safely send them there after sign in
          onGoToCheckout();
        }
      }

      onClose();
      showToast({
        type: 'success',
        description: t('account_now_signed_in'),
      });
    } catch {
      showToast({
        type: 'error',
        description: t('error_generic'),
      });
      onClose();
    }
  };

  // If our firebase user has email, pre-fill the email field with this value
  const defaultValues: IRegisterUserFormFields = {
    email: firebaseUser?.email ?? '',
    name: firebaseUser?.displayName ?? '',
    phoneNumber: '',
  };

  // Check if the pre filled firebase user email is verified and passes regex format test
  // and if so we can identify it as 'confirmed' by us, it will work as is to create user
  const isEmailConfirmed: boolean =
    Boolean(firebaseUser?.email) &&
    Boolean(firebaseUser?.emailVerified) &&
    new RegExp(EMAIL_REGEX_PATTERN).test(firebaseUser?.email ?? '');

  return (
    <Modal
      isOpen={isRegisterUserModalOpen}
      onClose={onClose}
      size="md"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalHeader pt={ThemeSpaceVariable.XLarge}>
        <Heading textAlign="center" as="h2" mb={ThemeSpaceVariable.Medium}>
          {t('account_complete_account_registration')}
        </Heading>
      </ModalHeader>
      <ModalBody
        px={{ base: ThemeSpaceVariable.Medium, md: ThemeSpaceVariable.XLarge }}
        pb={ThemeSpaceVariable.Large}
      >
        <RegisterUserForm
          onCreateUser={onCreateUser}
          defaultValues={defaultValues}
          isEmailConfirmed={isEmailConfirmed}
          onClose={onClose}
        />
      </ModalBody>
    </Modal>
  );
}
