import { IUpsellGroupListResponse, UpsellGroupList } from 'types/upsellGroup';
import { IArticleResponse, Article } from 'types/article';
import { IMenuResponse, IMenuListingResponse, Menu } from 'types/menu';
import { AbortAction, ApiService } from './Api.service';

// API layer
export class MenuAPIService {
  static async getMenu(menuId: string): Promise<IMenuResponse> {
    return ApiService.fetch(`menus/${menuId}`, undefined, undefined, 'v2');
  }

  static async getMenus(
    placeId: string,
    deliveryOptionType?: string,
  ): Promise<IMenuListingResponse> {
    const query = deliveryOptionType
      ? `?deliveryOptionType=${deliveryOptionType}`
      : '';
    return ApiService.fetch(`places/${placeId}/menus${query}`);
  }

  static async getMenuArticle(
    menuId: string,
    articleId: string,
    cartId?: string,
  ): Promise<IArticleResponse> {
    let menuArticleUrl = `menus/${menuId}/items/${articleId}`;

    if (cartId) {
      menuArticleUrl = `${menuArticleUrl}?cartId=${cartId}`;
    }

    return ApiService.fetch(
      menuArticleUrl,
      undefined,
      undefined,
      'v2',
      AbortAction.MENU_GET_ARTICLE,
    );
  }

  static async getMenuArticleUpsellGroups(
    menuId: string,
    articleId: string,
  ): Promise<IUpsellGroupListResponse> {
    return ApiService.fetch(
      `menus/${menuId}/items/${articleId}/upsell-groups`,
      undefined,
      undefined,
      'v1',
      AbortAction.MENU_GET_UPSELL_GROUPS_FOR_ARTICLE,
    );
  }
}

// DTO layer
export class MenuService {
  static async getMenus(
    placeId: string,
    deliveryOptionType?: string,
  ): Promise<Menu[]> {
    return MenuAPIService.getMenus(placeId, deliveryOptionType).then(
      (data: IMenuListingResponse) => data.menus.map((m) => new Menu(m)),
    );
  }

  static async getMenu(menuId: string): Promise<Menu> {
    return MenuAPIService.getMenu(menuId).then(
      (data: IMenuResponse) => new Menu(data),
    );
  }

  static async getMenuArticle(
    menuId: string,
    articleId: string,
    cartId?: string,
  ): Promise<Article> {
    return MenuAPIService.getMenuArticle(menuId, articleId, cartId).then(
      (data: IArticleResponse) => new Article(data),
    );
  }

  static async getMenuArticleUpsellGroups(
    menuId: string,
    articleId: string,
  ): Promise<UpsellGroupList> {
    return MenuAPIService.getMenuArticleUpsellGroups(menuId, articleId).then(
      (data: IUpsellGroupListResponse) => new UpsellGroupList(data),
    );
  }
}
