import { useTranslation } from 'react-i18next';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { TStep } from '@caspeco/casper-ui-library.components.stepper';

export const AwaitingConfirmationStep = (): TStep => {
  const { t } = useTranslation();
  return {
    label: (
      <Text color={ThemeColorVariable.OnBackgroundHeader}>
        {t('order_status_awaiting_confirmation')}
      </Text>
    ),
    color: ThemeColorVariable.Success,
    dividerColor: ThemeColorVariable.Success,
    icon: 'spinner',
  };
};
