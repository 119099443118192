import { useMemo } from 'react';
import { isAppWithSignInFeature } from 'helpers/app-helper/app-helper';
import { useAuthStore } from 'store/authStore';
import { useBSTLUserStore } from 'store/bstlUserStore';

export function useShowAccount(): boolean {
  const { bstlUser } = useBSTLUserStore();
  const { firebaseUser } = useAuthStore();
  const isSignInSupported = isAppWithSignInFeature();

  const show = useMemo(
    () => Boolean(firebaseUser && isSignInSupported && bstlUser),
    [firebaseUser, bstlUser, isSignInSupported],
  );

  return show;
}
