import { Box } from '@caspeco/casper-ui-library.components.box';
import {
  ThemeColorVariable,
  ThemeRadiusVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Stack } from '@caspeco/casper-ui-library.components.stack';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Skeleton } from '@caspeco/casper-ui-library.components.skeleton';
import { useBaseColorValue } from 'components/base-color-context';

export function SkeletonOrderConfirmation() {
  const { baseColor } = useBaseColorValue();
  let startColor: ThemeColorVariable;
  let endColor: ThemeColorVariable;
  switch (baseColor) {
    case 'background':
      startColor = ThemeColorVariable.Background;
      endColor = ThemeColorVariable.OnBackgroundSubdued;
      break;
    case 'surface':
    default:
      startColor = ThemeColorVariable.Surface;
      endColor = ThemeColorVariable.OnSurfaceSubdued;
      break;
  }

  function renderSkeletonOrderDetails() {
    return (
      <Box>
        <Stack spacing={ThemeSpaceVariable.Medium}>
          <Box flexDirection="row" justifyContent="space-between">
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              h={{ base: '250px', md: '100px' }}
              w={{ base: '80%', md: '100%' }}
              mb={ThemeSpaceVariable.Small}
            />
          </Box>
          <Box display={{ base: 'none', md: 'block' }}>
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              id="desktop-skeleton-button"
              w="30%"
              h="54px"
              mt={ThemeSpaceVariable.Medium}
              borderRadius={ThemeRadiusVariable.XLarge}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              h="1.5rem"
              w="35%"
              mb={ThemeSpaceVariable.Small}
            />
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              h="1.5rem"
              w="35%"
              mb={ThemeSpaceVariable.Small}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              h="1.5rem"
              w="35%"
              mb={ThemeSpaceVariable.Small}
            />
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              h="1.5rem"
              w="35%"
              mb={ThemeSpaceVariable.Small}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              h="1.5rem"
              w="35%"
              mb={ThemeSpaceVariable.Small}
            />
            <Skeleton
              endColor={endColor}
              startColor={startColor}
              h="1.5rem"
              w="35%"
              mb={ThemeSpaceVariable.Small}
            />
          </Box>
        </Stack>
      </Box>
    );
  }

  function renderSkeletonGreeting() {
    return (
      <Box
        my={ThemeSpaceVariable.Medium}
        borderRadius={ThemeRadiusVariable.Medium}
        px={ThemeSpaceVariable.Medium}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        display="flex"
      >
        <Skeleton
          endColor={endColor}
          startColor={startColor}
          h="1.125rem"
          w="40%"
          mb={ThemeSpaceVariable.XSmall}
        />
        <Skeleton
          endColor={endColor}
          startColor={startColor}
          h="1.125rem"
          w="70%"
          mb={ThemeSpaceVariable.Large}
        />
      </Box>
    );
  }

  function renderSkeletonOrderNumber() {
    return (
      <Box px={ThemeSpaceVariable.Medium}>
        <Skeleton
          h="6rem"
          endColor={endColor}
          startColor={startColor}
          w={{ base: '80%', md: '40%' }}
          mb={ThemeSpaceVariable.Small}
          borderRadius={ThemeRadiusVariable.Large}
        />
      </Box>
    );
  }

  function renderSkeletonOrderSummary() {
    return (
      <Box mt={ThemeSpaceVariable.Medium}>
        <Skeleton
          h="12rem"
          endColor={endColor}
          startColor={startColor}
          w="100%"
          mb={ThemeSpaceVariable.Small}
          borderRadius={ThemeRadiusVariable.Large}
        />
        <Skeleton
          h="3rem"
          endColor={endColor}
          startColor={startColor}
          w="30%"
          mb={ThemeSpaceVariable.Small}
          borderRadius={ThemeRadiusVariable.Large}
        />
      </Box>
    );
  }

  return (
    <span data-testid="loading-indicator">
      <Flex
        justify="center"
        px={ThemeSpaceVariable.Medium}
        pt={ThemeSpaceVariable.XLarge}
        pb={ThemeSpaceVariable.Large}
      >
        <Flex direction="column" w="100%" maxW="640px">
          {renderSkeletonGreeting()}
          {renderSkeletonOrderNumber()}
          <Box
            mt={ThemeSpaceVariable.Medium}
            w="100%"
            p={ThemeSpaceVariable.Medium}
          >
            {renderSkeletonOrderDetails()}
            {renderSkeletonOrderSummary()}
          </Box>
        </Flex>
      </Flex>
    </span>
  );
}
