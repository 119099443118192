import { Link } from 'react-router-dom';
import { useCreateHeaderLink } from 'hooks/useCreateHeaderLink';
import { Routes } from 'types/routes';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { ImageMap } from 'types/imageMap';
import { Logo } from './Logo';
import { Title } from './Title';

interface IPageTitleOrLogo {
  /** Logo source. */
  logo: ImageMap | undefined;
  route: Routes;
  /** Page title */
  title: string;
}

/**
 * Center component for page header.
 * Will render a logotype if app response contains a logotype image,
 * otherwise renders a text title for the current page.
 *
 * When clicking the PageTitleOrLogo, the user is either directly redirected to a home route
 * or prompted to confirm navigating to the home route through a dialog.
 */
export function PageTitleOrLogo({ route, logo, title }: IPageTitleOrLogo) {
  const { href, label } = useCreateHeaderLink(route);

  let visibleElement;
  if (logo) {
    visibleElement = <Logo image={logo} title={title} />;
  } else {
    visibleElement = <Title title={title} />;
  }
  return (
    <Flex className="link-card" justify="center" w="100%">
      <Link to={href}>
        <p className="visually-hidden">{label}</p>
      </Link>
      {visibleElement}
    </Flex>
  );
}
