import { hasNodes } from 'helpers/deliveryoptions-helper/deliveryoptions-helper';
import { DeliveryOption, DeliveryType } from 'types/deliveryOption';
import { ProtectedRoutes } from 'types/routes';

/**
 * @param route name of route where side navigation is opened within
 * @param rootDeliveryOption root delivery option (only root for delivery type 'EAT_AT_RESTAURANT')
 * @param deliveryTypes supported delivery types
 * @returns true if current conditions allows the user to edit their current delivery settings
 */
export function getIsDeliveryOptionEditable(
  route: ProtectedRoutes,
  rootDeliveryOption: DeliveryOption | undefined,
  deliveryTypes: DeliveryType[] = [],
): boolean {
  // Delivery option should only be editable when seen from menu route
  if (route !== ProtectedRoutes.MENU) return false;

  // More than one type available to choose from - editable!
  if (deliveryTypes.length > 1) return true;

  // Only one type is available
  const [singleDeliveryType] = deliveryTypes;
  if (
    singleDeliveryType === 'DELIVERY_TO_ADDRESS' ||
    singleDeliveryType === 'PICKUP_AT_RESTAURANT'
  ) {
    // Type that cannot have nodes, no other options available - not editable!
    return false;
  }
  if (
    singleDeliveryType === 'EAT_AT_RESTAURANT' &&
    !hasNodes(rootDeliveryOption)
  ) {
    // Is type that can have nodes, but there are none. No other options available - not editable!
    return false;
  }

  return true;
}
