import { useMemo } from 'react';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { useAuthStore } from 'store/authStore';
import { useBSTLUserStore } from 'store/bstlUserStore';
import { IAppGeneralFeatures } from 'types/appFeatures';

export function useShowContactAppName(): boolean {
  const { bstlUser } = useBSTLUserStore();
  const { firebaseUser } = useAuthStore();
  const appFeatures = getLocalStorage<IAppGeneralFeatures>('appFeatures');

  const show = useMemo(
    () => Boolean(firebaseUser && bstlUser && appFeatures?.sendFeedbackEnabled),
    [firebaseUser, bstlUser, appFeatures],
  );

  return show;
}
