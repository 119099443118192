import { useMemo } from 'react';
import { IAppLinks } from 'types/app';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';

export function useShowPrivacyPolicy(): boolean {
  const links = getLocalStorage<IAppLinks>('links');
  const privacyPolicyURL = links?.privacyPolicyURL;

  const show = useMemo(() => Boolean(privacyPolicyURL), [links]);
  return show;
}
