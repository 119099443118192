import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js/types';
import i18next from 'i18next';

/** Pattern used to validate email input */
export const EMAIL_REGEX_PATTERN = /^\S+@\S+\.\S+$/;

/**
 * Returns true if the string value, trimmed, is longer than minLength.
 * Use to validate text input fields and catch whitespace input.
 */
export function isValidMinLength(
  value: string,
  minLength: number = 1,
): boolean {
  return value.trim().length >= minLength;
}

/**
 * Custom validation for name, checks if the name input is more than minLength when trimmed.
 * @returns true if valid or string with error message if not valid
 */
export const validateName = (value: string | undefined) => {
  if (!value) return true;
  return isValidMinLength(value) || i18next.t('form_enter_name');
};

/**
 * Custom validation for marking, checks if the name input is more than minLength when trimmed.
 * @returns true if valid or string with error message if not valid
 */
export const validateMarking = (value: string | undefined) => {
  if (!value) return true;
  return isValidMinLength(value) || i18next.t('purchase_enter_invoice_marking');
};

/**
 * Validation for phone number.
 * @returns true if valid or string with error message if not valid
 */
export const validatePhoneNumber = (
  phoneNumber: string | undefined,
  countryCode: CountryCode | undefined,
): string | true => {
  // If phonenumber is undefined, clear errors
  if (!phoneNumber || !countryCode) return true;

  // Checks that phone number follows E.164 format
  const isValid = isPossiblePhoneNumber(phoneNumber);

  if (!isValid) {
    switch (countryCode) {
      case 'SE':
        return i18next.t('error_format_x_is_not_valid_phone_with_suggestion', {
          0: phoneNumber,
          1: '+46 70 123 45 67',
        });
      case 'NO':
        return i18next.t('error_format_x_is_not_valid_phone_with_suggestion', {
          0: phoneNumber,
          1: '+47 401234567',
        });
      default:
        return i18next.t('error_format_x_is_not_valid_phone', {
          0: phoneNumber,
        });
    }
  }
  return true;
};
