import { useTranslation } from 'react-i18next';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { Button } from '@caspeco/casper-ui-library.components.button';
import {
  onDisableAnalyticsCookies,
  onEnableAnalyticsCookies,
  setCookieConsent,
} from 'helpers/cookie-helper/cookie-helper';
import {
  ThemeColorVariable,
  ThemeFontSizeVariable,
  ThemeFontVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Heading } from '@caspeco/casper-ui-library.components.heading';
import {
  ModalBody,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Flex } from '@caspeco/casper-ui-library.components.flex';

interface IInitialCookieModal {
  onGoBack: () => void;
  onClose: () => void;
}
function InitialCookieModal({ onGoBack, onClose }: IInitialCookieModal) {
  const { t } = useTranslation();

  const onAcceptAll = () => {
    onEnableAnalyticsCookies();
    setCookieConsent({ analytics: true });
    onClose();
  };

  const onAcceptNecessary = () => {
    onDisableAnalyticsCookies();
    setCookieConsent({ analytics: false });
    onClose();
  };

  return (
    <>
      <ModalHeader p={ThemeSpaceVariable.Medium} minH="auto">
        <Heading
          as="h2"
          fontSize={ThemeFontSizeVariable.XLarge}
          fontFamily={ThemeFontVariable.SubHeader}
          color={ThemeColorVariable.OnSurfaceHeader}
          fontWeight="medium"
        >
          {t('cookies_we_use')}
        </Heading>
      </ModalHeader>
      <ModalBody
        px={ThemeSpaceVariable.Medium}
        pb="calc(1em + env(safe-area-inset-bottom))"
      >
        <Text>
          {t('cookies_usage_read_more')}
          {t('cookies_policy').toLocaleLowerCase()}
          {t('cookies_then_make_your_choices')}{' '}
          {t('cookies_consent_saved_for_a_year')}
        </Text>
        <Flex
          gap={ThemeSpaceVariable.Medium}
          mt={ThemeSpaceVariable.Large}
          direction="column"
          justify="space-between"
        >
          <Button
            variant="primary"
            size={{ base: 'lg', md: 'md' }}
            w="100%"
            onClick={onAcceptAll}
          >
            {t('cookies_accept_all')}
          </Button>
          <Button
            variant="secondary"
            w="100%"
            size={{ base: 'lg', md: 'md' }}
            onClick={onAcceptNecessary}
          >
            {t('cookies_accept_necessary')}
          </Button>
          <Button
            variant="ghost"
            w="100%"
            size={{ base: 'lg', md: 'md' }}
            onClick={onGoBack}
          >
            <Text
              color={ThemeColorVariable.OnSurface}
              fontWeight="medium"
              fontFamily="inherit"
              fontSize={{
                base: ThemeFontSizeVariable.Large,
                md: ThemeFontSizeVariable.Medium,
              }}
            >
              {t('misc_settings')}
            </Text>
          </Button>
        </Flex>
      </ModalBody>
    </>
  );
}

export default InitialCookieModal;
