export interface IHours {
  opens: string;
  closes: string;
}

export interface IOpeningHours {
  weekDay: string;
  hours: IHours[];
  inherited: boolean;
}

export enum RelationalDay {
  Today,
  Tomorrow,
  OtherWeekday,
}

export interface IOpeningHoursDisplayData {
  atRelation?: RelationalDay;
  at?: IHours;
  weekday?: number;
}
