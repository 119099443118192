import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProtectedRoutes, Routes } from 'types/routes';
import { getLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { IAppGeneralFeatures } from 'types/appFeatures';
import { useCartStore } from 'store/cartStore';
import { useOrderStore } from 'store/orderStore';
import { getAppAlias } from 'helpers/app-helper/app-helper';
import { createBSTLSearchParams } from 'helpers/search-param-helper/search-param-helper';

interface IHeaderLink {
  href: string;
  label: string;
}

/**
 * Creates a link object to be used in the page headers main middle clickable element (title or logo),
 * based on the current route and order + cart data available.
 */
export function useCreateHeaderLink(route: Routes): IHeaderLink {
  const appAlias = getAppAlias();
  const showMenuSectionList =
    getLocalStorage<IAppGeneralFeatures>('appFeatures')?.showMenuSectionList ??
    false;
  const { cart } = useCartStore();
  const { order } = useOrderStore();
  const { t } = useTranslation();

  const [headerLink, setHeaderLink] = useState<IHeaderLink>({
    href: `/${appAlias}`,
    label: t('app_back_to_home_page'),
  });

  useEffect(() => {
    let href = `/${appAlias}`;
    let label = t('app_back_to_home_page');

    if (order && route === ProtectedRoutes.ORDER_CONFIRMATION) {
      // Has just made a purchase and is viewing order confirmation, create a header link that
      // redirects to the MENU page + include any search parameters and delivery options from order
      label = t('action_back_to_menu');
      const search = createBSTLSearchParams(
        order.deliveryOptionType,
        order.deliveryOptionId,
        showMenuSectionList,
      ).toString();
      href = `/${appAlias}/${order.placeId}/${order.menuId}?${search}`;
    }

    // Update link values
    setHeaderLink({
      href,
      label,
    });
  }, [route, order, cart]);

  return headerLink;
}
