import { useState } from 'react';
import { Select } from '@caspeco/casper-ui-library.components.select';
import { TestThemeName } from 'types/theme';
import { ThemeSpaceVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@caspeco/casper-ui-library.components.modal';
import { Button } from '@caspeco/casper-ui-library.components.button';
import {
  deleteLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage-helper/local-storage-helper';

function ThemeModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [value, setValue] = useState<string>(
    (getLocalStorage<TestThemeName>('testThemeName') as string) ?? '',
  );

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
  };

  const onSave = () => {
    if (value === 'none') {
      deleteLocalStorage('testThemeName');
    } else {
      setLocalStorage('testThemeName', value);
    }
    window.location.reload();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader p={ThemeSpaceVariable.Medium}>
        Byt tema
        <ModalCloseButton isAbsolutePositioned />
      </ModalHeader>
      <ModalBody px={ThemeSpaceVariable.Medium} py={ThemeSpaceVariable.Medium}>
        <Select
          size="lg"
          w="100%"
          name="theme"
          onChange={handleSelect}
          value={value}
          id="themeSelect"
          placeholder="Välj tema"
        >
          <option value="none">Inget (Återställ)</option>
          <option value="dark" label="Cherry Noir">
            Cherry Noir
          </option>
          <option value={TestThemeName.Debug}>
            Stars and rainbows in Dantes Infreno
          </option>
          <option value={TestThemeName.Fallback}>Gråskala</option>
        </Select>
      </ModalBody>
      <ModalFooter
        pt={ThemeSpaceVariable.Medium}
        px={ThemeSpaceVariable.Medium}
        pb="calc(1em + env(safe-area-inset-bottom))"
      >
        <Button size="lg" onClick={onSave} variant="primary">
          Spara
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ThemeModal;
