import { useTranslation } from 'react-i18next';
import {
  ThemeColorVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Text } from '@caspeco/casper-ui-library.components.text';
import { NavigationListButton } from '@caspeco/casper-ui-library.components.navigation-list';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';
import { useBSTLUserStore } from 'store/bstlUserStore';

export function ListItemShowAccount({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();
  const { bstlUser } = useBSTLUserStore();
  return (
    <>
      <NavigationListButton onClick={onClick}>
        <Flex align="center" gap={ThemeSpaceVariable.Small}>
          <Icon
            icon={Icons.User}
            size={ThemeIconSizeVariable.Small}
            color={ThemeColorVariable.OnBackground}
          />
          <Text color={ThemeColorVariable.OnBackground}>
            {bstlUser?.name.length ? bstlUser.name : t('account_account')}
          </Text>
        </Flex>
      </NavigationListButton>
    </>
  );
}
