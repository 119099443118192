import {
  IBSTLUserResponse,
  BSTLUser,
  IBSTLUserRequestValues,
} from 'types/bstlUser';
import { ApiService } from './Api.service';

// API layer
export class UserAPIService {
  static async createUser(
    user: IBSTLUserRequestValues,
  ): Promise<IBSTLUserResponse> {
    return ApiService.fetch(`users`, {
      method: 'POST',
      body: JSON.stringify({ ...user }),
    });
  }

  static async getUser(userId: string): Promise<IBSTLUserResponse> {
    return ApiService.fetch(`users/${userId}`, {
      method: 'GET',
    });
  }

  static async updateUser(
    user: IBSTLUserRequestValues,
  ): Promise<IBSTLUserResponse> {
    return ApiService.fetch(`users`, {
      method: 'PUT',
      body: JSON.stringify({ ...user }),
    });
  }

  static async deleteUser(userId: string): Promise<void> {
    return ApiService.fetch(`users/${userId}`, {
      method: 'DELETE',
    });
  }
}

// Dto layer
export class UserService {
  static async createUser(user: IBSTLUserRequestValues): Promise<BSTLUser> {
    return UserAPIService.createUser(user).then(
      (data: IBSTLUserResponse) => new BSTLUser(data),
    );
  }

  static async getUser(userId: string): Promise<BSTLUser> {
    return UserAPIService.getUser(userId).then((data) => new BSTLUser(data));
  }

  static async updateUser(user: IBSTLUserRequestValues): Promise<BSTLUser> {
    return UserAPIService.updateUser(user).then((data) => new BSTLUser(data));
  }

  static async deleteUser(userId: string): Promise<void> {
    return UserAPIService.deleteUser(userId);
  }
}
