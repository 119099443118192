import { useEffect } from 'react';
import { deleteLocalStorage } from 'helpers/local-storage-helper/local-storage-helper';
import { ProtectedRoutes, Routes } from 'types/routes';
import { deleteCartReferences } from 'helpers/cart-helper/cart-helper';
import { deleteSessionStorage } from 'helpers/session-storage-helper/session-storage-helper';

/**
 * Called on route change.
 * Perform actions based on route, such as resetting stored values.
 */
export function useOnRouteChange(route: Routes) {
  useEffect(() => {
    if (route !== ProtectedRoutes.MENU && route !== ProtectedRoutes.CHECKOUT) {
      // Any stored reference to cart should be removed when leaving purchase routes (menu + checkout)
      // Or when browsing history
      deleteCartReferences();
    }

    if (route !== ProtectedRoutes.MENU) {
      // No need to remember what menu section was selected outside of menu route
      deleteSessionStorage('selectedSectionId');
    }

    if (route !== ProtectedRoutes.MENU && route !== ProtectedRoutes.MENUS) {
      // Only menu and menus route are dependent on resolvedAppLink-data (QR)
      // Remove it when leaving them to avoid it being referenced again
      deleteLocalStorage('resolvedAppLink');
    }

    if (route !== ProtectedRoutes.CHECKOUT) {
      // Remove stored orderId when leaving checkout, it is only temporaryly stored
      // to perform a safety check in Checkout, of the order already being paid for.
      // And in that case redirecting to order-confirmation where the orderId lives in the URL
      deleteSessionStorage('orderId');
    }
  }, [route]);
}
