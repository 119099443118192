import { useState, useEffect } from 'react';
import { useAuthStore } from 'store/authStore';
import { useBSTLUserStore } from 'store/bstlUserStore';
import { useHistoryAPI } from 'store/historyStore';
import { IRouteRequestState } from 'types/routeRequestState';

/**
 * Handles all requests that need to be made initially for history.
 * Initally and until all requests have finished, will return @param isLoading = true.
 * If any request fails, will return @param error with error contents.
 *
 * Used to display a shared initial loading and error state for a route.
 *
 * @returns shared loading state and error state for all requests.
 */
export const useHistoryRequests = (): IRouteRequestState => {
  const { getOngoingOrders, getProcessedOrders } = useHistoryAPI();
  const { bstlUser } = useBSTLUserStore();
  const { isLoadingAuth, firebaseUser } = useAuthStore();
  const [requestState, setRequestState] = useState<IRouteRequestState>({
    isLoading: true,
    error: undefined,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        if (!firebaseUser && !isLoadingAuth) {
          throw new Error('Failed to reauthenticate');
        }

        if (isLoadingAuth) return; // Await sign in/out

        // If signed in (bstl user exists) we also want to fetch processed orders
        if (bstlUser) {
          await getProcessedOrders();
        }
        // Ongoing orders are available to both signed in and non-signed in users
        await getOngoingOrders();

        setRequestState({
          isLoading: false,
          error: undefined,
        });
      } catch (error) {
        setRequestState({
          isLoading: false,
          error,
        });
      }
    };

    fetch();

    // Depend on authentication (current firebase user) to fetch again if user sings in/out
  }, [bstlUser, isLoadingAuth]);

  return requestState;
};
