import i18n from 'config/i18n';
import { SupportedLanguage } from 'types/language';
import { SupportedLocale } from 'types/locale';

export const getLocaleFromCurrentLang = (
  lang: string | undefined = i18n.language,
): SupportedLocale => {
  switch (lang as SupportedLanguage) {
    case SupportedLanguage.SV:
      return SupportedLocale.SV;
    case SupportedLanguage.EN:
      return SupportedLocale.EN;
    case SupportedLanguage.NB:
      return SupportedLocale.NB;
    default:
      return SupportedLocale.EN;
  }
};

/**
 * Helper to get translated name of current used i18n language.
 * @returns translated name of current language.
 * For ex. i18n language is "SV" the return value will be "Svenska".
 */
export const getNameOfCurrentLang = () => {
  switch (i18n.language as SupportedLanguage) {
    case SupportedLanguage.SV:
      return i18n.t('misc_swedish');
    case SupportedLanguage.NB:
      return i18n.t('misc_norwegian');
    default:
      return i18n.t('misc_english');
  }
};
