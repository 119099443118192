/* eslint-disable jsx-a11y/alt-text */
// The image is decorative and does not need an alt attribute
import { AspectRatio } from 'types/ui';
import { ImageMap } from 'types/imageMap';
import { LogoContainer } from './Logo.styles';

interface ILogo {
  image: ImageMap;
  title: string;
}

export function Logo({ image, title }: ILogo) {
  return (
    <>
      <h1 className="visually-hidden">{title}</h1>
      <LogoContainer>
        <img
          id="logo"
          // Set non-pixel based height and width values to indicate to browser it should keep aspect ratio
          // See https://web.dev/articles/optimize-cls?utm_source=lighthouse&utm_medium=devtools#modern_best_practice_for_setting_image_dimensions
          height="88"
          width="313"
          aria-hidden="true"
          fetchpriority="high"
          srcSet={image.getScrSet(AspectRatio.ThirtyTwoByNine, 's', 'm')}
        />
      </LogoContainer>
    </>
  );
}
