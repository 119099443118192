import { LogPrefix } from 'types/logging';
import { logDebug, logError } from 'helpers/log-helper/log-helper';
import { getRefreshToken } from 'helpers/auth-helper/auth-helper';
import {
  getLocalStorage,
  setLocalStorage,
} from 'helpers/local-storage-helper/local-storage-helper';
import packageJson from '../../../package.json';

export interface IRequestOptions {
  body?: string;
  method?: string;
  headers?: Record<string, string>;
  signal?: AbortSignal;
}

const updateOptions = (options?: IRequestOptions) => {
  const update = { ...options };

  update.headers = {
    ...update.headers,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    mode: 'cors',
    'x-request-bstl-client-platform': 'bstl-web',
    'x-request-bstl-client-version': packageJson.version,
  };

  if (update.headers.accept === undefined) {
    update.headers = {
      ...update.headers,
      accept: 'application/json',
    };
  } else {
    update.headers = {
      ...update.headers,
      accept: update.headers.accept,
    };
  }
  const appId = getLocalStorage<string>('appId');
  if (appId) {
    update.headers = {
      ...update.headers,
      appId: appId.toString(),
    };
  }
  const accessToken = getLocalStorage<string>('accessToken');
  if (accessToken) {
    update.headers = {
      ...update.headers,
      Authorization: `Bearer ${accessToken.toString()}`,
    };
  }

  return update;
};

export const fetcher = async (url: string, options?: IRequestOptions) =>
  fetch(url, updateOptions(options)).then(async (response) => {
    if (response.status === 401) {
      try {
        const token = await getRefreshToken();
        setLocalStorage('accessToken', token);
        logDebug(LogPrefix.Auth, 'Successfully refreshed token');
      } catch (error) {
        logError(LogPrefix.Auth, error, 'Failed to refresh token');
      }
      return fetch(url, updateOptions(options));
    }
    return response;
  });
