import { useTranslation } from 'react-i18next';
import { NavigationListButton } from '@caspeco/casper-ui-library.components.navigation-list';
import { Flex } from '@caspeco/casper-ui-library.components.flex';
import { Text } from '@caspeco/casper-ui-library.components.text';
import {
  ThemeColorVariable,
  ThemeIconSizeVariable,
  ThemeSpaceVariable,
} from '@caspeco/casper-ui-library.base-ui.theme';
import { Icon, Icons } from '@caspeco/casper-ui-library.components.icon';

export function ListItemSignIn({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();
  return (
    <NavigationListButton onClick={onClick}>
      <Flex align="center" gap={ThemeSpaceVariable.Small}>
        <Icon
          icon={Icons.User}
          size={ThemeIconSizeVariable.Small}
          color={ThemeColorVariable.OnBackground}
        />
        <Text color={ThemeColorVariable.OnBackground}>
          {t('account_sign_in')}
        </Text>
      </Flex>
    </NavigationListButton>
  );
}
