import { DateTime } from 'luxon';

export const EXTRA_TIME_TO_POLL_IN_MILLISECONDS = 600000;

/**
 * If readyAt is defined, returns a number representing the duration from current time
 * until pickup, plus one additional buffer minute. For fallback behaviour, see function body.
 * @param readyAt estimated time when order should be ready
 * @returns number representing milliseconds, to be used as a duration for polling order status.
 */
export const calculatePollDuration = (readyAt: string | undefined): number => {
  const ONE_MINUTE_IN_MILLISECONDS = 60000;
  const THIRTY_MINUTES_IN_MILLISECONDS = 1800000;
  const THREE_HOURS_IN_MILLISECONDS = 10800000;

  // If ready time is missing, fallback to only polling one minute
  if (!readyAt) {
    return ONE_MINUTE_IN_MILLISECONDS;
  }

  const now = DateTime.now();
  const readyAtDateTime = DateTime.fromISO(readyAt);

  // Ready time has passed, fallback to one minute
  if (now > readyAtDateTime) {
    return ONE_MINUTE_IN_MILLISECONDS;
  }

  const durationUntilReadyTime = readyAtDateTime
    .diff(now)
    .toObject().milliseconds;

  // Could not calculate duration, fallback to one minute
  if (!durationUntilReadyTime) {
    return ONE_MINUTE_IN_MILLISECONDS;
  }

  // Ready time is too long time from now to poll for, fallback to one minute
  if (durationUntilReadyTime > THREE_HOURS_IN_MILLISECONDS) {
    return ONE_MINUTE_IN_MILLISECONDS;
  }

  // Duration time exceeds 30 minutes but is still within a "reasonable" scope,
  // limit the polling to only 30 min, to poll longer is too much
  if (
    durationUntilReadyTime > THIRTY_MINUTES_IN_MILLISECONDS &&
    durationUntilReadyTime < THREE_HOURS_IN_MILLISECONDS
  ) {
    return THIRTY_MINUTES_IN_MILLISECONDS;
  }

  // Default behaviour - return duration until ready time plus ten minutes
  return durationUntilReadyTime + EXTRA_TIME_TO_POLL_IN_MILLISECONDS;
};
