import { Grid, GridItem } from '@caspeco/casper-ui-library.components.grid';
import { ThemeColorVariable } from '@caspeco/casper-ui-library.base-ui.theme';
import { StyledGridWithDividers } from './GridWithDividers.styles';

interface IGridWithDividers<GenericGridItem> {
  columnCount: number;
  items: Array<GenericGridItem>;
  renderGridItem: (item: GenericGridItem) => JSX.Element;
  dividerColor?: ThemeColorVariable;
}

export function GridWithDividers<GenericGridItem>({
  columnCount,
  items,
  renderGridItem,
  dividerColor = ThemeColorVariable.OnBackgroundBorder,
}: IGridWithDividers<GenericGridItem>) {
  return (
    <StyledGridWithDividers
      columnCount={columnCount}
      dividerColor={dividerColor}
    >
      <Grid
        className="grid-with-dividers"
        columnGap="32px"
        rowGap="32px"
        templateColumns={`repeat(${columnCount}, minmax(0, 1fr))`}
        gridAutoRows="1fr"
      >
        {items.map((item, i) => (
          <GridItem
            key={i}
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            className="grid-with-dividers-griditem"
          >
            {renderGridItem(item)}
          </GridItem>
        ))}
      </Grid>
    </StyledGridWithDividers>
  );
}
