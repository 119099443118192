import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/confirm-dialog/ConfirmDialog';
import { useCartAPI, useCartStore } from 'store/cartStore';

interface IConfirmChangePlaceDialog {
  isOpen: boolean;
  onClose: () => void;
}

export function ConfirmChangePlaceDialog({
  isOpen,
  onClose,
}: IConfirmChangePlaceDialog) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { appAlias } = useParams<'appAlias'>();
  const { cart } = useCartStore();
  const { deleteCart } = useCartAPI();

  const confirmLeaveMessage =
    cart && !cart.isEmpty() ? `${t('purchase_cart_will_be_emptied')}` : '';

  const onNavigateHome = () => {
    if (cart) deleteCart(cart.cartId);
    navigate(`/${appAlias}`);
    onClose();
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onClose={onClose}
      primaryAction={onNavigateHome}
      secondaryAction={onClose}
      title={t('location_change_question')}
      message={confirmLeaveMessage}
      primaryButtonText={t('action_continue')}
      secondaryButtonText={t('action_cancel')}
    />
  );
}
