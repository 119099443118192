import { useMemo } from 'react';
import { usePlaceStore } from 'store/placeStore';
import { useOrderStore } from 'store/orderStore';

export function useShowChangePlace(): boolean {
  const { place } = usePlaceStore();
  const { order } = useOrderStore();

  const show = useMemo(() => {
    const placeName = place?.name || order?.placeName;
    return Boolean(placeName);
  }, [order, place]);

  return show;
}
